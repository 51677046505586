import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ListItemClicked,
  NavigationListItem,
} from '@assecosolutions/fox-navigation-bar';
import {
  AdministrationRoutes,
  iterateRoutes,
  Route,
} from '@fox/administration-shared-util-routes';

@Component({
  selector: 'fox-administration-navigation-bar',
  templateUrl: './administration-navigation-bar.component.html',
  styleUrls: ['./administration-navigation-bar.component.scss'],
})
export class AdministrationNavigationBarComponent {
  navigationBarItems$: Observable<NavigationListItem[]> = this.route.url.pipe(
    map((segments) => segments.join('')),
    map((url) =>
      iterateRoutes(AdministrationRoutes).map((v) => ({
        path: v.path,
        icon: v.icon,
        name: v.name,
        active: url === v.path,
        slot: v === AdministrationRoutes.settings ? 'footer' : '',
      }))
    )
  );

  constructor(private router: Router, private route: ActivatedRoute) {}

  onListItemClicked(e: ListItemClicked<Route>) {
    this.router.navigate([e.detail.item.path]);
  }
}
