import { html, LitElement } from 'lit';

import { styles } from './FoxBackLayer.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxBackLayer;
  }
}

export class FoxBackLayer extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  render() {
    return html` <slot></slot> `;
  }
}

const name = 'fox-back-layer';

if (!customElements.get(name)) {
  customElements.define(name, FoxBackLayer);
} else {
  console.warn(`${name} is already defined`);
}
