import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  defaultHttpErrorNotification,
  defaultSuccessNotification,
} from '../notifications';
import { ApiCall, ParameterizedApiCall } from './actions';
import { ActionWithPayload } from './model';

export const effectForApiCall = <R extends object>(
  action: ApiCall<R>,
  actions$: Actions,
  apiCall: () => Observable<R>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(action.action),
      switchMap(() =>
        apiCall().pipe(
          map((payload) => onSuccess(payload, action)),
          catchError((error) => of(onFailure(error, action)))
        )
      )
    )
  );
};

export const effectForParameterizedApiCall = <
  P extends object,
  R extends object
>(
  action: ParameterizedApiCall<P, R>,
  actions$: Actions,
  apiCall: (p: P) => Observable<R>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(action.action),
      switchMap(({ payload }) =>
        apiCall(payload).pipe(
          map((payload) => onSuccess(payload, action)),
          catchError((error) => of(onFailure(error, action)))
        )
      )
    )
  );
};

const onSuccess = <P extends object, R extends object>(
  payload: R,
  action: ApiCall<R> | ParameterizedApiCall<P, R>
): ActionWithPayload<R> => {
  const onSuccessMessage = action.options?.notification?.onSuccess;
  return onSuccessMessage
    ? action.success({
        payload,
        notification: defaultSuccessNotification(onSuccessMessage),
      })
    : action.success({ payload });
};

const onFailure = <P extends object, R extends object>(
  payload: HttpErrorResponse,
  action: ApiCall<R> | ParameterizedApiCall<P, R>
): ActionWithPayload<HttpErrorResponse> => {
  const onFailureMessage = action.options?.notification?.onFailure;
  return onFailureMessage
    ? action.failure({
        payload,
        notification: defaultHttpErrorNotification(payload, onFailureMessage),
      })
    : action.failure({ payload });
};
