import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';

import { FoxTextField } from '@assecosolutions/fox-textfield';

@Component({
  selector: 'fox-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Output()
  search = new EventEmitter<string>();

  @ViewChild('input', { read: ElementRef })
  dialog!: ElementRef<FoxTextField>;

  inputFocused = false;

  onInput() {
    this.search.emit(this.dialog.nativeElement.value);
  }

  onFocusSearch() {
    this.inputFocused = true;
  }

  onFocusOutSearch() {
    this.inputFocused = false;
  }
}

@NgModule({
  declarations: [SearchComponent],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SearchComponent],
})
export class SearchComponentModule {}
