import { Guid } from 'guid-typescript';

import {
  DASHBOARD_TYPE_ENUM,
  DashboardConfiguration,
  DashboardTypeEnum,
  ElementConfiguration,
} from '@fox/shared/models';

import {
  Coordinate,
  Rectangle,
  RectangleSide,
  Triangle,
} from './page-element.model';

/**
 *
 * Checks whether insertion is possible on this side of the element.
 * Insertion on RIGHT and LEFT side are only allowed in Rows
 * Insertion on TOP and BOTTOM side are only allowed in Columns
 *
 * @param side RectangleSide
 * @param direction direction of the DashboardTypeEnum in which insertion is allowed
 */
export const insertionPossibleOnSide = (
  side: RectangleSide | null,
  direction: DashboardTypeEnum
): boolean =>
  ((side === RectangleSide.RIGHT || side === RectangleSide.LEFT) &&
    direction === DASHBOARD_TYPE_ENUM.Row) ||
  ((side === RectangleSide.BOTTOM || side === RectangleSide.TOP) &&
    direction === DASHBOARD_TYPE_ENUM.Column);

/**
 *
 * Checks whether the given point is in the outer 5% of the rectangle
 *
 * @param point Point to be checked
 * @param rec Rectangle with the edges
 */
export const pointIsAtEdgeOfRectangle = (
  point: Coordinate,
  rec: Rectangle
): boolean => {
  const firstFivePercentX = (rec.width / 100) * 5;
  const firstFivePercentY = (rec.height / 100) * 5;
  const lastFivePercentX = (rec.width / 100) * 95;
  const lastFivePercentY = (rec.height / 100) * 95;

  return (
    point.x <= firstFivePercentX ||
    point.y <= firstFivePercentY ||
    point.x >= lastFivePercentX ||
    point.y >= lastFivePercentY
  );
};

/**
 *
 * returns an array of triangles, that build this rectangle.
 * order is TOP, RIGHT, BOTTOM, LEFT
 *
 * Keep in mind that that the coordinate origin is in the upper left corner for web client.
 *
 * @param rectangle Rectangle
 */
export const getTriangles = (rectangle: Rectangle): Triangle[] => {
  const middle: Coordinate = {
    x: rectangle.width / 2,
    y: rectangle.height / 2,
  };
  const upperLeft: Coordinate = { x: 0, y: 0 };
  const upperRight: Coordinate = { x: rectangle.width, y: 0 };
  const lowerLeft: Coordinate = { x: 0, y: rectangle.height };
  const lowerRight: Coordinate = { x: rectangle.width, y: rectangle.height };
  return [
    { corner1: upperLeft, corner2: middle, corner3: upperRight },
    { corner1: upperRight, corner2: middle, corner3: lowerRight },
    { corner1: lowerRight, corner2: middle, corner3: lowerLeft },
    { corner1: lowerLeft, corner2: middle, corner3: upperLeft },
  ];
};

/**
 *
 * returns a positive number if the point is above given line
 * returns a negative number if the point is beneath given line
 * returns 0 if the point is on the given line
 *
 * @param point point to be checked on which side it is
 * @param edge1 one edge of the line
 * @param edge2 other edge of the line
 */
export const pointIsAboveEdge = (
  point: Coordinate,
  edge1: Coordinate,
  edge2: Coordinate
): number =>
  (point.x - edge2.x) * (edge1.y - edge2.y) -
  (edge1.x - edge2.x) * (point.y - edge2.y);

/**
 *
 * This function checks whether the point is inside the triangle.
 * It relies on the pointIsAboveEdge function. When the point lies on the same
 * side of all triangle edges, it is inside of the triangle. If the point is not
 * on the same side of all edges, it is not inside of the triangle.
 *
 * @param point point to be checked if it is in triangle
 * @param triangle triangle in which the point might be
 */
export const pointIsInTriangle = (
  point: Coordinate,
  triangle: Triangle
): boolean => {
  const d1: number = pointIsAboveEdge(
    point,
    triangle.corner1,
    triangle.corner2
  );
  const d2: number = pointIsAboveEdge(
    point,
    triangle.corner2,
    triangle.corner3
  );
  const d3: number = pointIsAboveEdge(
    point,
    triangle.corner3,
    triangle.corner1
  );
  const atLeastOneNegative = d1 < 0 || d2 < 0 || d3 < 0;
  const atLeastOnePositive = d1 > 0 || d2 > 0 || d3 > 0;

  const allPositiveOrNegative = !(atLeastOneNegative && atLeastOnePositive);

  return allPositiveOrNegative;
};

/**
 *
 * This function returns the RectangleSide (TOP, RIGHT, BOTTOM, LEFT) on which the point is.
 * This function relies on the pointIsInTriangle function to return the triangles in the
 * correct order (TOP, RIGHT, BOTTOM, LEFT).
 * Returns TOP for center point
 *
 * Keep in mind that the coordinate origin is in the upper left corner for web client.
 *
 * @param point the point which is on any side of the rectangle
 * @param rec the rectangle on which side the point is
 */
export const pointIsOnSideOfRectangle = (
  point: Coordinate,
  rec: Rectangle
): RectangleSide | null => {
  const triangles: Triangle[] = getTriangles(rec);

  for (const index in triangles) {
    if (pointIsInTriangle(point, triangles[index])) {
      return Object.values(RectangleSide)[index];
    }
  }
  return null;
};

/**
 *
 * returns a new PageElement with given element, parentGuid and opposite of given direction
 *
 * @param element
 * @param parentId
 * @param direction
 */
export const createPageElementFromElementAndParentType = (
  element?: ElementConfiguration,
  parentId?: string,
  direction?: DashboardTypeEnum
): DashboardConfiguration => ({
  element,
  id: Guid.create().toString(),
  parentId,
  type:
    direction === DASHBOARD_TYPE_ENUM.Column
      ? DASHBOARD_TYPE_ENUM.Row
      : DASHBOARD_TYPE_ENUM.Column,
});

/**
 *
 * return the index where to insert the element on given side of given element
 *
 * @param parent parent containing the given element
 * @param element element to be found
 * @param side side of element for insertion
 */
export const findInsertionIndex = (
  parent: DashboardConfiguration,
  element: DashboardConfiguration,
  side: RectangleSide
): number => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let index = parent.children.indexOf(element);
  if (side === RectangleSide.BOTTOM || side === RectangleSide.RIGHT) {
    index++;
  }
  return index;
};

/**
 *
 * Adds the element to the children array of parent on given side of given sibling
 *
 * @param sibling element where the new element should be inserted next to
 * @param element element to be inserted
 * @param parent parent containing sibling
 * @param side side on which should be inserted
 */
export const addElementAsSiblingTo = (
  sibling: DashboardConfiguration,
  element: DashboardConfiguration,
  parent: DashboardConfiguration,
  side: RectangleSide
): void => {
  if (parent.children) {
    element.type = sibling.type;
    element.parentId = sibling.parentId;

    if (parent?.children?.length === 0) {
      parent.children.push(sibling);
    }

    const index = findInsertionIndex(parent, sibling, side);
    parent.children.splice(index, 0, element);
  }
};

/**
 *
 * @param sibling element where the new element should be inserted next to
 * @param element element to be repositioned
 * @param parent parent element containing elements
 * @param side side on which should be inserted
 */
export const repositionElement = (
  sibling: DashboardConfiguration,
  element: DashboardConfiguration,
  parent: DashboardConfiguration,
  side: RectangleSide
): void => {
  if (parent.children) {
    const elementIndex = parent.children.indexOf(element);
    parent.children.splice(elementIndex, 1);

    const index = findInsertionIndex(parent, sibling, side);

    parent.children.splice(index, 0, element);
  }
};

/**
 *
 * updated parent element when it has only one child left.
 * moves childs element or children array into parent and updates their parentGuid
 *
 * @param parent parent which should now collapse
 */
export const eliminateChildren = (parent: DashboardConfiguration): void => {
  if (parent.children) {
    if (parent.children[0].element) {
      parent.element = parent.children[0].element;
      parent.children = undefined;
    } else {
      parent.children = parent.children[0].children;

      if (parent.children) {
        parent.children.forEach((child) => {
          child.parentId = parent.id;
        });
      }
    }
  }
};

/**
 *
 * removes given element from parent.children array, collapses parent if necessary
 *
 * @param element element to be removed
 * @param parent parent to remove element from
 */
export const removeFromPreviousParent = (
  element: DashboardConfiguration,
  parent: DashboardConfiguration
): void => {
  if (!element.parentId) {
    return;
  }
  if (parent.children) {
    const index = parent.children.indexOf(element);
    parent.children.splice(index, 1);
    if (parent.children.length === 1) {
      eliminateChildren(parent);
    }
  }
};
