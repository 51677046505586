import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import {
  DashboardExecutorComponent,
  DashboardExecutorComponentModule,
} from './page-executor/dashboard-executor/dashboard-executor.component';
import {
  PageExecutorComponent,
  PageExecutorComponentModule,
} from './page-executor/page-executor.component';
import {
  SpaExecutorComponent,
  SpaExecutorComponentModule,
} from './page-executor/spa-executor/spa-executor.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardExecutorComponentModule,
    SpaExecutorComponentModule,
    PageExecutorComponentModule,
  ],

  exports: [
    SpaExecutorComponent,
    PageExecutorComponent,
    DashboardExecutorComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedFeaturePageExecutorModule {}
