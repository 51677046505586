<fox-content-layer class="fox-preview">
  <div slot="header" class="border-bottom p-1">
    <fox-icon-button
      data-test-id="close-button"
      icon="close"
      (click)="onClose()"
    ></fox-icon-button>
  </div>

  <div slot="header" class="border-bottom p-4">
    <h2>
      <fox-typed-field
        [item]="selectedItem"
        [configuration]="configuration?.header?.title"
      ></fox-typed-field>
    </h2>
    <fox-typed-field
      [item]="selectedItem"
      [configuration]="configuration?.header?.subtitle"
    ></fox-typed-field>
  </div>

  <div class="row p-4" slot="body">
    <div
      class="col-12 pr-2 pb-4 col-sm-6"
      *ngFor="let column of configuration?.columns; index as index"
    >
      <small class="d-block">{{ column.header }}</small>
      <fox-typed-field
        [item]="selectedItem"
        [configuration]="column"
      ></fox-typed-field>
    </div>
  </div>
</fox-content-layer>
