export interface CustomComponentEventData<
  T,
  E extends HTMLElement | void = void
> {
  item: T;
  element?: E;
}

export interface ValueEvent<T> {
  value: T;
}

export interface ItemEvent<T> {
  item: T;
}

export interface ElementEvent<E extends HTMLElement | void = void> {
  element?: E;
}

export type CustomValueEvent<
  T,
  E extends HTMLElement | void = void
> = CustomEvent<ValueEvent<T> & ElementEvent<E>>;

export type CustomItemEvent<
  T,
  E extends HTMLElement | void = void
> = CustomEvent<ItemEvent<T> & ElementEvent<E>>;

//
// Type Guards
//

export function isCustomEvent<T>(e: Event): e is CustomEvent<T> {
  return 'detail' in e;
}

export function isCustomItemEvent<T, E extends HTMLElement | void = void>(
  e: Event
): e is CustomItemEvent<T, E> {
  return (e as CustomItemEvent<T, E>)?.detail?.item !== undefined;
}

export function isCustomValueEvent<T, E extends HTMLElement | void = void>(
  e: Event
): e is CustomValueEvent<T, E> {
  return (e as CustomValueEvent<T, E>)?.detail?.value !== undefined;
}

//
// Event Creators
//

export function customItemEvent<T, E extends HTMLElement | void = void>(
  name: string,
  item: T,
  options?: {
    eventInit?: EventInit;
    element?: E;
  }
): CustomItemEvent<T, E> {
  return new CustomEvent<ItemEvent<T> & ElementEvent<E>>(name, {
    detail: {
      item,
      element: options?.element,
    },
    ...options?.eventInit,
  });
}

export function customValueEvent<T, E extends HTMLElement | void = void>(
  name: string,
  value: T,
  options?: {
    eventInit?: EventInit;
    element?: E;
  }
): CustomValueEvent<T, E> {
  return new CustomEvent<ValueEvent<T> & ElementEvent<E>>(name, {
    detail: {
      value,
      element: options?.element,
    },
    ...options?.eventInit,
  });
}
