<ng-container [ngSwitch]="store.type$ | async">
  <ng-container *ngSwitchCase="typeEnum.Spa">
    <fox-spa-executor
      *ngIf="store.spaConfiguration$ | async as configuration"
      [configuration]="configuration.detailConfiguration"
    ></fox-spa-executor>
  </ng-container>
  <ng-container *ngSwitchCase="typeEnum.Dashboard">
    <fox-dashboard-executor
      [page]="store.dashboardConfiguration$ | async"
    ></fox-dashboard-executor>
  </ng-container>
</ng-container>
