import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { FoxNotification } from '@fox/shared/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notification$ = new Subject<FoxNotification>();

  showNotification(notification: FoxNotification): void {
    this.notification$.next(notification);
  }
}
