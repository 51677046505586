import {
  html,
  LitElement,
  nothing,
  PropertyValues,
  TemplateResult,
  css,
} from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { templateContent } from 'lit/directives/template-content.js';
import { isEqual, get } from 'lodash-es';

import {
  customItemEvent,
  customValueEvent,
  isEmpty,
  isObject,
  isString,
  StringRecord,
} from '@assecosolutions/fox-common-utils';
import '@assecosolutions/fox-list';
import { FoxListItem } from '@assecosolutions/fox-list';
import { FoxTextField } from '@assecosolutions/fox-textfield';
import {
  foxFlow,
  foxVirtualize,
  VirtualizerHostElement,
} from '@assecosolutions/fox-virtualizer';

import { styles } from './FoxCombobox.css';
import {
  AccessorFn,
  AddItemFn,
  FilterFn,
  FindItemFn,
  FoxComboboxItem,
  IsEqualFn,
  IsObjectFn,
} from './FoxCombobox.model';

export class FoxComboboxBase extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  /**
   * A full set of items to filter the visible options from.
   * The items can be of either `String` or `Object` type.
   */
  @property() items: FoxComboboxItem[] = [];

  /**
   * Path for the value of the item. If `items` is an array of objects, the
   * `itemValuePath:` is used to fetch the string value for the selected
   * item.
   *
   * The item value is used in the `value` property of the combo box,
   * to provide the form value.
   *
   * @attr {string} item-value-path
   */
  @property({ attribute: 'item-value-path', type: String }) itemValuePath = '';

  /**
   * Path for label of the item. If `items` is an array of objects, the
   * `itemLabelPath` is used to fetch the displayed string label for each
   * item.
   *
   * The item label is also used for matching items when processing user
   * input, i.e., for filtering and selecting items.
   *
   * When using item templates, the property is still needed because it is used
   * for filtering, and for displaying the selected item value in the input box.
   *
   * @attr {string} item-label-path
   */
  @property({ attribute: 'item-label-path', type: String }) itemLabelPath = '';

  /**
   * Will display placeholder text in the input field when no item is selected.
   */
  @property({ type: String }) placeholder = '';

  /**
   * Label of input.
   */
  @property({ type: String }) label = '';

  /**
   * Set `true` to prevent the overlay from opening automatically.
   */
  @property({ type: Boolean }) autoOpenDisabled = false;

  /**
   * The selected item from the `items` array.
   */
  @property({ type: String }) selectedItem?: FoxComboboxItem;

  /**
   * The value of the text-input
   */
  @property({ type: String }) value?: string = '';

  /**
   * The name of the element
   */
  @property({ type: String, reflect: true }) name?: string;

  /**
   * Allows user to enter custom values that are not present in the `items` array.
   */
  @property({ type: Boolean }) allowCustomValue = false;

  /**
   * Set to true to disable this element.
   */
  @property({ type: Boolean }) disabled = false;

  /**
   * Sets the dropdown menu's position to fixed.
   * This is useful when the Combobox is inside a stacking context
   * e.g. inside fox-dialog.
   */
  @property({ type: Boolean }) fixedMenuPosition = false;

  /**
   * Set to true to display required state on element.
   */
  @property({ type: Boolean }) required = false;

  /**
   * When set to `true`, "loading" attribute is added to host and the overlay element.
   */
  @property({ type: Boolean }) loading = false;

  /**
   * Sets custom validation message.
   */
  @property({ type: String }) validationMessage = '';

  /**
   * Sets allowed pattern for input.
   */
  @property({ type: String }) pattern = '';

  /**
   * Sets maximum allowed input length.
   */
  @property({ type: Number, reflect: true, attribute: true })
  maxLength?: number;

  /**
   * Set helper text that is displayed below the input.
   */
  @property({ type: String }) helperText = '';

  @property({ type: Boolean }) customTemplate = false;

  /**
   * Reference to input element.
   */
  @query('fox-textfield') inputElement!: FoxTextField;

  /**
   * Reference to scroller element
   */
  @query('.fox-combobox--menu') scrollerElement?: VirtualizerHostElement;

  /**
   *  When set to true, fox-combobox will be opened.
   */
  @state() menuOpen = false;

  /**
   * Variable to track selected items.
   */
  @state() selectedItems: FoxComboboxItem[] = [];

  /**
   * Internal variable to track focused item in list used for keyboard navigation.
   */
  @state() protected focusedItemIndex = -1;

  /**
   * Internal variable to track if an item is focused.
   */
  @state() protected isItemFocused = false;

  /**
   * Internal copy of items used for filtering and displaying items.
   */
  @state() protected filteredItems = this.items;

  /**
   * Internal variable to track if items are of type `Object`.
   */
  @state() protected areObjects = false;

  isEqualFn!: IsEqualFn;
  filterArrayFn!: FilterFn;
  isObjectFn!: IsObjectFn;
  listItemTemplate: HTMLTemplateElement | undefined;
  selectedItemTemplate: HTMLTemplateElement | undefined;
  badgeTemplate: HTMLTemplateElement | undefined;

  labelAccessorFn: AccessorFn = (_) => undefined;

  valueAccessorFn: AccessorFn = (_) => undefined;

  findItemByLabelFn: FindItemFn = (_) => undefined;

  findItemByValueFn: FindItemFn = (_) => undefined;

  addItemFn: AddItemFn = (_) => undefined;

  // Type guard to check if item is an object.
  setIsObjectFn = () => {
    this.isObjectFn = (
      item: FoxComboboxItem | undefined
    ): item is StringRecord => {
      return item !== undefined && this.areObjects;
    };
  };

  // Function used to access label of item.
  setLabelAccessorFn = () => {
    this.labelAccessorFn = (item: FoxComboboxItem | undefined) => {
      return this.isObjectFn(item) ? get(item, this.itemLabelPath) : item;
    };
  };

  // Function used to access value of item.
  setValueAccessorFn = () => {
    this.valueAccessorFn = (item: FoxComboboxItem | undefined) => {
      return this.isObjectFn(item) ? get(item, this.itemValuePath) : item;
    };
  };

  setIsEqualFn = () => {
    this.isEqualFn = (
      a: FoxComboboxItem | undefined,
      b: FoxComboboxItem | undefined
    ) => {
      return this.isObjectFn(a) ? isEqual(a, b) : a === b;
    };
  };

  setFindItemByLabelFn = () => {
    this.findItemByLabelFn = (label: string) => {
      return this.filteredItems?.find(
        (item) => this.labelAccessorFn(item) === label
      );
    };
  };

  setFindItemByValueFn = () => {
    this.findItemByValueFn = (value: string) => {
      return this.filteredItems?.find(
        (item) => this.valueAccessorFn(item) === value
      );
    };
  };

  setFilterArrayFn = () => {
    this.filterArrayFn = (query: string) => {
      return this.items.filter((item) =>
        this.labelAccessorFn(item)?.toLowerCase().includes(query.toLowerCase())
      );
    };
  };

  setAddItemFn = () => {
    this.addItemFn = (label: string) => {
      const newItem = this.areObjects ? { [this.itemLabelPath]: label } : label;
      this.items?.push(newItem);
      this.selectedItem = newItem;
      this.value = this.valueAccessorFn(newItem);
      this.dispatchEvent(
        customItemEvent('custom-value-set', label, { element: this })
      );
    };
  };

  setFunctions() {
    // Looking for objects in items array, to set areObjects.
    this.areObjects = this.items?.some((item) => isObject(item));
    this.setIsObjectFn();
    this.setLabelAccessorFn();
    this.setValueAccessorFn();
    this.setIsEqualFn();
    this.setFindItemByLabelFn();
    this.setFindItemByValueFn();
    this.setFilterArrayFn();
    this.setAddItemFn();
  }

  connectedCallback() {
    super.connectedCallback();
    this.listItemTemplate = this.querySelector(
      'template#list-item'
    ) as HTMLTemplateElement;
    this.selectedItemTemplate = this.querySelector(
      'template#selected-item'
    ) as HTMLTemplateElement;
    this.badgeTemplate = this.querySelector(
      'template#badge'
    ) as HTMLTemplateElement;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.setFunctions();
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('items') && !isEmpty(this.items)) {
      this.setFunctions();
      this.filteredItems = this.items;
      this.isItemFocused = false;
    }
    // Tracker for dispatching events.
    if (
      changedProperties.has('selectedItem') ||
      changedProperties.has('value')
    ) {
      if (this.value && !this.selectedItem) {
        this.selectedItem =
          this.findItemByValueFn(this.value) ||
          this.findItemByLabelFn(this.value);
      }

      this.dispatchEvent(
        customItemEvent('selected-item-changed', this.selectedItem, {
          element: this,
        })
      );
      this.dispatchEvent(
        customValueEvent(
          'value-changed',
          this.valueAccessorFn(this.selectedItem) || this.selectedItem,
          { element: this }
        )
      );

      //Fired when value changes. To comply with https://developer.mozilla.org/en-US/docs/Web/Events/change
      this.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }
    if (changedProperties.has('menuOpen')) {
      if (this.selectedItem) {
        const index = this.items.findIndex((item) =>
          this.isEqualFn(item, this.selectedItem)
        );
        this.scrollToListItem(index);
      }

      this.dispatchEvent(
        customItemEvent('opened-changed', this.menuOpen, { element: this })
      );
    }
  }

  renderMenu() {
    let fixedMenuStyles = css``;

    if (this.fixedMenuPosition) {
      const width = this.inputElement?.offsetWidth;

      const hostRect = this.getBoundingClientRect();
      const spaceBelow = window.innerHeight - hostRect.bottom;

      fixedMenuStyles = css`
        width: ${width}px;
        max-height: ${spaceBelow > 350 ? 350 : spaceBelow}px;
        position: fixed;
        top: unset;
      `;
    }
    // If there are no items in the FilterdList, and the input is not empty, and custom values are not allowed, display "No items found".
    if (this.shouldNoItemsFoundBeDisplayed()) {
      return html`
        <div
          class="fox-combobox--menu fox-combobox--menu--below"
          style="${fixedMenuStyles}"
        >
          <fox-list-item class="fox-combobox--menu-blocker">
            No items found
          </fox-list-item>
        </div>
      `;
    }

    if (this.areFilteredItemsEmpty()) return nothing;
    return html`
      <div
        class="fox-combobox--menu fox-combobox--menu--below"
        style="${fixedMenuStyles}"
      >
        <div>
          ${foxVirtualize({
            layout: foxFlow({
              direction: 'vertical',
            }),
            items: this.filteredItems,
            renderItem: (item: FoxComboboxItem, index): TemplateResult =>
              this.listItemTemplate
                ? this.renderItemTemplate(item, index)
                : this.renderItem(item, index),
          })}
        </div>
      </div>
    `;
  }

  renderItem(item: FoxComboboxItem, index: number) {
    const selected = this.isSelected(item);
    const label = this.labelAccessorFn(item);
    const classes = {
      focused: this.focusedItemIndex === index && this.isItemFocused,
    };

    return html`
      <fox-list-item
        @click="${() => this.onItemClicked(item)}"
        class="${classMap(classes)}"
        ?activated="${selected}"
        ?selected="${selected}"
        index="${index}"
        style="transition: none"
        @mousedown="${(e: MouseEvent) => e.preventDefault()}"
      >
        ${label}
      </fox-list-item>
    `;
  }

  renderItemTemplate(item: FoxComboboxItem, index: number) {
    const selected = this.isSelected(item);
    const classes = {
      focused: this.focusedItemIndex === index && this.isItemFocused,
    };
    const copy = this.listItemTemplate?.cloneNode(true) as HTMLTemplateElement;
    copy.innerHTML = this.replaceWithObjectValue(
      copy.innerHTML,
      item as StringRecord
    );
    const attributeList = copy.content.querySelector('fox-list-item');
    this.removeParentNode(copy);
    return this.customTemplate
      ? html`
          <div
            style="width: 100%"
            @click="${() => this.onItemClicked(item)}"
            class="${classMap(classes)}"
            ?activated="${selected}"
            ?selected="${selected}"
            index="${index}"
            style="transition: none"
            @mousedown="${(e: MouseEvent) => e.preventDefault()}"
          >
            ${templateContent(copy)}
          </div>
        `
      : html`
          <fox-list-item
            @click="${() => this.onItemClicked(item)}"
            class="${classMap(classes)}"
            ?activated="${selected}"
            ?selected="${selected}"
            index="${index}"
            style="transition: none"
            @mousedown="${(e: MouseEvent) => e.preventDefault()}"
            ?twoline="${attributeList?.hasAttribute('twoline')}"
            hasmeta="${attributeList?.getAttribute('hasmeta')}"
            graphic="${attributeList?.getAttribute('graphic')}"
          >
            ${templateContent(copy)}
          </fox-list-item>
        `;
  }

  renderProgress() {
    return html` <fox-linear-progress indeterminate></fox-linear-progress>`;
  }

  renderToggle() {
    if (this.disabled) return nothing;
    return html`
      <fox-icon
        @click="${this.toggleMenu}"
        class="fox-combobox--toggle ${this.helperText
          ? 'fox-combobox--toggle--with-helper'
          : ''}"
        icon="arrow_drop_down"
        @mousedown="${(e: MouseEvent) => e.preventDefault()}"
      ></fox-icon>
    `;
  }

  replaceWithObjectValue(input: string, obj: StringRecord) {
    if (!input) {
      return input;
    }
    const matches = input.match(/{[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*}/g);
    let result = input;
    if (matches) {
      matches.forEach((match) => {
        result = result.replace(match, get(obj, match.slice(1, -1)));
      });
    }
    return result;
  }

  scrollToListItem(index: number) {
    // Scroll to selected item in list.
    if (!this.scrollerElement) return;
    setTimeout(() => {
      const child = this.scrollerElement?.querySelector('fox-list-item');
      this.scrollerElement?.scrollTo({
        top: index * child!.getBoundingClientRect().height,
      });
    }, 1);
  }

  removeParentNode(template: HTMLTemplateElement) {
    return template.content
      .querySelector('fox-list-item')
      ?.replaceWith(
        ...(template.content.querySelector('fox-list-item')?.childNodes || [])
      );
  }

  toggleMenu() {
    if (this.disabled) return;
    if (this.menuOpen) {
      this.onCloseMenu();
    } else {
      this.menuOpen = true;
    }
  }

  openMenu() {
    if (this.disabled) return;
    if (!this.autoOpenDisabled) {
      this.menuOpen = true;
    }
  }

  closeMenu() {
    this.menuOpen = false;
  }

  onCloseMenu() {
    this.filteredItems = this.items;
    this.closeMenu();

    if (isEmpty(this.inputElement.value)) {
      this.selectedItem = undefined;
      this.value = '';
      return;
    }
    // When input is not empty, check if the value matches any of the items.
    const itemExists = this.findItemByLabelFn(this.inputElement.value);
    if (itemExists) {
      this.selectedItem = itemExists; // If it does, set the selected item to that item.
      this.value = this.valueAccessorFn(itemExists); // If it does, set the selected item to that item.
    } else {
      if (this.allowCustomValue) {
        // If it doesn't, check if custom values are allowed and add the value to the items.
        this.addItemFn(this.inputElement.value);
      } else {
        // If custom values are not allowed, check if there is a selected item and set the input value to that item.
        if (this.selectedItem) {
          const label = this.labelAccessorFn(this.selectedItem);
          if (isString(label)) {
            // If the label is a string, set the input value to that string.
            this.inputElement.value = label;
          }
        } else {
          // If there is no selected item, clear the input.
          this.inputElement.value = '';
        }
      }
    }
  }

  setCustomValidity(message: string) {
    this.inputElement.setCustomValidity(message);
  }

  checkValidity() {
    return this.inputElement.checkValidity();
  }

  reportValidity() {
    return this.inputElement.reportValidity();
  }

  clear() {
    this.inputElement.value = '';
    this.selectedItem = '';
    this.selectedItems = [];
    this.value = '';
    this.filteredItems = this.items;
  }

  protected onTextFieldChange(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowDown': {
        if (!this.menuOpen) {
          this.menuOpen = true;
          return;
        }
        if (
          this.focusedItemIndex + 1 !== this.filteredItems?.length &&
          this.isItemFocused
        ) {
          this.focusedItemIndex++;
        } else {
          this.focusedItemIndex = 0;
          this.isItemFocused = true;
        }
        break;
      }
      case 'ArrowUp': {
        if (!this.menuOpen) return;
        if (this.focusedItemIndex !== 0 && this.isItemFocused) {
          this.focusedItemIndex--;
        }
        break;
      }
      case 'Enter': {
        this.closeMenu();
        break;
      }
      case 'ArrowLeft': {
        break;
      }
      case 'ArrowRight': {
        break;
      }
      case 'Escape': {
        this.onCloseMenu();
        break;
      }
      case 'Tab': {
        break;
      }
      default: {
        this.isItemFocused = false;
        this.filteredItems = this.filterArrayFn(this.inputElement.value);
      }
    }

    if (this.isItemFocused) {
      const isBlocker = this.shadowRoot?.querySelector('.blocker');
      if (isBlocker) {
        return;
      }
      const item = this.shadowRoot?.querySelector(
        `[index='${this.focusedItemIndex}']`
      ) as FoxListItem;
      if (item) {
        item.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      } else {
        this.scrollToListItem(this.focusedItemIndex);
        this.inputElement.value = this.labelAccessorFn(
          this.filteredItems[this.focusedItemIndex]
        ) as string;
      }
    }
  }

  protected onItemClicked(item: FoxComboboxItem) {
    this.selectedItem = item;
    this.value = this.valueAccessorFn(item);
    this.inputElement.value = this.labelAccessorFn(item) as string;
    this.onCloseMenu();
    this.inputElement.focus();
    this.filteredItems = this.items;
  }

  isSelected(item: FoxComboboxItem) {
    return (
      this.isEqualFn(item, this.selectedItem) ||
      this.selectedItems.includes(item)
    );
  }

  areFilteredItemsEmpty = () => {
    return isEmpty(this.filteredItems);
  };

  shouldNoItemsFoundBeDisplayed = () => {
    return (
      this.areFilteredItemsEmpty() &&
      !this.loading &&
      this.inputElement.value &&
      !this.allowCustomValue
    );
  };
}
