import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FieldConfiguration } from '@assecosolutions/fox-common-models';
import { AutoUnsubscribe } from '@fox/shared/util-rxjs';

@Injectable({ providedIn: 'root' })
export class FormExecutorService extends AutoUnsubscribe {
  formGroup = new FormGroup({});

  formGroupValid = false;

  constructor() {
    super();
  }

  generateFormGroup(configuration: FieldConfiguration[]) {
    const formGroup = new FormGroup({});

    configuration.map((config) => {
      if (config.path) {
        formGroup.setControl(
          config.path,
          new FormControl('', {
            validators: Validators.required,
            nonNullable: true,
          })
        );
      }
    });

    this.formGroup = formGroup;

    this.subscribeTo(this.formGroup.valueChanges, () => {
      this.formGroupValid = this.formGroup.valid;
    });
  }
}
