import { html, LitElement } from 'lit';

import { styles } from './FoxContentLayer.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxContentLayer;
  }
}

export class FoxContentLayer extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  render() {
    return html`
      <div class="fox-content-layer">
        <div class="fox-content-layer__header">
          <slot name="header"></slot>
        </div>
        <div class="fox-content-layer__content-wrapper">
          <div class="fox-content-layer__body">
            <slot name="body"></slot>
            <slot></slot>
          </div>
        </div>
        <div class="fox-content-layer__footer">
          <slot name="footer"> </slot>
        </div>
      </div>
      <div class="fox-content-layer__sidebar">
        <slot name="sidebar"></slot>
      </div>
    `;
  }
}

const name = 'fox-content-layer';

if (!customElements.get(name)) {
  customElements.define(name, FoxContentLayer);
} else {
  console.warn(`${name} is already defined`);
}
