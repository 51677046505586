import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FoxFormsModule } from '@fox/shared-ui-forms';
import { FoxConfiguration } from '@fox/shared/api-administration';

@Component({
  selector: 'fox-release-process',
  templateUrl: './release-process.component.html',
  styleUrls: ['./release-process.component.scss'],
})
export class ReleaseProcessComponent {
  @Input()
  state?: FoxConfiguration.StateEnum;

  @Input()
  formGroup?: FormGroup;

  @Output()
  save = new EventEmitter<void>();

  @Output()
  updateState = new EventEmitter<FoxConfiguration.StateEnum>();

  @Output()
  preview = new EventEmitter<void>();

  states = FoxConfiguration.StateEnum;

  get showReleaseButton(): boolean {
    return !!(this.state && this.state === 'IN_REVIEW');
  }

  get showReviewButton(): boolean {
    return !!(
      this.state &&
      (this.state === 'IN_DEVELOPMENT' || this.state === 'RETRACTED')
    );
  }

  get showRetractButton(): boolean {
    return !!(this.state && this.state === 'RELEASED');
  }

  onSave() {
    this.save.emit();
  }

  onPreview() {
    this.preview.emit();
  }

  onUpdateState(state: FoxConfiguration.StateEnum) {
    this.updateState.emit(state);
  }
}

@NgModule({
  imports: [CommonModule, FoxFormsModule],
  declarations: [ReleaseProcessComponent],
  exports: [ReleaseProcessComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReleaseProcessComponentModule {}
