<fox-indicator
  theme="{{ elementConfiguration?.detailConfiguration?.theme ?? 'default' }}"
  icon="{{ iconValue() }}"
  primaryText="{{ primaryTextValue() }}"
  secondaryText="{{ secondaryTextValue() }}"
  heading="{{ elementConfiguration?.name }}"
  [showPlaceholder]="options?.showPlaceholder"
  [compact]="options?.compact"
>
  <fox-icon-button
    *ngIf="menuIcon"
    slot="action"
    [icon]="menuIcon"
    (contextmenu)="onRightClick($event)"
    (click)="onLeftClick($event)"
  ></fox-icon-button>
</fox-indicator>
