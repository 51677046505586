<div class="d-flex flex-grow-1">
  <div class="d-none" [class.hover-overlay]="this.editable">
    <div class="mx-auto mt-4">
      <fox-button
        class=""
        icon="delete"
        outlined
        (click)="onDelete()"
        label="Delete"
        raised
      ></fox-button>
    </div>
  </div>

  <fox-element-executor
    slot="body"
    [loading]="!element"
    [configuration]="element"
    [loadDataByRequest]="true"
  ></fox-element-executor>
</div>
