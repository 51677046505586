import { TabBase } from '@material/mwc-tab/mwc-tab-base';
import { styles as mwcStyles } from '@material/mwc-tab/mwc-tab.css';
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxTab.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxTab;
  }
}

export class FoxTab extends TabBase {
  /**
   * @ignore
   */
  static styles = [styles, mwcStyles];

  @property() badge = '';

  protected override render() {
    const classes = {
      'mdc-tab--min-width': this.minWidth,
      'mdc-tab--stacked': this.stacked,
    };

    let iconTemplate = html``;
    if (this.hasImageIcon || this.icon) {
      // NOTE: MUST be on same line as spaces will cause vert alignment issues
      // in IE
      iconTemplate = html` <span class="mdc-tab__icon material-icons"
        ><slot name="icon">${this.icon}</slot></span
      >`;
    }

    let labelTemplate = html``;
    if (this.label) {
      labelTemplate = html` <span class="mdc-tab__text-label">
        ${this.label}
      </span>`;
    }

    return html` <button
      @click="${this.handleClick}"
      class="mdc-tab ${classMap(classes)}"
      role="tab"
      aria-selected="false"
      tabindex="-1"
      @focus="${this.focus}"
      @blur="${this.handleBlur}"
      @mousedown="${this.handleRippleMouseDown}"
      @mouseenter="${this.handleRippleMouseEnter}"
      @mouseleave="${this.handleRippleMouseLeave}"
      @touchstart="${this.handleRippleTouchStart}"
      @touchend="${this.handleRippleDeactivate}"
      @touchcancel="${this.handleRippleDeactivate}"
    >
      <span class="mdc-tab__content">
        ${iconTemplate}
        ${this.badge
          ? html` <div class="mdc-tab__badge">
              ${labelTemplate}
              <fox-badge primary label="${this.badge}"></fox-badge>
            </div>`
          : labelTemplate}
        ${this.isMinWidthIndicator ? this.renderIndicator() : ''}
      </span>
      ${this.isMinWidthIndicator ? '' : this.renderIndicator()}
      ${this.renderRipple()}
    </button>`;
  }

  protected renderIndicator() {
    return html` <mwc-tab-indicator
      .icon="${this.indicatorIcon}"
      .fade="${this.isFadingIndicator}"
    ></mwc-tab-indicator>`;
  }
}

const name = 'fox-tab';

if (!customElements.get(name)) {
  customElements.define(name, FoxTab);
} else {
  console.warn(`${name} is already defined`);
}
