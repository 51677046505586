<fox-kanban-card
  [title]="title"
  [link]="link"
  (contextmenu)="onRightClick($event)"
  (click)="onLeftClick($event)"
>
  <div
    slot="body"
    *ngFor="let configuration of enrichedKanbanCardsConfiguration.rows"
  >
    <div class="row kanban-card">
      <div
        class="col-5 kanban-card-label pb-1"
        *ngIf="configuration.header as header"
      >
        {{ header }}
      </div>

      <fox-typed-field
        class="col-7"
        [item]="data"
        [configuration]="configuration"
      ></fox-typed-field>
    </div>
  </div>
  <div
    slot="footer"
    *ngIf="enrichedKanbanCardsConfiguration?.footer"
    class="footer"
  >
    <fox-typed-field
      *ngFor="let configuration of enrichedKanbanCardsConfiguration.footer"
      [item]="data"
      [configuration]="configuration"
    ></fox-typed-field>
  </div>
</fox-kanban-card>
