import { format, parseISO } from 'date-fns';
import { html, nothing, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

import {
  ConditionConfiguration,
  FieldTypes,
  TableColumnConfiguration,
} from '@assecosolutions/fox-common-models';
import {
  dynamicContentExecutor,
  flatten,
  isNil,
  UnknownRecord,
  valueByPath,
} from '@assecosolutions/fox-common-utils';
import { executeCondition } from '@assecosolutions/fox-condition-executor';

const renderConditional = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => {
  let renderConditionalElement;

  if (configuration?.conditionsConfiguration?.length) {
    Object.values(configuration?.conditionsConfiguration).map((cConfig) => {
      let shouldRender = true;

      if (cConfig?.conditions && cConfig?.conditionsString) {
        shouldRender = executeCondition(cConfig?.conditionsString, item);
      }

      if (shouldRender) {
        const newConfiguration: TableColumnConfiguration &
          ConditionConfiguration = {
          ...configuration,
          conditionsConfiguration: [],
          ...cConfig,
        };

        const theme = cConfig?.theme?.replace('#', 'theme-');
        renderConditionalElement = html`
          <style>
            .${theme} * {
              --fox-badge-primary-color: ${cConfig?.theme};
              --fox-badge-text-color: white;
              --fox-font-base-color: ${cConfig?.theme};
            }
            .${theme} fox-icon {
              color: ${cConfig?.theme};
            }
          </style>

          <span class="${theme}">
            ${renderTypedField(
              newConfiguration,
              item,
              cConfig.staticContent || pathValue
            )}
          </span>
        `;
      }
    });
  }

  return renderConditionalElement;
};

/***** Renderer ****/
export const renderStringField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => {
  return html` <fox-text
    label="${pathValue}"
    .icon="${ifDefined(configuration.icon)}"
  >
  </fox-text>`;
};

export const renderLinkField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => {
  const url = dynamicContentExecutor(configuration.value ?? '', item) || '#';

  return html`
    <a href="${url}" target="${configuration.target || '_blank'}">
      ${configuration.icon
        ? html`<fox-icon-button icon="${configuration.icon}"></fox-icon-button>`
        : pathValue}
    </a>
  `;
};

export const renderEmailField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => html`<a href="mailto:${pathValue}">${pathValue}</a>`;

export const renderImageField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) =>
  html`<img
    style="width: 20px; height: auto;"
    src="${pathValue}"
    alt="${pathValue}"
  />`;

export const renderBadgeField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) =>
  pathValue
    ? html`
        <fox-badge
          label="${pathValue}"
          icon="${ifDefined(configuration.icon)}"
        ></fox-badge>
      `
    : html``;

//TODO: return action event
export const renderCheckboxField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => html`<fox-checkbox .checked="${pathValue}"></fox-checkbox>`;

export const renderIconField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => {
  const icon = ifDefined(
    configuration.icon || configuration.value || pathValue
  );
  return html`<fox-icon icon="${icon}"></fox-icon>`;
};

export const renderNumberField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => {
  const prefix = configuration.prefix ?? '';
  const num = Number.parseFloat(pathValue as string).toFixed(
    configuration.decimals
  );
  const postfix = configuration.postfix ?? '';

  return num
    ? html`<fox-text label="${prefix} ${num} ${postfix}"> </fox-text>`
    : html``;
};

export const renderDateTime = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown
) => {
  const date = configuration.dateTimeFormat
    ? format(parseISO(pathValue as string), configuration.dateTimeFormat)
    : '';

  return html` <fox-text label="${date}"></fox-text> `;
};

/** **/
export const renderTypedField = (
  configuration: TableColumnConfiguration,
  item: UnknownRecord,
  pathValue: unknown = ''
) => {
  if (!isNil(configuration)) {
    const type = configuration?.type as FieldTypes;
    item = flatten(item);

    pathValue = pathValue || valueByPath(item, configuration.path || '');

    return (
      renderConditional(configuration, item, pathValue) ||
      (foxTypedFieldMap[type] ? foxTypedFieldMap[type] : renderStringField)(
        configuration,
        item,
        pathValue
      )
    );
  }
  return nothing;
};

export const foxTypedFieldMap: {
  [key in FieldTypes]: (
    configuration: TableColumnConfiguration,
    item: UnknownRecord,
    pathValue: unknown
  ) => TemplateResult<1>;
} = {
  text: renderStringField,
  link: renderLinkField,
  email: renderEmailField,
  image: renderImageField,
  badge: renderBadgeField,
  checkbox: renderCheckboxField,
  icon: renderIconField,
  number: renderNumberField,
  datetime: renderDateTime,
};
