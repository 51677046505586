import { GridColumnElement } from '@vaadin/vaadin-grid/src/vaadin-grid-column';
import { html, render } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

import { FoxCheckbox } from '@assecosolutions/fox-checkbox';
import { TableColumnConfiguration } from '@assecosolutions/fox-common-models';
import {
  customItemEvent,
  dynamicContentExecutor,
  valueByPath,
} from '@assecosolutions/fox-common-utils';
import { FoxTextField } from '@assecosolutions/fox-textfield';
import { renderTypedField } from '@assecosolutions/fox-typed-field';

import { GridItemModel } from './FoxTableExecutor.model';

export const renderSelectionColumn = () =>
  html` <fox-table-selection-column auto-select></fox-table-selection-column>`;

/** ************** Renderer for all supported types ******************** **/

export const renderIndexColumn = () => {
  const indexRenderer = (
    root: HTMLElement,
    _column: GridColumnElement,
    model: GridItemModel
  ) => {
    render(model.index + 1, root);
  };
  return html`
    <fox-table-column
      .renderer="${indexRenderer}"
      header="#"
    ></fox-table-column>
  `;
};

export const renderActionColumn = (
  configuration: TableColumnConfiguration,
  model: GridItemModel,
  parentTable: HTMLElement,
  pathValue: unknown
) => {
  return html`
    <span
      @click="${(e: { target: HTMLElement }) => {
        parentTable.dispatchEvent(
          customItemEvent(configuration.path + '-action-clicked', model.item, {
            element: e.target,
          })
        );
      }}"
    >
      ${configuration.icon
        ? html` <fox-icon-button
            icon="${configuration.icon}"
          ></fox-icon-button>`
        : pathValue}
    </span>
  `;
};

export const renderCheckboxColumn = (
  configuration: TableColumnConfiguration,
  model: GridItemModel,
  parentTable: HTMLElement
) => {
  return html`
    <fox-checkbox
      .checked="${valueByPath(model.item, configuration.path)}"
      @click="${(e: Event & { target: FoxCheckbox }) => {
        e.preventDefault();
        parentTable.dispatchEvent(
          customItemEvent(
            configuration.path + '-checkbox-clicked',
            model.item,
            {
              element: e.target,
            }
          )
        );
      }}"
    ></fox-checkbox>
  `;
};

export const renderTextfieldColumn = (
  configuration: TableColumnConfiguration,
  model: GridItemModel,
  parentTable: HTMLElement,
  pathValue: unknown
) => html`
  <fox-textfield
    @change="${(e: { target: FoxTextField }) => {
      if (configuration.path) {
        model.item[configuration.path] = e.target.value;
      }

      parentTable.dispatchEvent(
        customItemEvent(configuration.path + '-value-changed', model.item, {
          element: e.target,
        })
      );
    }}"
    value="${pathValue}"
  ></fox-textfield>
`;

export const renderPlaceholderColumn = () =>
  html` <span class="fox-table-executor__placeholder"></span> `;

/** ******** Basic renderer wrap functions for columns and header ******** **/

export const createColumnRenderer =
  (configuration: TableColumnConfiguration, parentTable: HTMLElement) =>
  (root: HTMLElement, _column: GridColumnElement, model: GridItemModel) => {
    if (_column) {
      const renderField =
        configuration.type && foxTableColumnMap[configuration.type]
          ? foxTableColumnMap[configuration.type](
              configuration,
              model,
              parentTable,
              dynamicContentExecutor(configuration.path, model.item)
            )
          : renderTypedField(configuration, model.item);

      render(renderField, root);
    }
  };

export const createHeaderRenderer =
  (configuration: TableColumnConfiguration) =>
  (root: HTMLElement, _column: GridColumnElement) => {
    if (_column && configuration.header) {
      render(
        html`
          <fox-table-sorter
            path="${configuration.path}"
            title="${ifDefined(configuration.tooltip)}"
            direction="${ifDefined(configuration.direction)}"
          >
            ${configuration.header}
          </fox-table-sorter>
        `,
        root
      );
    }
  };

export const renderDefaultTableColumn = (
  configuration: TableColumnConfiguration,
  parentTable: HTMLElement
) => {
  return html` <fox-table-column
    resizable
    auto-width="${ifDefined(configuration.autoWidth)}"
    width="${ifDefined(configuration.width)}"
    text-align="${ifDefined(configuration.textAlign || 'start')}"
    flex-grow="${ifDefined(
      configuration.flexGrow || (configuration.width ? 0 : undefined)
    )}"
    .renderer="${createColumnRenderer(configuration, parentTable)}"
    .headerRenderer="${createHeaderRenderer(configuration)}"
  >
  </fox-table-column>`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const foxTableColumnMap: any = {
  textfield: renderTextfieldColumn,
  action: renderActionColumn,
  placeholder: renderPlaceholderColumn,
  checkbox: renderCheckboxColumn,
};
