import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
} from '@angular/core';

import {
  ElementActionTargetEnum,
  ElementActionTriggerEnum,
  IndicatorConfiguration,
  TableColumnConfiguration,
} from '@assecosolutions/fox-common-models';
import {
  isArray,
  isObject,
  UnknownRecord,
  flatten,
  ensureNotNil,
} from '@assecosolutions/fox-common-utils';
import { foxFormulasMap, FoxFormulasTypes } from '@assecosolutions/ng-formulas';
import { SearchComponentModule } from '@assecosolutions/ng-search';
import { ElementConfiguration } from '@fox/shared/models';

import { ElementExecutorBaseComponent } from '../element-executor-base.component';
import { ElementExecutorOptions } from '../element-executor.model';
import { menuIcon } from '../element-executor.utils';

@Component({
  selector: 'fox-element-executor-indicator',
  templateUrl: './element-executor-indicator.component.html',
  styleUrls: ['./element-executor-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementExecutorIndicatorComponent extends ElementExecutorBaseComponent {
  @Input()
  elementConfiguration?: ElementConfiguration<IndicatorConfiguration>;

  @Input()
  data: unknown;

  @Input()
  options: ElementExecutorOptions | undefined = undefined;

  get menuIcon() {
    if (!this.elementConfiguration) {
      return undefined;
    }
    return menuIcon(this.elementConfiguration, ElementActionTargetEnum.HEADER);
  }

  primaryTextValue() {
    return this.typedValue(
      this.elementConfiguration?.detailConfiguration?.parameters?.primaryText
    );
  }

  iconValue() {
    return this.typedValue(
      this.elementConfiguration?.detailConfiguration?.parameters?.icon
    );
  }

  secondaryTextValue() {
    return this.typedValue(
      this.elementConfiguration?.detailConfiguration?.parameters?.secondaryText
    );
  }

  typedValue(configuration: TableColumnConfiguration | undefined): string {
    if (configuration) {
      const type = configuration?.type as FoxFormulasTypes;
      const postfix = configuration.postfix ?? '';
      const prefix = configuration.prefix ?? '';
      let returnValue: unknown = configuration?.value || '';

      if (isArray(this.data)) {
        returnValue =
          (foxFormulasMap[type]
            ? foxFormulasMap[type](this.data as UnknownRecord[], configuration)
            : configuration?.value) ?? '';
      } else if (isObject(this.data)) {
        const item = flatten(this.data);
        returnValue =
          (configuration.path && item
            ? item[configuration.path]
            : configuration?.value) ?? '';
      }

      return prefix + returnValue + postfix;
    }
    return '';
  }

  onLeftClick(e: MouseEvent) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.LEFT_CLICK,
        target: ElementActionTargetEnum.HEADER,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );
  }

  onRightClick(e: MouseEvent) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.RIGHT_CLICK,
        target: ElementActionTargetEnum.HEADER,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );

    return false;
  }
}

@NgModule({
  imports: [CommonModule, SearchComponentModule],
  declarations: [ElementExecutorIndicatorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ElementExecutorIndicatorComponent],
})
export class ElementExecutorIndicatorComponentModule {}
