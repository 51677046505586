import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';

import { TableColumnConfiguration } from '@assecosolutions/fox-common-models';
import { UnknownRecord } from '@assecosolutions/fox-common-utils';

import { renderTypedField } from './FoxTypedFieldFunctions';
import '@assecosolutions/fox-text';
import '@assecosolutions/fox-badge';
import '@assecosolutions/fox-checkbox';
import '@assecosolutions/fox-icon';
import '@assecosolutions/fox-icon-button';
import '@assecosolutions/fox-textfield';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxTypedField;
  }
}

/**
 * FOX typed field
 *
 * @element fox-typed-field
 * This Component is not properly defined from the UX Team
 * it will have breaking changes
 *
 * @property item - item containing the information defined in configuration.path
 * @property configuration - configuration with header and path to define value
 * @property type - type of field, one of 'text', 'link', 'email', 'image'. More to come
 *
 */
export class FoxTypedField extends LitElement {
  @property({ type: Object }) item: UnknownRecord = {};
  @property({ type: Object }) configuration: TableColumnConfiguration = {};

  render() {
    return renderTypedField(this.configuration, this.item);
  }

  createRenderRoot() {
    return this;
  }
}

const name = 'fox-typed-field';

if (!customElements.get(name)) {
  customElements.define(name, FoxTypedField);
} else {
  console.warn(`${name} is already defined`);
}
