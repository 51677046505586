import { Snackbar } from '@material/mwc-snackbar/mwc-snackbar';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxSnackbar;
  }
}

export class FoxSnackbar extends Snackbar {}

const name = 'fox-snackbar';

if (!customElements.get(name)) {
  customElements.define(name, FoxSnackbar);
} else {
  console.warn(`${name} is already defined`);
}
