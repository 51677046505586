import {
  Chart,
  ChartData,
  ChartOptions,
  ChartType,
  registerables,
} from 'chart.js';
import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';

import { isNotNil } from '@assecosolutions/fox-common-utils';

import { styles } from './FoxChart.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: LitElement;
  }
}

export class FoxChart extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property() type?: ChartType;
  @property() data?: ChartData;
  @property() options?: ChartOptions;
  @property() chart?: Chart;

  @query('canvas') canvasElement!: HTMLCanvasElement;

  /**
   * Use lit-html render Elements
   */
  render() {
    if (
      isNotNil(this.canvasElement) &&
      isNotNil(this.type) &&
      isNotNil(this.data) &&
      isNotNil(this.options)
    ) {
      const config = {
        type: this.type,
        data: this.data,
        options: this.options,
      };

      const ctx = this.canvasElement.getContext(
        '2d'
      ) as CanvasRenderingContext2D;
      Chart.register(...registerables);

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, config);
    }

    return html` <canvas></canvas> `;
  }
}

const name = 'fox-chart';

if (!customElements.get(name)) {
  customElements.define(name, FoxChart);
} else {
  console.warn(`${name} is already defined`);
}
