import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';

import { KanbanCardsConfiguration } from '@assecosolutions/fox-common-models';
import { UnknownRecord } from '@assecosolutions/fox-common-utils';

@Component({
  selector: 'fox-element-executor-kanban-card',
  templateUrl: './element-executor-kanban-card.component.html',
  styleUrls: ['./element-executor-kanban-card.component.scss'],
})
export class ElementExecutorKanbanCardComponent {
  @Input()
  title?: string;

  @Input()
  link?: string;

  @Input()
  enrichedKanbanCardsConfiguration!: KanbanCardsConfiguration;

  @Input()
  data!: UnknownRecord;

  @Output()
  leftClick = new EventEmitter<{ e: MouseEvent; item: UnknownRecord }>();

  @Output()
  rightClick = new EventEmitter<{ e: MouseEvent; item: UnknownRecord }>();

  onRightClick(e: MouseEvent) {
    this.rightClick.emit({ e, item: this.data });
    return false;
  }

  onLeftClick(e: MouseEvent) {
    this.leftClick.emit({ e, item: this.data });
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ElementExecutorKanbanCardComponent],
  exports: [ElementExecutorKanbanCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ElementExecutorKanbanCardComponentModule {}
