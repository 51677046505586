import { NgModule } from '@angular/core';

import { DialogComponentModule } from './dialog.component';
import { DialogService } from './dialog.service';

@NgModule({
  imports: [DialogComponentModule],
  exports: [DialogComponentModule],
  providers: [DialogService],
})
export class SharedUiDialogModule {}
