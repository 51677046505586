import { html, LitElement, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { customItemEvent } from '@assecosolutions/fox-common-utils';
import { FoxTextField } from '@assecosolutions/fox-textfield';

import { styles } from './FoxIconGrid.css';
import './FoxIconGrid.model';
import { FoxGridIcon } from './FoxIconGrid.model';
import { ICONS } from './icons/MaterialIcons';

export class FoxIconGrid extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: String }) selectedIcon = '';
  @property() icons: FoxGridIcon[] = ICONS.sort((x, y) => {
    return y.popularity - x.popularity;
  });

  @property() filteredIcons: FoxGridIcon[] | null = null;

  render(): TemplateResult {
    return html`
      <div class="fox-icon-grid">
        <fox-textfield
          @input="${this.filterIcons}"
          label="Search icon"
          iconTrailing="search"
        ></fox-textfield>
        <div class="fox-icon-grid__container">${this.mapIcons()}</div>
      </div>
    `;
  }

  private mapIcons(): TemplateResult[] {
    return (this.filteredIcons ? this.filteredIcons : this.icons).map((icon) =>
      this.renderIcon(icon)
    );
  }

  private renderIcon(icon: FoxGridIcon): TemplateResult {
    const classes = {
      'fox-icon-grid__item-selected': icon.name === this.selectedIcon,
    };

    return html`
      <div
        class="fox-icon-grid__item ${classMap(classes)}"
        @click="${() => this.selectIcon(icon.name)}"
      >
        <div class="material-icons">${icon.name}</div>
      </div>
    `;
  }

  private selectIcon(icon: string): void {
    this.selectedIcon = icon;
    this.dispatchEvent(customItemEvent('icon-selected', icon));
  }

  private filterIcons(e: Event) {
    const value = (<FoxTextField>e.target).value || '';

    const filters = value
      .trim()
      .split(' ')
      .map((filter) => new RegExp(filter, 'i'));

    this.filteredIcons = this.icons.filter((icons) =>
      filters.some(
        (filter) =>
          filter.test(icons.name) ||
          Object.values(icons.tags).some((tags) => filter.test(tags))
      )
    );
  }
}

export const NAME = 'fox-icon-grid';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxIconGrid);
} else {
  console.warn(`${NAME} is already defined`);
}
