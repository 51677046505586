import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
  ActionCreator,
  ActionOptions,
  ErrorPayload,
  ParameterizedActionCreator,
  Props,
} from './model';

export class Action {
  scope = '';
  name = '';
  options?: ActionOptions;

  constructor(scope: string, name: string, options?: ActionOptions) {
    this.scope = scope;
    this.name = name;
    this.options = options;
  }

  fullActionName(name?: string): string {
    return name
      ? `[${this.scope}] ${this.name} ${name}`
      : `[${this.scope}] ${this.name}`;
  }
}

export class ApiCall<R extends object> extends Action {
  action: ActionCreator;
  success: ParameterizedActionCreator<R>;
  failure: ParameterizedActionCreator<HttpErrorResponse>;

  constructor(scope: string, name: string, options?: ActionOptions) {
    super(scope, name, options);

    this.action = createAction(this.fullActionName());

    this.failure = createAction(
      this.fullActionName('Failure'),
      props<ErrorPayload>()
    );

    this.success = createAction(
      this.fullActionName('Success'),
      props<Props<R>>()
    );
  }
}

export class ParameterizedApiCall<
  P extends object,
  R extends object
> extends Action {
  action: ParameterizedActionCreator<P>;
  success: ParameterizedActionCreator<R>;
  failure: ParameterizedActionCreator<HttpErrorResponse>;

  constructor(scope: string, name: string, options?: ActionOptions) {
    super(scope, name, options);

    this.action = createAction(this.fullActionName(), props<Props<P>>());

    this.failure = createAction(
      this.fullActionName('Failure'),
      props<ErrorPayload>()
    );

    this.success = createAction(
      this.fullActionName('Success'),
      props<Props<R>>()
    );
  }
}
