import { html, LitElement, nothing, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';

import {
  customItemEvent,
  isObject,
  isString,
} from '@assecosolutions/fox-common-utils';
import { startCase } from '@assecosolutions/ng-common';

import { styles } from './FoxCardList.css';
import { CardListItem } from './FoxCardList.model';

export class FoxCardList extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: String }) title: string | undefined;

  @property({ type: String }) captionPath = 'name';

  @property({ type: Boolean }) upperFirst = false;

  @property() items: CardListItem[];

  render() {
    if (this.items) {
      return html`
        <div class="fox-card-list">
          <div class="fox-card-list__grid">
            ${this.title ? this.renderTitle() : nothing}
            ${this.items.map((item) => this.renderCardButton(item))}
          </div>
        </div>
      `;
    } else {
      return null;
    }
  }

  private renderTitle(): TemplateResult {
    return html`<h2 class="fox-card-list__title">${this.title}</h2>`;
  }

  private renderCardButton(item: CardListItem) {
    return html`<fox-card-button
      caption="${this.caption(item)}"
      .selected="${item.selected}"
      data-test-id="${this.caption(item)}"
      @click="${() => this.cardButtonClicked(item)}"
      icon="${item.icon}"
    ></fox-card-button>`;
  }

  private caption(item: unknown): string {
    const caption: unknown = isObject(item) ? item[this.captionPath] : null;
    return isString(caption)
      ? this.upperFirst
        ? startCase(caption)
        : caption
      : '';
  }

  private cardButtonClicked(item: CardListItem) {
    this.dispatchEvent(customItemEvent('card-selected', item));
  }
}

export const NAME = 'fox-card-list';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxCardList);
} else {
  console.warn(`${NAME} is already defined`);
}
