import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import './FoxCard';
import { styles } from './FoxCardButton.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxCardButton;
  }
}

/**
 *
 * FOX Card Button
 *
 * @element fox-card-button
 *
 * @cssprop [--fox-card-button-size=80px] - Size of the button card element (and the text width)
 * @cssprop [--fox-card-button-padding=0] - Padding of the card
 * @cssprop [--fox-card-button-background-color=white] - Default background color of the card
 * @cssprop [--fox-card-button-text-color=inherit] - Default color of the card
 * @cssprop [--fox-card-button-selected-text-color=inherit] - Default selected color of the card
 * @cssprop [--fox-card-button-border=''] - Default card border
 * @cssprop [--fox-card-button-border-width=2px] - Default card border width
 * @cssprop [--fox-card-button-border-color=transparent] - Default card border color
 * @cssprop [--fox-card-button-border-type=solid] - Default card border type
 * @cssprop [--fox-card-button-selected-border-color=var(--fox-client-base-color,#4599ff)] - Default background color of the card
 * @cssprop [--fox-card-button-caption-color=var(--fox-font-base-color, #0f1310)] - Text color of the caption
 * @cssprop [--fox-card-button-font-weight=var(--fox-font-weight-semi-bold, 600)] - Font weight of the caption
 * @cssprop [--fox-card-button-font-size=var(--fox-font-size-s, 12px)] - Font size of the Caption
 *
 */

export class FoxCardButton extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  /** Material icon name of the icon */
  @property({ type: String }) icon = '';
  /** Caption text displayed underneath the card */
  @property({ type: String }) caption = '';
  /** Shows the card in outlined theme */
  @property({ type: Boolean }) outlined = false;
  /** Sets the button card into selected state  */
  @property({ type: Boolean }) selected = false;
  /** Enlarge the button-card to its full-width  */
  @property({ type: Boolean, reflect: true }) fullwidth = false;

  render() {
    const classes = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fox-card-button__selected': this.selected,
      'fox-card-button__outlined': this.outlined,
      'fox-card-button__icon-card': this.icon,
    };
    const icon = html`<fox-icon>${this.icon}</fox-icon>`;

    const caption = html`<div class="fox-card-button__caption">
      ${this.caption}
    </div>`;

    const iconCard = html`<fox-card>${this.icon ? icon : nothing}</fox-card>`;

    const defaultCard = html`
      <fox-card>
        <div slot="header">
          <slot name="header"></slot>
        </div>
        <div slot="body">
          <slot></slot>
          <slot name="body"></slot>
        </div>
        <div slot="footer">
          <slot name="footer"></slot>
        </div>
      </fox-card>
    `;

    return html`
      <button class="fox-card-button ${classMap(classes)}">
        ${this.icon ? iconCard : defaultCard}
        ${this.caption ? caption : nothing}
      </button>
    `;
  }
}
const name = 'fox-card-button';

if (!customElements.get(name)) {
  customElements.define(name, FoxCardButton);
} else {
  console.warn(`${name} is already defined`);
}
