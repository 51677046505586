import {
  isNil,
  isNull,
  isUndefined,
  isObject,
  isString,
  isArray,
  isBoolean,
  isDate,
  isNumber,
  isPrimitive,
  Nil,
  Primitive,
} from '@assecosolutions/fox-common-utils';

export type Handler<P, R, O> = (data: P, opts: O) => R;
export type DefaultHandler<R, O = void> = Handler<unknown, R, O>;
export type NullHandler<R, O = void> = Handler<null, R, O>;
export type UndefinedHandler<R, O = void> = Handler<undefined, R, O>;
export type NilHandler<R, O = void> = Handler<Nil, R, O>;

export type StringHandler<R, O = void> = Handler<string, R, O>;
export type BooleanHandler<R, O = void> = Handler<boolean, R, O>;
export type NumberHandler<R, O = void> = Handler<number, R, O>;
export type PrimitiveHandler<R, O = void> = Handler<Primitive, R, O>;

export type DateHandler<R, O = void> = Handler<Date, R, O>;
export type ArrayHandler<R, O = void> = Handler<Array<unknown>, R, O>;
export type ObjectHandler<R, O = void> = Handler<Record<string, unknown>, R, O>;

export const errorDefaultHandler = <R>(data: unknown): R => {
  throw new Error('Unexpected data format received: ' + JSON.stringify(data));
};

export class UnknownHandler<R, O = void> {
  private defaultHandler: DefaultHandler<R, O>;
  private nullHandler: NullHandler<R, O> | null;
  private undefinedHandler: UndefinedHandler<R, O> | null;
  private nilHandler: NilHandler<R, O> | null;

  private stringHandler: StringHandler<R, O> | null;
  private booleanHandler: BooleanHandler<R, O> | null;
  private numberHandler: NumberHandler<R, O> | null;
  private primitiveHandler: PrimitiveHandler<R, O> | null;

  private dateHandler: DateHandler<R, O> | null;
  private arrayHandler: ArrayHandler<R, O> | null;
  private objectHandler: ObjectHandler<R, O> | null;

  constructor(
    defaultHandler: DefaultHandler<R, O> | null,
    nullHandler: NullHandler<R, O> | null,
    undefinedHandler: UndefinedHandler<R, O> | null,
    nilHandler: NilHandler<R, O> | null,
    stringHandler: StringHandler<R, O> | null,
    booleanHandler: BooleanHandler<R, O> | null,
    numberHandler: NumberHandler<R, O> | null,
    primitiveHandler: PrimitiveHandler<R, O> | null,
    dateHandler: DateHandler<R, O> | null,
    arrayHandler: ArrayHandler<R, O> | null,
    objectHandler: ObjectHandler<R, O> | null,
    other: UnknownHandler<R, O> | null
  ) {
    this.defaultHandler =
      defaultHandler ?? other?.defaultHandler ?? errorDefaultHandler;

    this.nullHandler = nullHandler ?? other?.nullHandler ?? null;
    this.undefinedHandler = undefinedHandler ?? other?.undefinedHandler ?? null;
    this.nilHandler = nilHandler ?? other?.nilHandler ?? null;

    this.stringHandler = stringHandler ?? other?.stringHandler ?? null;
    this.booleanHandler = booleanHandler ?? other?.booleanHandler ?? null;
    this.numberHandler = numberHandler ?? other?.numberHandler ?? null;
    this.primitiveHandler = primitiveHandler ?? other?.primitiveHandler ?? null;

    this.dateHandler = dateHandler ?? other?.dateHandler ?? null;
    this.arrayHandler = arrayHandler ?? other?.arrayHandler ?? null;
    this.objectHandler = objectHandler ?? other?.objectHandler ?? null;
  }

  handle(data: unknown, opts: O extends undefined ? never : O): R {
    // Null + Undefined
    if (isUndefined(data) && this.undefinedHandler) {
      return this.undefinedHandler(data, opts);
    } else if (isNull(data) && this.nullHandler) {
      return this.nullHandler(data, opts);
    } else if (isNil(data) && this.nilHandler) {
      return this.nilHandler(data, opts);
    }
    // Primitives
    else if (isString(data) && this.stringHandler) {
      return this.stringHandler(data, opts);
    } else if (isBoolean(data) && this.booleanHandler) {
      return this.booleanHandler(data, opts);
    } else if (isNumber(data) && this.numberHandler) {
      return this.numberHandler(data, opts);
    } else if (isPrimitive(data) && this.primitiveHandler) {
      return this.primitiveHandler(data, opts);
    }
    // Object & Array
    else if (isDate(data) && this.dateHandler) {
      return this.dateHandler(data, opts);
    } else if (isArray(data) && this.arrayHandler) {
      return this.arrayHandler(data, opts);
    } else if (isObject(data) && this.objectHandler) {
      return this.objectHandler(data, opts);
    }

    return this.defaultHandler(data, opts);
  }
}

export class UnknownHandlerBuilder<R, O = void> {
  private defaultHandler: DefaultHandler<R, O> | null = null;

  private nullHandler: NullHandler<R, O> | null = null;
  private undefinedHandler: UndefinedHandler<R, O> | null = null;
  private nilHandler: NilHandler<R, O> | null = null;

  private stringHandler: StringHandler<R, O> | null = null;
  private booleanHandler: BooleanHandler<R, O> | null = null;
  private numberHandler: NumberHandler<R, O> | null = null;
  private primitiveHandler: PrimitiveHandler<R, O> | null = null;

  private dateHandler: DateHandler<R, O> | null = null;
  private arrayHandler: ArrayHandler<R, O> | null = null;
  private objectHandler: ObjectHandler<R, O> | null = null;

  private handler: UnknownHandler<R, O> | null = null;

  withDefaultHandler(
    handler: DefaultHandler<R, O>
  ): UnknownHandlerBuilder<R, O> {
    this.defaultHandler = handler;
    return this;
  }

  withNullHandler(handler: NullHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.nullHandler = handler;
    return this;
  }

  withUndefinedHandler(
    handler: UndefinedHandler<R, O>
  ): UnknownHandlerBuilder<R, O> {
    this.undefinedHandler = handler;
    return this;
  }

  withNilHandler(handler: NilHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.nilHandler = handler;
    return this;
  }

  withStringHandler(handler: StringHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.stringHandler = handler;
    return this;
  }

  withBooleanHandler(
    handler: BooleanHandler<R, O>
  ): UnknownHandlerBuilder<R, O> {
    this.booleanHandler = handler;
    return this;
  }

  withNumberHandler(handler: NumberHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.numberHandler = handler;
    return this;
  }

  withPrimitiveHandler(
    handler: PrimitiveHandler<R, O>
  ): UnknownHandlerBuilder<R, O> {
    this.primitiveHandler = handler;
    return this;
  }

  withDateHandler(handler: DateHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.dateHandler = handler;
    return this;
  }

  withArrayHandler(handler: ArrayHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.arrayHandler = handler;
    return this;
  }

  withObjectHandler(handler: ObjectHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.objectHandler = handler;
    return this;
  }

  // combine with other handler
  withHandler(handler: UnknownHandler<R, O>): UnknownHandlerBuilder<R, O> {
    this.handler = handler;
    return this;
  }

  build(): UnknownHandler<R, O> {
    return new UnknownHandler<R, O>(
      this.defaultHandler,
      this.nullHandler,
      this.undefinedHandler,
      this.nilHandler,
      this.stringHandler,
      this.booleanHandler,
      this.numberHandler,
      this.primitiveHandler,
      this.dateHandler,
      this.arrayHandler,
      this.objectHandler,
      this.handler
    );
  }
}
