/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Category } from './category';
import { DependencyRequest } from './dependencyRequest';
import { SchemaMetaDataRequest } from './schemaMetaDataRequest';


export interface CreateElementRequest { 
    schemaMetaData: SchemaMetaDataRequest;
    dependencies?: Array<DependencyRequest>;
    name: string;
    description?: string;
    type: CreateElementRequest.TypeEnum;
    categories?: Array<Category>;
    detailConfiguration?: object;
    actions?: Array<object>;
    requestConfiguration?: object;
}
export namespace CreateElementRequest {
    export type TypeEnum = 'table' | 'chart' | 'indicator' | 'kanban';
    export const TypeEnum = {
        Table: 'table' as TypeEnum,
        Chart: 'chart' as TypeEnum,
        Indicator: 'indicator' as TypeEnum,
        Kanban: 'kanban' as TypeEnum
    };
}


