import { cloneDeep } from 'lodash-es';

import { dynamicContentExecutor } from '@assecosolutions/fox-common-utils';
import { Parameter, Request } from '@fox/shared/api-administration';

type RequestInputRecord = Record<string, string>;

export const replaceRequestInputs = (request: Request): Request => {
  const processedRequest = cloneDeep(request);
  const requestInputRecord = transformRequestInputListIntoObject(request);
  replaceRequestInputsInEndpoint(processedRequest, requestInputRecord);
  replaceRequestInputsInQueryParams(processedRequest, requestInputRecord);
  replaceRequestInputsInHeaderParams(processedRequest, requestInputRecord);
  return processedRequest;
};

const replaceRequestInputsInEndpoint = (
  request: Request,
  requestInput: RequestInputRecord
) => {
  request.endpoint = dynamicContentExecutor(request.endpoint, requestInput);
};

const replaceRequestInputsInQueryParams = (
  request: Request,
  requestInput: RequestInputRecord
) => {
  request.queryParameterList?.forEach((param) =>
    replaceRequestInputInParam(param, requestInput)
  );
};

const replaceRequestInputsInHeaderParams = (
  request: Request,
  requestInput: RequestInputRecord
) => {
  request.headerParameterList?.forEach((param) =>
    replaceRequestInputInParam(param, requestInput)
  );
};

const replaceRequestInputInParam = (
  param: Parameter,
  requestInput: RequestInputRecord
) => {
  param.key = dynamicContentExecutor(param.key, requestInput);
  param.value = dynamicContentExecutor(param.value, requestInput);
};

const transformRequestInputListIntoObject = (
  request: Request
): Record<string, string> => {
  return (
    request.requestInputList?.reduce((acc, cur) => {
      acc[cur.key] = cur.value ?? '';
      return acc;
    }, {} as RequestInputRecord) ?? {}
  );
};
