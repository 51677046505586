import { html, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { templateContent } from 'lit/directives/template-content.js';
import { isEmpty } from 'lodash-es';

import {
  customItemEvent,
  StringRecord,
} from '@assecosolutions/fox-common-utils';

import { styles } from './FoxCombobox.css';
import { FoxComboboxBase } from './FoxComboboxBase';

export class FoxCombobox extends FoxComboboxBase {
  /**
   * @ignore
   */
  static styles = [styles];

  render() {
    return html`
      <fox-textfield
        @click="${this.openMenu}"
        @keyup="${(event: KeyboardEvent) => this.onTextFieldChange(event)}"
        @focusout="${() => this.onCloseMenu()}"
        @focusin="${this.openMenu}"
        ?disabled="${this.disabled}"
        ?required="${this.required}"
        label="${this.label}"
        placeholder="${this.placeholder}"
        value="${this.labelAccessorFn(this.selectedItem)}"
        validationMessage="${this.validationMessage}"
        pattern="${this.pattern}"
        maxLength="${ifDefined(this.maxLength)}"
        helper="${this.helperText}"
      ></fox-textfield>
      ${this.renderToggle()} ${this.loading ? this.renderProgress() : nothing}
      ${this.menuOpen ? this.renderMenu() : nothing}
      ${this.selectedItem ? this.renderSelectedItem() : nothing}
    `;
  }

  renderSelectedItem() {
    if (!this.selectedItemTemplate) {
      return nothing;
    }
    const copy = this.selectedItemTemplate.cloneNode(
      true
    ) as HTMLTemplateElement;
    copy.innerHTML = this.replaceWithObjectValue(
      copy.innerHTML,
      this.selectedItem as StringRecord
    );
    return html` <div
      style="position: absolute; background: white; width: 100%; z-index: 100; display: flex; flex: 1; height: 47px; left: 0; top: 0;"
      @click="${() => this.openMenu()}"
    >
      ${templateContent(copy)}
      ${!copy.innerHTML.includes('hasmeta')
        ? html` <fox-icon
            icon="close"
            style="position: absolute; right: 8px; top: 12px; z-index: 2000; cursor: pointer;"
            @click="${() => {
              this.selectedItem = undefined;
              this.value = '';
              this.inputElement.focus();
            }}"
          ></fox-icon>`
        : nothing}
    </div>`;
  }

  protected onTextFieldChange(event: KeyboardEvent) {
    window.requestAnimationFrame(() => {
      this.dispatchEvent(
        customItemEvent('input-changed', this.inputElement.value, {
          element: this,
        })
      );
    });

    super.onTextFieldChange(event);
    if (event.key === 'Enter') {
      if (this.isItemFocused) {
        return this.onItemClicked(this.filteredItems[this.focusedItemIndex]);
      }
      if (isEmpty(this.inputElement.value)) {
        return;
      }
      if (this.allowCustomValue) {
        const item = this.findItemByLabelFn(this.inputElement.value);
        if (item) {
          this.selectedItem = item;
          this.value = this.valueAccessorFn(item);
        } else {
          this.addItemFn(this.inputElement.value);
        }

        this.filteredItems = this.items;
      }
    }
  }
}

const name = 'fox-combobox';
if (!customElements.get(name)) {
  customElements.define(name, FoxCombobox);
} else {
  console.warn(`${name} is already defined`);
}
