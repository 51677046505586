import { Page } from '@fox/shared/api-administration';

import { ElementConfiguration } from './element-configuration.model';

//TODO: #585
export interface PageConfiguration<
  T = DashboardConfiguration | SpaConfiguration
> extends Omit<Page, 'detailConfiguration'> {
  detailConfiguration?: T;
}

export interface DashboardConfiguration {
  children?: DashboardConfiguration[];
  id: string;
  type?: DashboardTypeEnum;
  parentId?: string | null;
  element?: ElementConfiguration;
  state?: string;
}

export interface SpaConfiguration {
  path: string;
}

export type DashboardTypeEnum = 'COLUMN' | 'ROW';

export const DASHBOARD_TYPE_ENUM = {
  Column: 'COLUMN' as DashboardTypeEnum,
  Row: 'ROW' as DashboardTypeEnum,
};
