import { html, LitElement } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';

import { FoxComboBoxSelectedItemChanged } from '@assecosolutions/fox-combobox';
import { Condition } from '@assecosolutions/fox-common-models';
import { ensureNotNil, isString } from '@assecosolutions/fox-common-utils';

import { Field, Option } from '../FoxConditionBuilder.model';
import { styles } from './FoxConditionBuilderCondition.css';
import {
  ConditionValueChanged,
  ConditionValueDeleted,
} from './FoxConditionBuilderCondition.model';

export class FoxConditionBuilderCondition extends LitElement {
  static styles = [styles];

  static properties = {
    fields: { type: Array },
    operators: { type: Array },
    options: { type: Array },
    selectedField: { type: Object },
    formValue: { type: Object },
  };

  fields: Field[] = [];

  operators: { [key: string]: string[] } = {};

  options: { [key: string]: Option[] } = {};

  selectedField?: Field;

  formValue?: Condition;

  render() {
    return html` <div class="fox-condition-builder-condition">
      <div
        class="fox-condition-builder-condition__inputs"
        @change=${this.formValueChanged}
      >
        <fox-combobox
          .value=${this.formValue?.field || ''}
          placeholder="Fields"
          name="field"
          fixedMenuPosition
          item-id-path="${ifDefined(this.fieldHasName())}"
          item-label-path="${ifDefined(this.fieldHasName())}"
          item-value-path="${ifDefined(this.fieldHasName())}"
          .items="${this.fields}"
          @selected-item-changed="${(
            e: FoxComboBoxSelectedItemChanged<Field>
          ) => (this.selectedField = e.detail.item)}"
        ></fox-combobox>

        <fox-combobox
          .value=${this.formValue?.operator || ''}
          name="operator"
          placeholder="Operator"
          item-label-path="name"
          fixedMenuPosition
          .items="${this.getOperators()}"
        ></fox-combobox>

        ${this.getOptions()?.length
          ? html` <fox-combobox
              .value="${this.formValue?.value || ''}"
              name="value"
              fixedMenuPosition
              placeholder="Select Option"
              item-id-path="${ifDefined(this.fieldHasValue())}"
              item-label-path="name"
              item-value-path="${ifDefined(this.fieldHasValue())}"
              .items="${this.getOptions()}"
            ></fox-combobox>`
          : html`
              <fox-textfield
                .value="${this.formValue?.value || ''}"
                name="value"
                placeholder="Value"
                type="${this.selectedField?.type}"
              ></fox-textfield>
            `}
      </div>
      <div class="fox-condition-builder-condition__actions">
        <fox-icon-button
          icon="delete"
          @click="${this.deleteCondition}"
        ></fox-icon-button>
      </div>
    </div>`;
  }

  private fieldHasName(): string | undefined {
    return this.fields && this.fields[0]?.name ? 'name' : undefined;
  }

  private fieldHasValue(): string | undefined {
    return this.fields && this.fields[0]?.value ? 'name' : undefined;
  }

  private deleteCondition() {
    const event: ConditionValueDeleted = new CustomEvent('form-value-delete', {
      detail: {
        value: this.formValue,
      },
    });
    this.dispatchEvent(event);
  }

  private getOptions() {
    return this.selectedField?.options || isString(this.selectedField?.type)
      ? this.options[this.selectedField?.type as string]
      : [];
  }

  private getOperators(): string[] {
    return isString(this.selectedField?.type)
      ? this.operators[this.selectedField?.type as string]
      : this.operators['unknown'];
  }

  private formValueChanged(e: { target: HTMLInputElement }) {
    const newFormValue: Condition = {
      ...ensureNotNil(this.formValue),
      [e.target.name]: e.target.value,
    };

    this.dispatchChangedEvent(ensureNotNil(this.formValue), newFormValue);
  }

  private dispatchChangedEvent(
    oldFormValue: Condition,
    newFormValue: Condition
  ) {
    const event: ConditionValueChanged = new CustomEvent('form-value-changed', {
      detail: {
        new: newFormValue,
        old: oldFormValue,
      },
    });
    this.dispatchEvent(event);
  }
}

export const CONDITION_NAME = 'fox-condition-builder-condition';

if (!customElements.get(CONDITION_NAME)) {
  customElements.define(CONDITION_NAME, FoxConditionBuilderCondition);
} else {
  console.warn(`${CONDITION_NAME} is already defined`);
}
