/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Version } from './version';
import { SchemaMetaData } from './schemaMetaData';
import { FoxConfiguration } from './foxConfiguration';
import { Dependency } from './dependency';


export interface Element { 
    configurationId: string;
    _id: string;
    schemaMetaData: SchemaMetaData;
    version: Version;
    state: Element.StateEnum;
    dependencies?: Array<Dependency>;
    resolvedDependencies?: Array<FoxConfiguration>;
    name: string;
    description?: string;
    type: Element.TypeEnum;
    categoryIds?: Array<string>;
    detailConfiguration?: object;
    actions?: Array<object>;
    requestConfiguration?: object;
}
export namespace Element {
    export type StateEnum = 'IN_DEVELOPMENT' | 'IN_REVIEW' | 'RELEASED' | 'RETRACTED';
    export const StateEnum = {
        InDevelopment: 'IN_DEVELOPMENT' as StateEnum,
        InReview: 'IN_REVIEW' as StateEnum,
        Released: 'RELEASED' as StateEnum,
        Retracted: 'RETRACTED' as StateEnum
    };
    export type TypeEnum = 'table' | 'chart' | 'indicator' | 'kanban';
    export const TypeEnum = {
        Table: 'table' as TypeEnum,
        Chart: 'chart' as TypeEnum,
        Indicator: 'indicator' as TypeEnum,
        Kanban: 'kanban' as TypeEnum
    };
}


