import '@vaadin/vaadin-grid/theme/material/vaadin-grid-tree-toggle.js';
import { GridTreeToggleElement } from '@vaadin/vaadin-grid/src/vaadin-grid-tree-toggle.js';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-tree-toggle': FoxTableTreeToggle;
  }
}

export class FoxTableTreeToggle extends GridTreeToggleElement {}

window.customElements.define('fox-table-tree-toggle', FoxTableTreeToggle);
