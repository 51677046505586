import {
  Condition,
  ConditionConfiguration,
  ConditionSet,
} from '@assecosolutions/fox-common-models';
import {
  isCondition,
  isConditionSet,
} from '@assecosolutions/fox-condition-builder';

export const enrichConfigurationByConditionString = (
  conditionConfigurations: ConditionConfiguration[]
): ConditionConfiguration[] => {
  let newConditionsConfiguration: ConditionConfiguration[] = [];
  if (conditionConfigurations) {
    conditionConfigurations.map(
      (conditionsConfiguration: ConditionConfiguration) => {
        newConditionsConfiguration = [
          ...newConditionsConfiguration,
          {
            ...conditionsConfiguration,
            conditionsString: buildConditionString(
              conditionsConfiguration?.conditions
            ),
          },
        ];
      }
    );
  }

  return newConditionsConfiguration;
};

export const buildConditionString = (
  conditionSet: ConditionSet | undefined
) => {
  if (!conditionSet) {
    return '';
  }
  return checkCondition(conditionSet) || '';
};

export const checkCondition = (conditionSet: ConditionSet): string => {
  let conditionString = '';

  if (conditionSet?.conditions) {
    const condition = Object.values(conditionSet.conditions).map(
      (formValueSet: ConditionSet | Condition) => {
        if (isConditionSet(formValueSet)) {
          return checkCondition(formValueSet);
        } else if (isCondition(formValueSet)) {
          return prepareCondition(formValueSet);
        } else {
          return '';
        }
      }
    );

    conditionString +=
      '(' +
      condition
        .toString()
        .replace(
          /,/g,
          ` ${operatorMap[conditionSet.disjunction]['js'] || ''} `
        ) +
      ')';
  }

  return conditionString;
};

export const prepareCondition = (condition: Condition) => {
  return (
    condition.operator &&
    operatorMap[condition.operator]['js']
      .replace(/{field}/g, ` {${condition?.field}}` || '""')
      .replace(/{value}/g, ` "${condition?.value}"` || '""')
  );
};

export const executeCondition = (
  conditionString: string,
  data: Record<string, unknown>
) => {
  conditionString = replaceTokenFromCondition(conditionString, data);
  return executeConditionValues(conditionString);
};

const replaceTokenFromCondition = (
  condition: string,
  data: Record<string, unknown>
) => {
  const pattern = /{\s*([\w&.-]+?)\s*}/g; // {property}
  return condition.replace(pattern, (_, token) => {
    const d = (data[`${token}`] as string) ?? '';
    return d ? ` "${encodeURI(d)}" ` : `""`;
  });
};

const executeConditionValues = (conditionString: string) => {
  try {
    return eval(conditionString);
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.log(e.message);
    }
  }
};

const operatorMap: {
  [key: string]: {
    name: string;
    value: string;
    js: string;
    sql: string;
  };
} = {
  '=': {
    name: 'Equals to',
    value: '=',
    js: '{field} == {value}',
    sql: '=',
  },
  '!=': {
    name: 'Not Equal',
    value: '!=',
    js: '{field} != {value}',
    sql: '<>',
  },
  '>': {
    name: 'Greater than',
    value: '>',
    js: '{field} > {value}',
    sql: '>',
  },
  '>=': {
    name: 'Greater than to equals to',
    value: '>=',
    js: '{field} >= {value}',
    sql: '>=',
  },
  '<': {
    name: 'Less than',
    value: '<',
    js: '{field} < {value}',
    sql: '<',
  },
  '<=': {
    name: 'Less than or equals to',
    value: '<=',
    js: '{field} <= {value}',
    sql: '<=',
  },
  in: {
    name: 'in',
    value: 'in',
    js: '',
    sql: '',
  },
  'not in': {
    name: 'not in',
    value: 'not in',
    js: '',
    sql: '',
  },
  contains: {
    name: 'Contains',
    value: 'containers',
    js: '{field}.includes({value})',
    sql: '<=',
  },
  like: {
    name: 'like',
    value: 'like',
    js: '',
    sql: '',
  },
  and: {
    name: 'and',
    value: 'and',
    js: ' && ',
    sql: 'and',
  },
  or: {
    name: 'or',
    value: 'or',
    js: ' || ',
    sql: 'or',
  },
};
