import {
  ChartConfiguration,
  ConditionSet,
  ElementAction,
  IndicatorConfiguration,
  KanbanConfiguration,
  TableConfiguration,
} from '@assecosolutions/fox-common-models';
import { Parameter, Request } from '@fox/shared/api-administration';

export enum ElementTypes {
  ELEMENT_TYPE_TABLE = 'table',
  ELEMENT_TYPE_CHART = 'chart',
  ELEMENT_TYPE_INDICATOR = 'indicator',
  ELEMENT_TYPE_KANBAN = 'kanban',
}

export interface CreateElementConfiguration {
  name?: string;
  description?: string;
  type: string;
  categories?: [];
  requestConfiguration: ElementRequestConfiguration;
}

export interface ElementConfiguration<
  T =
    | TableConfiguration
    | ChartConfiguration
    | IndicatorConfiguration
    | KanbanConfiguration
> extends CreateElementConfiguration {
  state: 'IN_DEVELOPMENT' | 'IN_REVIEW' | 'RELEASED' | 'RETRACTED';
  version?: {
    major: number;
    minor: number;
    tenant: string;
  };
  detailConfiguration: T;
  actions?: ElementAction[];
  schemaMetaData?: unknown; //soon
  _id?: string;
}

export interface ElementRequestConfiguration {
  request: Request;
  requestInputList: Parameter[];
  dataTransformation: DataTransformation;
}

export interface DataTransformation {
  filter: DataTransformationConfiguration<ConditionSet>[];
  order: DataTransformationConfiguration<OrderAndGroupConfiguration>[];
  group: DataTransformationConfiguration<OrderAndGroupConfiguration>[];
}

export interface DataTransformationConfiguration<T> {
  name: string;
  configuration: T;
  inactive?: boolean;
}

export interface OrderAndGroupConfiguration {
  path: string;
  direction: 'asc' | 'desc';
}

export function isChartElementConfiguration(
  c?: ElementConfiguration<
    ChartConfiguration | TableConfiguration | IndicatorConfiguration
  >
): c is ElementConfiguration<ChartConfiguration> {
  return (
    (c as ElementConfiguration<ChartConfiguration>)?.detailConfiguration
      ?.dataSet !== undefined
  );
}

export function isTableElementConfiguration(
  c?: ElementConfiguration<
    ChartConfiguration | TableConfiguration | IndicatorConfiguration
  >
): c is ElementConfiguration<TableConfiguration> {
  return (
    (c as ElementConfiguration<TableConfiguration>)?.detailConfiguration
      ?.columns !== undefined
  );
}

export function isIndicatorElementConfiguration(
  c?: ElementConfiguration<
    ChartConfiguration | TableConfiguration | IndicatorConfiguration
  >
): c is ElementConfiguration<IndicatorConfiguration> {
  return (
    (c as ElementConfiguration<IndicatorConfiguration>).detailConfiguration
      .parameters !== undefined
  );
}
