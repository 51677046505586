import { maxBy, minBy, sumBy, isFinite, meanBy } from 'lodash-es';

import {
  isNil,
  isNotNil,
  isNumber,
  isString,
  UnknownRecord,
} from '@assecosolutions/fox-common-utils';

import { FormulasConfiguration, FoxFormulasTypes } from './formulas.model';

export const parsePathValueToFloat = (
  items: UnknownRecord[],
  configuration: FormulasConfiguration
): { [key: string]: number }[] | undefined => {
  if (!configuration.path) {
    return undefined;
  } else {
    const path = configuration.path;

    return items.reduce(function (result: { [key: string]: number }[], item) {
      let value: number | undefined;
      if (isNumber(item[path])) {
        value = item[path] as number;
      } else if (isString(item[path])) {
        const parsedValue = parseFloat(item[path] as string);
        value = isFinite(parsedValue) ? parsedValue : undefined;
      } else {
        value = undefined;
      }

      if (isNil(value) && isNotNil(configuration.mapTo)) {
        value = configuration.mapTo;
      }

      if (isNotNil(value)) {
        result.push({ [path]: value as number });
      }

      return result;
    }, []);
  }
};

export const sum = (
  items: UnknownRecord[],
  configuration: FormulasConfiguration
): number | undefined => {
  const path = configuration.path;
  if (!path) {
    return undefined;
  }
  const parsedItems = parsePathValueToFloat(items, configuration);
  return sumBy(parsedItems, path);
};

export const median = (
  items: UnknownRecord[],
  configuration: FormulasConfiguration
): number | undefined => {
  const path = configuration.path;
  if (!path) {
    return undefined;
  }
  const parsedItems = parsePathValueToFloat(items, configuration);
  if (!parsedItems || parsedItems?.length === 0) {
    return undefined;
  }

  parsedItems.sort(function (a, b) {
    return a[path] - b[path];
  });

  const half = Math.floor(parsedItems.length / 2);

  if (parsedItems.length % 2) {
    return parsedItems[half][path];
  } else {
    return (parsedItems[half - 1][path] + parsedItems[half][path]) / 2;
  }
};

export const average = (
  items: UnknownRecord[],
  configuration: FormulasConfiguration
): number | undefined => {
  const parsedItems = parsePathValueToFloat(items, configuration);
  const path = configuration.path;

  if (!parsedItems || !path) {
    return undefined;
  }

  const mean = meanBy(parsedItems, path);

  return isFinite(mean) ? mean : undefined;
};

export const count = (
  items: UnknownRecord[],
  configuration: FormulasConfiguration
): number | undefined => {
  const path = configuration.path;

  return path
    ? items.filter((item) => isNotNil(item[path])).length
    : items.length;
};

export const min = (
  items: UnknownRecord[],
  configuration: FormulasConfiguration
): number | undefined => {
  const parsedItems = parsePathValueToFloat(items, configuration);
  const path = configuration.path;
  if (!path) {
    return undefined;
  }

  const minElement = minBy(parsedItems, path);

  return minElement && isNotNil(minElement[path]) && isFinite(minElement[path])
    ? minElement[path]
    : undefined;
};

export const max = (
  items: UnknownRecord[],
  configuration: FormulasConfiguration
): number | undefined => {
  const parsedItems = parsePathValueToFloat(items, configuration);
  const path = configuration.path;
  if (!path) {
    return undefined;
  }

  const maxElement = maxBy(parsedItems, path);

  return maxElement && isNotNil(maxElement[path]) && isFinite(maxElement[path])
    ? maxElement[path]
    : undefined;
};

export const foxFormulasMap: {
  [key in FoxFormulasTypes]: (
    items: UnknownRecord[],
    configuration: FormulasConfiguration
  ) => number | undefined;
} = {
  sum,
  median,
  average,
  count,
  max,
  min,
};
