import { MenuBase } from '@material/mwc-menu/mwc-menu-base';
import { styles } from '@material/mwc-menu/mwc-menu.css';

export {
  createSetFromIndex,
  isEventMulti,
  isIndexSet,
  MWCListIndex,
} from '@material/mwc-list/mwc-list-foundation.js';
export { DefaultFocusState } from '@material/mwc-menu/mwc-menu-base.js';
export {
  Corner,
  MenuCorner,
} from '@material/mwc-menu/mwc-menu-surface-base.js';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxMenu;
  }
}

export class FoxMenu extends MenuBase {
  /**
   * @ignore
   */
  static styles = [styles];
}

const name = 'fox-menu';

if (!customElements.get(name)) {
  customElements.define(name, FoxMenu);
} else {
  console.warn(`${name} is already defined`);
}
