import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
  OnChanges,
} from '@angular/core';

import { hasChanged, NgChanges } from '@assecosolutions/ng-common';
import { ElementConfiguration } from '@fox/shared/models';
import { AutoUnsubscribe } from '@fox/shared/util-rxjs';

import {
  ElementExecutorState,
  ElementExecutorStore,
} from './+state/element-executor/element-executor.store';
import { PreviewStoreModule } from './+state/preview/preview.store.module';
import { ActionExecutorService } from './action-executor/action-executor.service';
import { ElementExecutorChartComponentModule } from './element-executor-chart/element-executor-chart.component';
import { ElementExecutorIndicatorComponentModule } from './element-executor-indicator/element-executor-indicator.component';
import { ElementExecutorKanbanComponentModule } from './element-executor-kanban/element-executor-kanban.component';
import { ElementExecutorTableComponentModule } from './element-executor-table/element-executor-table.component';
import { ElementExecutorOptions } from './element-executor.model';

@Component({
  selector: 'fox-element-executor',
  templateUrl: './element-executor.component.html',
  styleUrls: ['./element-executor.component.scss'],
  providers: [ActionExecutorService, ElementExecutorStore],
})
export class ElementExecutorComponent
  extends AutoUnsubscribe
  implements OnChanges
{
  @Input()
  configuration?: ElementConfiguration;

  @Input()
  data: unknown;

  @Input()
  disableActions = false;

  @Input()
  options: ElementExecutorOptions | undefined = undefined;

  @Input()
  loadDataByRequest = false;

  @Input()
  loading = false;

  constructor(public store: ElementExecutorStore) {
    super();
  }

  ngOnChanges(changes: NgChanges<ElementExecutorComponent>): void {
    if (!this.configuration) {
      return;
    }

    if (!hasChanged(changes, 'configuration')) {
      return;
    }

    const state: ElementExecutorState = {
      configuration: this.configuration,
      disableActions: this.disableActions,
      options: this.options,
      loadDataByRequest: this.loadDataByRequest,
      data: this.data,
    };

    this.store.setElementExecutorState(state);
    this.store.loadData();
  }

  onUpdatedElementConfiguration(elementConfiguration: ElementConfiguration) {
    this.store.updateConfiguration(elementConfiguration);
  }
}

@NgModule({
  imports: [
    CommonModule,
    ElementExecutorTableComponentModule,
    PreviewStoreModule,
    ElementExecutorIndicatorComponentModule,
    ElementExecutorChartComponentModule,
    ElementExecutorKanbanComponentModule,
  ],
  declarations: [ElementExecutorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ElementExecutorComponent],
})
export class ElementExecutorComponentModule {}
