<fox-card style="height: 400px" #card>
  <div slot="header" class="d-flex">
    <div class="p-3 pr-0" *ngIf="nameValue() || countValue()">
      <p class="m-0 semi-bold" *ngIf="nameValue()">{{ nameValue() }}</p>
      <p class="m-0" *ngIf="countValue()">{{ countValue() }} Results</p>
    </div>
    <div class="ml-auto d-flex p-3 pr-0" *ngIf="!options?.disableActions">
      <fox-search (search)="onSearch($event)"></fox-search>
      <div class="menu-wrapper">
        <fox-icon-button
          icon="more_vert"
          (click)="onOpenMenu($event)"
          (contextmenu)="onContextmenu($event)"
        ></fox-icon-button>
      </div>
    </div>
  </div>
  <fox-table-executor
    [dataObject]="filteredData || data"
    [configuration]="elementConfiguration?.detailConfiguration?.columns"
    [showPlaceholder]="options?.showPlaceholder"
    [showIndex]="options?.showIndex"
    (cell-click)="onCellClick($event)"
    (contextmenu)="onTableContextmenu()"
    (cell-right-click)="onRightClick($event)"
    #table
  ></fox-table-executor>
</fox-card>
