/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DependencyRequest { 
    configurationId: string;
    range: string;
    configurationType: DependencyRequest.ConfigurationTypeEnum;
}
export namespace DependencyRequest {
    export type ConfigurationTypeEnum = 'DATASOURCE' | 'ELEMENT' | 'PAGE' | 'REQUEST' | 'MODULE';
    export const ConfigurationTypeEnum = {
        Datasource: 'DATASOURCE' as ConfigurationTypeEnum,
        Element: 'ELEMENT' as ConfigurationTypeEnum,
        Page: 'PAGE' as ConfigurationTypeEnum,
        Request: 'REQUEST' as ConfigurationTypeEnum,
        Module: 'MODULE' as ConfigurationTypeEnum
    };
}


