import { TabBarBase } from '@material/mwc-tab-bar/mwc-tab-bar-base';
import { styles as mwcStyles } from '@material/mwc-tab-bar/mwc-tab-bar.css';

import { styles } from './FoxTabBar.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxTabBar;
  }
}

export class FoxTabBar extends TabBarBase {
  /**
   * @ignore
   */
  static styles = [styles, mwcStyles];
}

const name = 'fox-tab-bar';

if (!customElements.get(name)) {
  customElements.define(name, FoxTabBar);
} else {
  console.warn(`${name} is already defined`);
}
