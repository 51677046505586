import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
  OnChanges,
} from '@angular/core';

import {
  ElementActionTargetEnum,
  ElementActionTriggerEnum,
  FieldConfiguration,
  KanbanCardsConfiguration,
  KanbanConfiguration,
} from '@assecosolutions/fox-common-models';
import {
  ensureNotNil,
  isArray,
  UnknownRecord,
  valueByPath,
} from '@assecosolutions/fox-common-utils';
import { enrichConfigurationByConditionString } from '@assecosolutions/fox-condition-executor';
import { hasChanged, NgChanges } from '@assecosolutions/ng-common';
import { SearchComponentModule } from '@assecosolutions/ng-search';
import { ElementConfiguration } from '@fox/shared/models';

import { ElementExecutorBaseComponent } from '../element-executor-base.component';
import { ElementExecutorOptions } from '../element-executor.model';
import {
  filterElementActionsByEvent,
  menuIcon,
} from '../element-executor.utils';
import { ElementExecutorKanbanCardComponentModule } from './element-executor-kanban-card/element-executor-kanban-card.component';

@Component({
  selector: 'fox-element-executor-kanban',
  templateUrl: './element-executor-kanban.component.html',
  styleUrls: ['./element-executor-kanban.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementExecutorKanbanComponent
  extends ElementExecutorBaseComponent
  implements OnChanges
{
  @Input()
  elementConfiguration?: ElementConfiguration<KanbanConfiguration> | undefined;

  @Input()
  options: ElementExecutorOptions | undefined = undefined;

  @Input()
  data?: unknown;

  enrichedKanbanCardsConfiguration: KanbanCardsConfiguration = {
    footer: [],
    title: [],
    rows: [],
  };

  get type() {
    return this.elementConfiguration?.detailConfiguration?.type;
  }

  get columns() {
    return this.elementConfiguration?.detailConfiguration?.columns ?? [];
  }

  get cardConfiguration() {
    return this.elementConfiguration?.detailConfiguration?.cards;
  }

  get menuIcon() {
    if (!this.elementConfiguration) {
      return undefined;
    }
    return menuIcon(this.elementConfiguration, ElementActionTargetEnum.LANE);
  }

  ngOnChanges(changes: NgChanges<ElementExecutorKanbanComponent>) {
    if (hasChanged(changes, 'elementConfiguration')) {
      if (
        this.cardConfiguration?.footer &&
        isArray(this.cardConfiguration?.footer)
      ) {
        this.enrichedKanbanCardsConfiguration.footer =
          this.enrichedKanbanCardsConfigurationByConditionString(
            this.cardConfiguration?.footer
          );
      }
      if (
        this.cardConfiguration?.rows &&
        isArray(this.cardConfiguration?.rows)
      ) {
        this.enrichedKanbanCardsConfiguration.rows =
          this.enrichedKanbanCardsConfigurationByConditionString(
            this.cardConfiguration?.rows
          );
      }
    }
  }

  toUnknownRecord(data: unknown) {
    return data as UnknownRecord[];
  }

  configurationToCardTitle(card: UnknownRecord) {
    let title = '';
    let link = '';

    if (this.cardConfiguration && this.cardConfiguration.title) {
      this.cardConfiguration.title.map((configuration) => {
        title = valueByPath(card, configuration?.path || '');
        link = configuration?.type === 'link' ? configuration.value || '' : '';
      });
    }

    return { title, link };
  }

  enrichedKanbanCardsConfigurationByConditionString(
    configuration: FieldConfiguration[]
  ) {
    const config: FieldConfiguration[] = [];

    configuration.map((configuration) => {
      if (configuration.conditionsConfiguration) {
        config.push({
          ...configuration,
          conditionsConfiguration: enrichConfigurationByConditionString(
            configuration.conditionsConfiguration
          ),
        });
      } else {
        config.push(configuration);
      }
    });

    return config;
  }

  onCardLeftClick(subject: { e: MouseEvent; item: UnknownRecord }) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.LEFT_CLICK,
        target: ElementActionTargetEnum.CARD,
        mouseEvent: subject.e,
        selectedItem: subject.item,
      },
      ensureNotNil(this.elementConfiguration)
    );
  }

  onCardRightClick(subject: { e: MouseEvent; item: UnknownRecord }) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.RIGHT_CLICK,
        target: ElementActionTargetEnum.CARD,
        mouseEvent: subject.e,
        selectedItem: subject.item,
      },
      ensureNotNil(this.elementConfiguration)
    );
  }

  onOpenMenu(e: MouseEvent) {
    const filteredActions = filterElementActionsByEvent(
      {
        trigger: ElementActionTriggerEnum.LEFT_CLICK,
        target: ElementActionTargetEnum.LANE,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );

    this.openElementMenu(e.x / 2, e.y / 2, filteredActions);
  }

  onContextmenu(e: MouseEvent) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.RIGHT_CLICK,
        target: ElementActionTargetEnum.LANE,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );

    return false;
  }
}

@NgModule({
  imports: [
    CommonModule,
    ElementExecutorKanbanCardComponentModule,
    SearchComponentModule,
  ],
  declarations: [ElementExecutorKanbanComponent],
  exports: [ElementExecutorKanbanComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ElementExecutorKanbanComponentModule {}
