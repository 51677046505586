import { TableConfiguration } from '@assecosolutions/fox-common-models';
import { isNotNil } from '@assecosolutions/fox-common-utils';
import { ElementConfiguration } from '@fox/shared/models';

export const applyRequestOrderConfigurationToTableColumnConfiguration = (
  configuration: ElementConfiguration<TableConfiguration>
): TableConfiguration => {
  const order = configuration?.requestConfiguration?.dataTransformation?.order;
  let detailConfiguration = configuration?.detailConfiguration;

  if (isNotNil(order)) {
    order.map((o) => {
      const updatedColumns = detailConfiguration?.columns?.map((column) => {
        if (column.path === o.configuration.path) {
          return {
            ...column,
            direction: o.configuration.direction,
          };
        }
        return column;
      });

      if (isNotNil(updatedColumns) && isNotNil(detailConfiguration)) {
        detailConfiguration = {
          ...detailConfiguration,
          columns: updatedColumns,
        };
      }
    });
  }
  return detailConfiguration;
};
