<fox-textfield
  #input
  iconTrailing="search"
  label="Search"
  type="search"
  [ngClass]="{ focused: inputFocused }"
  (input)="onInput()"
  (focus)="onFocusSearch()"
  (focusout)="onFocusOutSearch()"
></fox-textfield>
