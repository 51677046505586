import {
  GridActiveItemChanged,
  GridCellActivate,
  GridEventContext,
  GridSelectedItemsChanged,
} from '@vaadin/vaadin-grid';
import { html, LitElement, nothing, TemplateResult } from 'lit';
import { property, query } from 'lit/decorators.js';

import { TableColumnConfiguration } from '@assecosolutions/fox-common-models';
import { customItemEvent } from '@assecosolutions/fox-common-utils';
import { enrichConfigurationByConditionString } from '@assecosolutions/fox-condition-executor';
import { FoxTable } from '@assecosolutions/fox-table';

import { styles } from './FoxTableExecutor.css';
import { GridItem } from './FoxTableExecutor.model';
import * as tableFunctions from './FoxTableExecutorFunctions';
import '@assecosolutions/fox-badge';
import '@assecosolutions/fox-icon';
import '@assecosolutions/fox-text';
import '@assecosolutions/fox-textfield';
import '@assecosolutions/fox-checkbox';
import '@assecosolutions/fox-table';
import '@assecosolutions/fox-typed-field';

const placeholder = [
  { type: 'placeholder' },
  { type: 'placeholder' },
  { type: 'placeholder' },
  { type: 'placeholder' },
];

export class FoxTableExecutor extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: Array }) dataObject: GridItem[];
  @property({ type: Array }) configuration: TableColumnConfiguration[] = [];
  @property({ type: Array }) activeItem: GridItem;
  @property({ type: Array }) selectedItems: GridItem[];
  @property({ type: Boolean }) selection = false;
  @property({ type: Boolean }) multiSort = true;
  @property({ type: Boolean }) reorder = true;
  @property({ type: String, reflect: true }) theme = 'column-borders';
  @property({ type: Boolean }) showIndex = false;
  @property({ type: Boolean }) showPlaceholder = false;

  @property({ type: Object }) eventOptions = { bubbles: true, composed: true };

  @query('fox-table') table: FoxTable;

  render(): TemplateResult {
    if (this.showPlaceholder) {
      return html` <fox-table .items="${this.dataObject ?? placeholder}">
        ${this.renderColumnsByConfiguration(placeholder)}
      </fox-table>`;
    }

    if (this.configuration) {
      this.prepareCondition();
    }

    return html`
      <fox-table
        theme="${this.theme}"
        .items="${this.dataObject}"
        ?column-reordering-allowed="${this.reorder}"
        ?multi-sort="${this.multiSort}"
        @active-item-changed="${this.onActiveItemChanged}"
        @dblclick="${this.onDblclick}"
        @selected-items-changed="${this.onSelectedItemChanged}"
        @click="${this.onCellClick}"
        @contextmenu="${this.onCellRightClick}"
        @cell-activate="${this.onCellActivate}"
      >
        ${this.selection ? tableFunctions.renderSelectionColumn() : ''}
        ${this.showIndex ? tableFunctions.renderIndexColumn() : ''}

        <slot name="start"></slot>

        ${this.renderColumnsByConfiguration(this.configuration)}

        <slot name="end"></slot>
      </fox-table>
    `;
  }

  selectSingleItem(item: GridItem) {
    this.table.selectedItems = [item];
  }

  deselectItem(item: GridItem) {
    this.table.deselectItem(item);
  }

  deselectItems() {
    this.table.selectedItems = [];
  }

  private renderColumnsByConfiguration(
    configuration: TableColumnConfiguration[]
  ) {
    return Array.isArray(configuration)
      ? configuration.map((tableColumn: TableColumnConfiguration) =>
          tableColumn.hidden
            ? undefined
            : tableFunctions.renderDefaultTableColumn(tableColumn, this)
        )
      : nothing;
  }

  /** events **/
  private onActiveItemChanged(e: GridActiveItemChanged) {
    this.activeItem = e.detail.value;

    this.dispatchEvent(
      customItemEvent('active-item-changed', this.activeItem, {
        eventInit: this.eventOptions,
      })
    );
  }

  private onDblclick(e: MouseEvent) {
    //https://cdn.vaadin.com/vaadin-grid/5.3.0-alpha6/demo/#grid-event-context-demos
    this.dispatchEvent(
      customItemEvent(
        'dblClick',
        (this.table.getEventContext(e) as GridEventContext).item,
        {
          eventInit: this.eventOptions,
        }
      )
    );
  }

  private onSelectedItemChanged(e: GridSelectedItemsChanged) {
    this.selectedItems = e.detail.value;

    this.dispatchEvent(
      customItemEvent('selected-items-changed', this.selectedItems, {
        eventInit: this.eventOptions,
      })
    );
  }

  private onCellActivate(e: GridCellActivate) {
    this.dispatchEvent(
      customItemEvent('cell-activate', e.detail.model.item, {
        eventInit: this.eventOptions,
      })
    );
  }

  private onCellClick(e: Event) {
    const elementContext = this.table.getEventContext(e) as GridEventContext;

    this.dispatchEvent(
      new CustomEvent('cell-click', {
        detail: {
          item: elementContext.item,
          initialEvent: e,
        },
      })
    );
  }

  private onCellRightClick(e: Event) {
    const elementContext = this.table.getEventContext(e) as GridEventContext;

    this.dispatchEvent(
      new CustomEvent('cell-right-click', {
        detail: {
          item: elementContext.item,
          initialEvent: e,
        },
      })
    );

    return false;
  }

  private prepareCondition() {
    let newConfiguration: TableColumnConfiguration[] = [];

    Object.values(this.configuration).map(
      (configuration: TableColumnConfiguration) => {
        newConfiguration = [
          ...newConfiguration,
          {
            ...configuration,
            conditionsConfiguration: enrichConfigurationByConditionString(
              configuration?.conditionsConfiguration
            ),
          },
        ];
      }
    );

    this.configuration = newConfiguration;
  }
}

export const NAME = 'fox-table-executor';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxTableExecutor);
} else {
  console.warn(`${NAME} is already defined`);
}
