import { Condition, ConditionSet } from '@assecosolutions/fox-common-models';

import { Option } from './FoxConditionBuilder.model';

export const emptyConditionSet = (): ConditionSet => ({
  disjunction: 'and',
  conditions: [emptyCondition()],
});

export const emptyCondition = (): Condition => ({
  field: '',
  operator: '',
  value: '',
});

export const OPERATORS: { [key: string]: string[] } = {
  unknown: ['=', '!=', 'contains', '>', '<'],
  string: ['=', '!=', 'contains'],
  number: ['=', '!=', '>', '>=', '<', '<='],
  time: ['=', '!=', '>', '>=', '<', '<='],
  date: ['=', '!=', '>', '>=', '<', '<='],
  category: ['=', '!=', 'in', 'not in'],
  boolean: ['='],
};

export const OPTIONS: { [key: string]: Option[] } = {
  boolean: [
    { name: 'True', value: 'true' },
    { name: 'False', value: 'false' },
  ],
};

/** demo for dev and test */
export const DEMO_CONFIGURATION: ConditionSet = {
  disjunction: 'and',
  conditions: [
    {
      field: 'Gender',
      operator: '=',
      value: 'f',
    },
    {
      field: 'Occupation',
      operator: '!=',
      value: 'unemployed',
    },
    { field: 'Birthday', operator: '=', value: '2012-01-01' },
    {
      disjunction: 'and',
      conditions: [
        { field: 'Name', operator: '=', value: 'Lorem2' },
        { field: 'Name', operator: '=', value: 'Lorem2' },
        {
          disjunction: 'and',
          conditions: [
            { field: 'Name', operator: '=', value: 'Lorem3' },
            { field: 'Name', operator: '=', value: 'Lorem3' },
          ],
        },
      ],
    },
    { field: 'Name', operator: '=', value: 'Lorem1' },
  ],
};

export const DEMO_FIELDS = [
  { name: 'Age', type: 'number' },
  {
    name: 'Gender',
    type: 'category',
    options: [
      { name: 'Male', value: 'm' },
      { name: 'Female', value: 'f' },
    ],
  },
  { name: 'Name', type: 'string' },
  { name: 'College Degree?', type: 'boolean' },
  { name: 'Birthday', type: 'date' },
  { name: 'Time', type: 'time' },
  { name: 'School', type: 'string' },
  {
    name: 'Occupation',
    type: 'string',
    options: [
      { name: 'Student', value: 'student' },
      { name: 'Teacher', value: 'teacher' },
      { name: 'Unemployed', value: 'unemployed' },
      { name: 'Scientist', value: 'scientist' },
    ],
  },
];

/* type-guards */
export function isCondition(c: ConditionSet | Condition): c is Condition {
  return (c as Condition).field !== undefined;
}

export function isConditionSet(c: ConditionSet | Condition): c is ConditionSet {
  return (c as ConditionSet).disjunction !== undefined;
}
