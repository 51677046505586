import { AbstractControl, ValidationErrors } from '@angular/forms';

import { isEmpty } from '@assecosolutions/fox-common-utils';

export class FoxValidators {
  static notEmpty(control: AbstractControl): ValidationErrors | null {
    return notEmptyValidator(control);
  }

  static isValidPath(control: AbstractControl): ValidationErrors | null {
    return !isValidUrl(control.value)
      ? { pathInvalid: 'Path is not a valid URL' }
      : null;
  }

  static isInvalidPath(control: AbstractControl): ValidationErrors | null {
    const urlPattern = new RegExp('^[ A-Za-z0-9_@-]*$', 'i').test(
      control.value
    );

    return urlPattern
      ? null
      : { pathInvalid: 'Path contains not allowed special characters' };
  }
}

const notEmptyValidator = (
  control: AbstractControl
): ValidationErrors | null => {
  return isEmpty(control.value) ? { empty: true } : null;
};

export const isValidUrl = (value: string): boolean => {
  try {
    return Boolean(new URL(value));
  } catch (e) {
    return false;
  }
};
