import {
  ComponentRef,
  createNgModuleRef,
  Injectable,
  Injector,
  TemplateRef,
  Type,
} from '@angular/core';

import { DEFAULT_DIALOG_OPTIONS } from '..';
import { DialogBaseComponent } from './dialog-base';
import {
  ComponentDialogOptions,
  ComponentNotRegistered,
  Dialog,
  DialogOptions,
} from './dialog.model';

@Injectable()
export class DialogService {
  private _components: Map<string, Dialog> = new Map<string, Dialog>();

  private get(byId: string): Dialog {
    if (this._components.has(byId)) {
      return this._components.get(byId) as Dialog;
    } else {
      throw new ComponentNotRegistered(byId);
    }
  }

  get registeredComponents(): Map<string, Dialog> {
    return Object.freeze(this._components);
  }

  constructor(private injector: Injector) {}

  register(component: Dialog) {
    this._components.set(component.id, component);
  }

  // TODO add slot template
  openDialogWithTemplate(
    bodyTemplateRef: TemplateRef<unknown>,
    dialogOptions?: Partial<DialogOptions>
  ): void {
    const options = this.mergeWithDefaultOptions(dialogOptions);
    const dialog: Dialog = this.get(options.id);

    this.applyOptions(dialog, options);
    dialog.bodyTemplateRef = bodyTemplateRef;
    dialog.open();
  }

  openDialogWithComponent<C extends DialogBaseComponent, M>(
    component: Type<C>,
    dialogOptions?: Partial<ComponentDialogOptions<C>>,
    ngModule?: Type<M>
  ): ComponentRef<C> {
    const options = this.mergeWithDefaultOptions(dialogOptions);
    const dialog: Dialog = this.get(options.id);

    dialog.viewContainer?.clear();
    this.applyOptions(dialog, options);
    dialog.open();

    const createComponentOptions = ngModule
      ? { ngModuleRef: createNgModuleRef(ngModule, this.injector) }
      : {};
    const ref = dialog.viewContainer.createComponent<C>(
      component,
      createComponentOptions
    );

    if (options.inputs) {
      Object.assign(ref.instance, options.inputs);
    }

    ref.changeDetectorRef.detectChanges();
    dialog.slotTemplateRef = ref.instance.slotTemplateRef;
    ref.hostView?.markForCheck();
    return ref;
  }

  closeDialog(id: string = 'default'): void {
    const dialogComponent: Dialog = this.get(id);
    dialogComponent.close();
  }

  private applyOptions(dialog: Dialog, options: DialogOptions) {
    dialog.heading = options.heading ?? '';
    dialog.type = options.type;
    dialog.hideActions = options.hideActions;
    dialog.noBorder = options.noBorder;
    dialog.noPadding = options.noPadding;

    dialog.scrimClickAction = options.scrimClickAction;
    dialog.escapeKeyAction = options.escapeKeyAction;
    dialog.defaultAction = options.defaultAction;
  }

  private mergeWithDefaultOptions<T = unknown>(
    dialogOptions?: Partial<ComponentDialogOptions<T>>
  ): ComponentDialogOptions<T> {
    return dialogOptions
      ? {
          ...DEFAULT_DIALOG_OPTIONS,
          ...dialogOptions,
        }
      : DEFAULT_DIALOG_OPTIONS;
  }
}
