/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/directive-selector,@angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Directive,
  forwardRef,
  NgModule,
  StaticProvider,
} from '@angular/core';
import {
  CheckboxControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

export const CHECKBOX_VALUE_ACCESSOR: StaticProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FoxCheckboxControlValueAccessor),
  multi: true,
};

@Directive({
  selector: 'fox-checkbox',
  host: {
    '(change)': 'onChange($event.target.checked)',
    '(blur)': 'onTouched()',
  },
  providers: [CHECKBOX_VALUE_ACCESSOR],
})
export class FoxCheckboxControlValueAccessor extends CheckboxControlValueAccessor {}

@NgModule({
  declarations: [FoxCheckboxControlValueAccessor],
  exports: [FoxCheckboxControlValueAccessor],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CheckboxControlDirectiveModule {}
