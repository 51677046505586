import { html, LitElement, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';

import { customItemEvent } from '@assecosolutions/fox-common-utils';
import '@assecosolutions/fox-icon';

import { styles } from './FoxNavigationBar.css';
import { NavigationListItem } from './FoxNavigationBar.model';

/**
 *
 * FOX Navigation bar
 *
 * @element fox-navigation-bar
 *
 * @slot footer - Optional slot for custom content placed at the bottom
 *
 */

export class FoxNavigationBar extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property() items: NavigationListItem[] = [];

  render() {
    return html`
      <div class="fox-navigation-bar">
        <div class="fox-navigation-bar__list">${this.renderListItems()}</div>

        <div class="fox-navigation-bar__footer">
          <slot name="footer">
            <div class="fox-navigation-bar__list">
              ${this.renderListItems('footer')}
            </div>
          </slot>
        </div>
      </div>
    `;
  }

  protected listItemTemplate(item: NavigationListItem): TemplateResult {
    return html` <div
      class="fox-navigation-bar__list-item ${classMap({
        active: item.active ?? false,
      })}"
      @click="${() => this.dispatchListItemClicked(item)}"
      .title="${ifDefined(
        item.name && item.name.length > 12 ? item.name : undefined
      )}"
    >
      <div class="inner">
        <fox-icon>${item.icon}</fox-icon>
        <div class="caption">${item.name}</div>
      </div>
    </div>`;
  }

  protected renderListItems(slot: string | null = null): TemplateResult | void {
    if (this.items) {
      const items = this.items.filter((item: NavigationListItem) => {
        return slot === 'footer' ? item.slot === slot : item.slot !== 'footer';
      });

      return html`${repeat(
        items,
        (item) => item.name,
        (item) => this.listItemTemplate(item)
      )}`;
    }
  }

  dispatchListItemClicked(item: NavigationListItem) {
    this.dispatchEvent(customItemEvent('list-item-clicked', item));
  }
}

export const NAME = 'fox-navigation-bar';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxNavigationBar);
} else {
  console.warn(`${NAME} is already defined`);
}
