import '@vaadin/vaadin-grid/theme/material/vaadin-grid-selection-column.js';
import { GridSelectionColumnElement } from '@vaadin/vaadin-grid/src/vaadin-grid-selection-column.js';

import { findHostGrid } from './FoxTableHelper';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-selection-column': FoxTableSelectionColumn;
  }
}

export class FoxTableSelectionColumn extends GridSelectionColumnElement {
  _findHostGrid() {
    return findHostGrid(this);
  }
}

window.customElements.define(
  'fox-table-selection-column',
  FoxTableSelectionColumn
);
