import { GridDropLocation } from '@vaadin/vaadin-grid/src/interfaces';
import { GridColumnElement } from '@vaadin/vaadin-grid/src/vaadin-grid-column';

import { FoxTableExecutor, NAME } from './FoxTableExecutor';

declare global {
  interface HTMLElementTagNameMap {
    [NAME]: FoxTableExecutor;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  interface HTMLElementEventMap {
    'active-item-changed': TableActiveItemChanged<any>;

    'cell-click': TableCellClick<unknown>;

    'cell-right-click': TableCellClick<unknown>;

    dblClick: TableDblClick<any>;

    'cell-activate': TableCellActivate;

    'column-resize': TableColumnResize;

    'expanded-items-changed': TableExpandedItemsChanged<any>;

    'table-dragstart': TableDragStart<any>;

    'table-dragend': Event;

    'table-drop': TableDrop<any>;

    'loading-changed': TableLoadingChanged;

    'selected-items-changed': TableSelectedItemsChanged<any>;
  }
}

export type GridItem = unknown;

export interface GridItemModel {
  index: number;
  item: Record<string, string>;
  flatItem: Record<string, unknown>;
  selected?: boolean;
  expanded?: boolean;
  level?: number;
  detailsOpened?: boolean;
}

export type TableActiveItemChanged<T> = CustomEvent<{ item: T }>;
export type TableCellClick<T> = CustomEvent<{
  item: T;
  initialEvent: MouseEvent;
}>;
export type TableDblClick<T> = CustomEvent<{ item: T }>;
export type TableCellActivate = CustomEvent<{ model: GridItemModel }>;
export type TableColumnResize = CustomEvent<{
  resizedColumn: GridColumnElement;
}>;
export type TableExpandedItemsChanged<T> = CustomEvent<{ item: T[] }>;
export type TableDragStart<T> = CustomEvent<{
  draggedItems: T[];
  setDraggedItemsCount: (count: number) => void;
  setDragData: (type: string, data: string) => void;
}>;
export type TableDrop<T> = CustomEvent<{
  dropTargetItem: T;
  dropLocation: GridDropLocation;
  dragData: Array<{ type: string; data: string }>;
}>;
export type TableLoadingChanged = CustomEvent<{ item: boolean }>;
export type TableSelectedItemsChanged<T> = CustomEvent<{ item: T[] }>;
