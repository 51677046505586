import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import {
  ElementAction,
  ElementActionResultEnum,
  FieldConfiguration,
  PreviewConfiguration,
} from '@assecosolutions/fox-common-models';
import { isArray } from '@assecosolutions/fox-common-utils';
import { DialogService } from '@assecosolutions/ng-dialog';
import { RequestExecutorService } from '@fox/shared-util-request-executor';
import { Request } from '@fox/shared/api-administration';
import { AutoUnsubscribe } from '@fox/shared/util-rxjs';

import { PreviewFacade } from '../+state/preview/preview.facade';
import { ActionExecutorDialogComponent } from './action-executor-dialog.component';

@Injectable()
export class ActionExecutorService extends AutoUnsubscribe {
  private window: Window | null;

  constructor(
    private previewFacade: PreviewFacade,
    private requestExecutor: RequestExecutorService,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.window = this.document.defaultView;
  }

  executeAction(elementAction: ElementAction, item?: unknown) {
    switch (elementAction.result) {
      case ElementActionResultEnum.OPEN_URL:
        this.onOpenUrl(elementAction);
        break;
      case ElementActionResultEnum.RUN_ACTION:
        this.onRunAction(elementAction);
        break;
      case ElementActionResultEnum.OPEN_PREVIEW:
        this.onOpenPreview(elementAction, item);
        break;
      default:
        throw new Error('Element Action not supported');
    }
  }

  private onOpenUrl(elementAction: ElementAction) {
    this.window?.open(
      (<ElementAction<{ url: string }>>elementAction)?.parameters?.url,
      '_blank'
    );
  }

  private onRunAction(elementAction: ElementAction) {
    const configuration = elementAction?.parameters
      ?.configuration as FieldConfiguration[];

    const request = (elementAction as ElementAction<Request>)?.parameters;

    if (request) {
      if (isArray(configuration) && configuration.length) {
        const ref = this.dialogService.openDialogWithComponent(
          ActionExecutorDialogComponent,
          {
            heading: elementAction.name,
            scrimClickAction: '',
            inputs: { elementAction, configuration },
          }
        );

        this.subscribeTo(ref.instance.save, (requestInputList) => {
          this.requestExecutor.execute({
            ...request,
            requestInputList,
          });

          this.dialogService.closeDialog();
        });
      } else {
        this.requestExecutor.execute(request);
      }
    }
  }

  private onOpenPreview(elementAction: ElementAction, item: unknown) {
    const previewConfiguration = (
      elementAction as unknown as ElementAction<PreviewConfiguration>
    ).parameters;
    if (previewConfiguration) {
      this.previewFacade.openPreview(item, previewConfiguration);
    }
  }
}
