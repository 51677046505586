import { html, LitElement, TemplateResult } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { FoxMenu } from '@assecosolutions/fox-menu';

import { styles } from './FoxUserProfile.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxUserProfile;
  }
}

/**
 * Fox-user-profile
 *
 * @element fox-user-profile
 */
export class FoxUserProfile extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: String }) profileName = '';
  @property({ type: Boolean }) hideProfileName = false;
  @property({ type: String }) profileClient = '';
  @property({ type: String }) profileImage = '';
  @property({ type: Boolean }) small = false;

  @property({ type: Boolean }) protected showProfileMenu = false;

  @query('fox-menu') protected foxMenu!: FoxMenu;

  @query('.fox-user-profile') protected foxUserProfile!: FoxUserProfile;

  render() {
    const classes = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fox-user-profile__small': this.small,
      'fox-user-profile__menu-active': this.showProfileMenu,
    };

    return html`
      <div
        class="fox-user-profile ${classMap(classes)}"
        @click="${this.onUserProfileClick}"
      >
        <div class="fox-user-profile__name">
          <p class="client">${this.profileClient}</p>
          ${!this.hideProfileName
            ? html`<p class="name">${this.profileName}</p>`
            : html``}
        </div>
        ${this.renderImage()}
      </div>
      <fox-menu corner="BOTTOM_END" menucorner="END" fixed>
        <slot></slot>
        <slot name="profileMenu"></slot>
      </fox-menu>
    `;
  }

  protected firstUpdated() {
    const defaultSlot = this.shadowRoot?.querySelector(
      'slot'
    ) as HTMLSlotElement;
    const namedSlot = this.shadowRoot?.querySelector(
      'slot[name=profileMenu]'
    ) as HTMLSlotElement;

    if (
      defaultSlot.assignedNodes().length ||
      namedSlot.assignedNodes().length
    ) {
      this.showProfileMenu = true;
    }
  }

  protected renderImage(): TemplateResult | void {
    if (this.profileImage) {
      return html` <img
        class="fox-user-profile__image"
        src="${this.profileImage}"
        alt="${this.profileName}"
      />`;
    }

    if (!this.profileImage && this.profileName) {
      let initials = '';

      this.profileName.split(' ', 2).forEach((element) => {
        initials += element[0];
      });
      return html`<span
        class="fox-user-profile__image-initials fox-user-profile__image"
      >
        <span class="initials">${initials}</span>
      </span>`;
    }
    return html``;
  }

  protected onUserProfileClick() {
    if (this.showProfileMenu) {
      this.foxMenu.show();
      this.foxMenu.anchor = this.foxUserProfile;
    }
    this.dispatchEvent(new CustomEvent('profile-click'));
  }
}

const name = 'fox-user-profile';

if (!customElements.get(name)) {
  customElements.define(name, FoxUserProfile);
} else {
  console.warn(`${name} is already defined`);
}
