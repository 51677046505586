import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep } from 'lodash-es';

import {
  FoxComboBoxSelectedItemsChanged,
  FoxMultiselectCombobox,
} from '@assecosolutions/fox-combobox';

import { BaseControlValueAccessor } from './base-control-value-accessor';

@Component({
  selector: 'fox-multiselect-combobox-control',
  template: ` <fox-multiselect-combobox
    #combobox
    [label]="label"
    [items]="internalItems"
    [selectedItems]="value"
    [itemLabelPath]="itemLabelPath"
    [itemValuePath]="itemValuePath"
    [itemIdPath]="itemValuePath"
    [required]="required"
    [disabled]="isDisabled"
    (selected-items-change)="onMultiSelectComboboxItemsChangeEvent($event)"
    (blur)="onTouched()"
    allowcustomvalue
  >
    <ng-content></ng-content>
  </fox-multiselect-combobox>`,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiselectComboboxControlComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectComboboxControlComponent
  extends BaseControlValueAccessor
  implements OnChanges
{
  @Input()
  items: unknown[] = [];

  @Input()
  label = '';

  @Input()
  itemLabelPath = '';

  @Input()
  itemValuePath = '';

  @Input()
  required = false;

  @Output()
  selectedItemsChanged = new EventEmitter<unknown>();

  @ViewChild('combobox', { read: ElementRef })
  combobox: ElementRef<FoxMultiselectCombobox> | null = null;

  internalItems: unknown[] = [];

  onChange(value: unknown) {
    super.onChange(value);
    this.writeValue(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && !!changes.items) {
      this.internalItems = cloneDeep(changes.items.currentValue);
    }
  }

  onMultiSelectComboboxItemsChangeEvent(
    event: FoxComboBoxSelectedItemsChanged<unknown>
  ) {
    this.onChange(event.detail.item);
  }
}

@NgModule({
  declarations: [MultiselectComboboxControlComponent],
  exports: [MultiselectComboboxControlComponent],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MultiselectComboboxControlComponentModule {}
