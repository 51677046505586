/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Category } from './category';
import { DependencyRequest } from './dependencyRequest';
import { SchemaMetaDataRequest } from './schemaMetaDataRequest';
import { VersionRequest } from './versionRequest';


export interface CreateElementVersionRequest { 
    configurationId: string;
    schemaMetaData: SchemaMetaDataRequest;
    version: VersionRequest;
    state: CreateElementVersionRequest.StateEnum;
    dependencies?: Array<DependencyRequest>;
    name: string;
    description?: string;
    type: CreateElementVersionRequest.TypeEnum;
    categories?: Array<Category>;
    detailConfiguration?: object;
    actions?: Array<object>;
    requestConfiguration?: object;
}
export namespace CreateElementVersionRequest {
    export type StateEnum = 'IN_DEVELOPMENT' | 'IN_REVIEW' | 'RELEASED' | 'RETRACTED';
    export const StateEnum = {
        InDevelopment: 'IN_DEVELOPMENT' as StateEnum,
        InReview: 'IN_REVIEW' as StateEnum,
        Released: 'RELEASED' as StateEnum,
        Retracted: 'RETRACTED' as StateEnum
    };
    export type TypeEnum = 'table' | 'chart' | 'indicator' | 'kanban';
    export const TypeEnum = {
        Table: 'table' as TypeEnum,
        Chart: 'chart' as TypeEnum,
        Indicator: 'indicator' as TypeEnum,
        Kanban: 'kanban' as TypeEnum
    };
}


