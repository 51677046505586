import { CheckboxBase } from '@material/mwc-checkbox/mwc-checkbox-base';
import { styles as mwcStyles } from '@material/mwc-checkbox/mwc-checkbox.css';

import { styles } from './FoxCheckbox.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxCheckbox;
  }
}

export class FoxCheckbox extends CheckboxBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-checkbox';

if (!customElements.get(name)) {
  customElements.define(name, FoxCheckbox);
} else {
  console.warn(`${name} is already defined`);
}
