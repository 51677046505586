import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { SharedUiDialogModule } from '@assecosolutions/ng-dialog';
import { SharedUiMenuModule } from '@assecosolutions/ng-menu';
import { AdministrationRoutes } from '@fox/administration-shared-util-routes';
import { CombinedRouterStateSerializer } from '@fox/shared-util-ngrx';
import { ApiModule, Configuration } from '@fox/shared/api-administration';
import { SharedDataAccessNotificationsModule } from '@fox/shared/data-access-notifications';
import { SharedFeaturePageExecutorModule } from '@fox/shared/feature-page-executor';
import { SharedUiNotificationsModule } from '@fox/shared/ui-notifications';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './shared/layouts/default/default-layout.component';
import { DefaultLayoutModule } from './shared/layouts/default/default-layout.module';
import { TenantIdInterceptor } from './shared/tenant-id.interceptor';

const ROUTES: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'elements', pathMatch: 'full' },
      {
        path: AdministrationRoutes.elements.path,
        component: DefaultLayoutComponent,
        loadChildren: () =>
          import('@fox/administration/elements/feature-builder').then(
            (m) => m.AdministrationElementsFeatureBuilderModule
          ),
      },
      {
        path: AdministrationRoutes.requests.path,
        component: DefaultLayoutComponent,
        loadChildren: () =>
          import('@fox/administration-data-feature-builder').then(
            (m) => m.AdministrationDataFeatureBuilderModule
          ),
      },
      {
        path: AdministrationRoutes.pages.path,
        component: DefaultLayoutComponent,
        loadChildren: () =>
          import('@fox/administration/pages/feature-builder').then(
            (m) => m.AdministrationPagesFeatureBuilderModule
          ),
      },
      {
        path: AdministrationRoutes.modules.path,
        component: DefaultLayoutComponent,
        loadChildren: () =>
          import('@fox/administration-modules-feature-builder').then(
            (m) => m.AdministrationModulesFeatureBuilderModule
          ),
      },
      {
        path: AdministrationRoutes.settings.path,
        component: DefaultLayoutComponent,
        loadChildren: () =>
          import('@fox/administration-settings-feature-back-layer').then(
            (m) => m.AdministrationSettingsFeatureBackLayerModule
          ),
      },
    ],
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {}
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CombinedRouterStateSerializer,
    }),
    EffectsModule.forRoot([]),
    RouterModule.forRoot(ROUTES, { paramsInheritanceStrategy: 'always' }),
    HttpClientModule,
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.apiUrl,
        })
    ),
    DefaultLayoutModule,
    SharedUiDialogModule,
    SharedUiMenuModule,
    SharedUiNotificationsModule,
    SharedDataAccessNotificationsModule,
    SharedFeaturePageExecutorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TenantIdInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
