import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Selector, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

export interface SelectorRouteData {
  [key: string]: Selector<unknown, unknown>;
}

@Injectable()
export class SelectorRouteResolver implements Resolve<unknown> {
  constructor(private store: Store) {}

  resolve(
    routeSnapshot: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<unknown> {
    if (
      !routeSnapshot.data.selectors ||
      !Object.keys(routeSnapshot.data.selectors).length
    ) {
      throw new Error('No selectors provided in route');
    }
    const { selectors } = routeSnapshot.data as {
      selectors: SelectorRouteData;
    };
    return combineLatest(
      Object.keys(selectors).map((key) =>
        this.store
          .select(selectors[key])
          .pipe(map((value) => ({ [key]: value })))
      )
    ).pipe(
      first(),
      map((resolvedValues) =>
        resolvedValues.reduce(
          (routeData, value) => ({
            ...routeData,
            ...value,
          }),
          {}
        )
      )
    );
  }
}
