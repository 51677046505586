import { CommonModule } from '@angular/common';
import {
  Directive,
  ElementRef,
  NgModule,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NgControl,
  Validators,
} from '@angular/forms';

import { isNil, isNotNil } from '@assecosolutions/fox-common-utils';
import { MissingDependencyError } from '@assecosolutions/ng-common';

import {
  ComboboxControlComponent,
  MultiselectComboboxControlComponent,
} from '../form-controls';
import { FoxValidators } from '../validators';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[formControlName]' })
export class RequiredDirective implements OnInit {
  constructor(
    private renderer: Renderer2,
    private ngControl: NgControl,
    private elRef: ElementRef,
    @Optional() private comboboxControl: ComboboxControlComponent,
    @Optional()
    private multiselectComboboxControl: MultiselectComboboxControlComponent
  ) {}

  ngOnInit(): void {
    const abstractControl = this.ngControl?.control;
    const isRequired =
      abstractControl?.hasValidator(Validators.required) ||
      abstractControl?.hasValidator(FoxValidators.notEmpty);

    if (isNil(abstractControl)) {
      throw new MissingDependencyError(
        RequiredDirective.name,
        AbstractControl.name
      );
    }

    isRequired ? this.setRequiredAttribute() : this.removeRequiredAttribute();
  }

  private setRequiredAttribute() {
    this.renderer.setAttribute(this.elRef.nativeElement, 'required', 'true');
    this.setRequiredOnCombobox(true);
    this.setRequiredOnMultiSelectCombobox(true);
  }

  private removeRequiredAttribute() {
    this.renderer.removeAttribute(this.elRef.nativeElement, 'required');
    this.setRequiredOnCombobox(false);
    this.setRequiredOnMultiSelectCombobox(false);
  }

  private setRequiredOnMultiSelectCombobox(required: boolean) {
    if (isNotNil(this.multiselectComboboxControl)) {
      this.multiselectComboboxControl.required = required;
    }
  }

  private setRequiredOnCombobox(required: boolean) {
    if (isNotNil(this.comboboxControl)) {
      this.comboboxControl.required = required;
    }
  }
}

@NgModule({
  declarations: [RequiredDirective],
  exports: [RequiredDirective],
  imports: [CommonModule, FormsModule],
})
export class RequiredDirectiveModule {}
