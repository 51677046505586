import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxFrontLayer.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxFrontLayer;
  }
}

/**
 *
 * FOX front-layer
 *
 * @element fox-front-layer
 *
 * @cssprop [--fox-front-layer-border-radius] - Border radius
 * @cssprop [--fox-front-layer-margin-top] - Margin of the Sidebar (used in old Design to move the Front-layer into the header)
 * @cssprop [--fox-front-layer-box-shadow] - Default Box Shadow
 * @cssprop [--fox-front-layer-background] - Default background
 * @cssprop [--fox-front-layer-close-transition] Default close transition
 * @cssprop [--fox-front-layer-open-transition] Default close transition
 * @cssprop [--fox-front-layer-width] - Default width
 * @cssprop [--fox-front-layer-preview-width] - Default width when preview mode
 * @cssprop [--fox-front-layer-large-width] - Default width when large mode
 *
 */
export class FoxFrontLayer extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: Boolean, reflect: true }) preview = false;
  @property({ type: Boolean, reflect: true }) large = false;

  render() {
    const classes = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fox-front-layer__preview': this.preview,
      'fox-front-layer__large': this.large,
    };
    return html`
      <div class="fox-front-layer ${classMap(classes)}">
        <div class="fox-front-layer__body">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

const name = 'fox-front-layer';

if (!customElements.get(name)) {
  customElements.define(name, FoxFrontLayer);
} else {
  console.warn(`${name} is already defined`);
}
