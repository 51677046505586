import { CustomItemEvent } from '@assecosolutions/fox-common-utils';

import { FoxCardList, NAME } from './FoxCardList';

export interface CardListItem<T = string> {
  icon: string;
  name: T;
  selected?: boolean;
}

export type CardSelectedEvent<T> = CustomItemEvent<T>;

declare global {
  interface HTMLElementTagNameMap {
    [NAME]: FoxCardList;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  interface HTMLElementEventMap {
    'card-selected': CardSelectedEvent<any>;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
}
