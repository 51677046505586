import { Action, createReducer, on } from '@ngrx/store';

import { PreviewConfiguration } from '@assecosolutions/fox-common-models';

import { closePreview, openPreview } from './preview.actions';

export const PREVIEW_FEATURE_KEY = 'preview';

export interface State {
  item: unknown;
  configuration: PreviewConfiguration | undefined;
  previewActive: boolean;
}

export const initialState: State = {
  item: null,
  configuration: undefined,
  previewActive: false,
};

const previewReducer = createReducer(
  initialState,
  on(openPreview, (state, { payload }) => ({
    ...state,
    previewActive: true,
    ...payload,
  })),
  on(closePreview, (state) => ({
    ...state,
    previewActive: false,
    item: null,
    configuration: undefined,
  }))
);

export const reducer = (state: State | undefined, action: Action) =>
  previewReducer(state, action);
