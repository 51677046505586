import { ConditionConfiguration } from './condition-configuration.model';

export interface FieldConfiguration {
  hidden?: boolean; //show-hide column
  header?: string; // label of the field
  path?: string; // path to data inside the object, can use dot notation user.name
  type?: string; //FieldTypes
  tooltip?: string; //text for tooltip
  width?: string; //width of the element / inner content
  textAlign?: 'start' | 'center' | 'end' | null; //Aligns the text content horizontally.

  //type specific
  prefix?: string;
  postfix?: string;
  decimals?: number;
  dateTimeFormat?: string;
  target?: string; // link target
  value?: string; // value of the link / static text to display
  icon?: string; //name of the Icon
  conditionsConfiguration?: ConditionConfiguration[]; //todo: refactor key to conditions or conditionConfiguration
}

export enum FieldTypes {
  TEXT = 'text',
  LINK = 'link',
  EMAIL = 'email',
  IMAGE = 'image',
  BADGE = 'badge',
  CHECKBOX = 'checkbox',
  ICON = 'icon',
  NUMBER = 'number',
  DATETIME = 'datetime',
}
