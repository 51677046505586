import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';

import {
  ElementAction,
  FieldConfiguration,
} from '@assecosolutions/fox-common-models';
import { DialogBaseComponent } from '@assecosolutions/ng-dialog';
import { FoxFormsModule } from '@fox/shared-ui-forms';
import { Parameter } from '@fox/shared/api-administration';
import {
  FormExecutorComponentModule,
  FormExecutorService,
} from '@fox/shared/feature-form-executor';

@Component({
  selector: 'fox-action-executor-dialog',
  template: `
    <fox-form-executor
      [configuration]="configuration"
      #formExecutor
    ></fox-form-executor>
    <ng-template #slotTemplate>
      <fox-button
        slot="secondaryAction"
        label="Close"
        dialogAction="close"
      ></fox-button>
      <fox-button
        label="Submit"
        slot="secondaryAction"
        primary
        raised
        [disabled]="!formExecutorService.formGroupValid"
        (click)="onDialogSaveClick()"
      ></fox-button>
    </ng-template>
  `,
})
export class ActionExecutorDialogComponent extends DialogBaseComponent {
  @Input()
  elementAction?: ElementAction;

  @Input()
  configuration: FieldConfiguration[] = [];

  @Output()
  save = new EventEmitter<Parameter[]>();

  constructor(public formExecutorService: FormExecutorService) {
    super();
  }

  onDialogSaveClick() {
    const parameter: Parameter[] = [];

    Object.entries(this.formExecutorService?.formGroup?.getRawValue()).map(
      ([key, value]) =>
        parameter.push({ key: key, value: value?.toString() || '' })
    );

    this.save.emit(parameter);
  }
}

@NgModule({
  imports: [CommonModule, FoxFormsModule, FormExecutorComponentModule],
  declarations: [ActionExecutorDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ActionExecutorDialogComponent],
})
export class ActionExecutorDialogComponentModule {}
