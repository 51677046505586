import { FoxList } from '@assecosolutions/fox-list';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxActivityList;
  }
}

export class FoxActivityList extends FoxList {}

const name = 'fox-activity-list';

if (!customElements.get(name)) {
  customElements.define(name, FoxActivityList);
} else {
  console.warn(`${name} is already defined`);
}
