import { html, LitElement, nothing, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import '@assecosolutions/fox-icon';
import '@assecosolutions/fox-icon-button';
import '@assecosolutions/fox-textfield';
import '@assecosolutions/fox-user-profile';

import { customItemEvent } from '@assecosolutions/fox-common-utils';
import { FoxTextField } from '@assecosolutions/fox-textfield';

import { styles } from './FoxAppBar.css';

/**
 * Fox-app-bar
 *
 * @element fox-app-bar
 *
 * @fires searchInputChange - Fired when user interacts with the search-textield
 * @fires logoClick - Fired when user interacts with the brand-logo, keep in mind that if you set brandUrl the page will route.
 * @fires profileClick - Fired when user interacts with the user profile.
 *
 * @slot brand - To Override brand container
 * @slot breadcrumb - For manual content placement, can be used for the page breadcrumb
 * @slot action - For manual placement of additional actions
 * @slot secondary-bar - second part of the app-bar (used in flow)
 * @slot profile - For manual placement at profile area
 * @slot profileMenu - For manual placement of menu list items
 *
 * @cssprop [--fox-app-bar-font-family=Nunito] - Font-family of non slotted items
 * @cssprop [--fox-app-bar-background=white] - Default app-bar background
 * @cssprop [--fox-app-bar-admin-background=#252836] - Admin app-bar background
 * @cssprop [--fox-app-bar-shadow="0px 3px 6px #dfe1e4"] - Default shadow
 * @cssprop [--fox-app-bar-height=62px] - Default height of the app-bar (set it in global context)
 * @cssprop [--fox-app-bar-brand-width=70px] - Default width of the brand element that includes the brand-logo
 * @cssprop [--fox-app-bar-box-shadow] - Default box-shadow
 * @cssprop [--fox-app-bar-border-color] - Default border-color of the header-bottom
 * @cssprop [--fox-app-bar-logo-width] - Default width of the logo
 * @cssprop [--fox-app-bar-title-color] - Default title color
 * @cssprop [--fox-app-bar-action-text-color] - Default action text color
 * @cssprop [--fox-app-bar-system-test-background] - Default system background for text env
 * @cssprop [--fox-app-bar-system-deploy-background] - Default system background for deploy env
 * @cssprop [--fox-app-bar-client-color] - Default client system background color (can also be set with client color property)
 * @cssprop [--fox-app-bar-mobile-height] - Default app-bar height for mobile version
 * @cssprop [--fox-app-bar-mobile-logo-width] - Default logo width for mobile version
 *
 */
export class FoxAppBar extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  /** Branding Company name of the product */
  @property({ type: String }) brandName = 'AP+';
  /** Branding Logo of the product, has to be an SVG */
  @property({ type: SVGElement }) brandLogo = html`
    <svg
      width="84"
      height="42"
      viewBox="0 0 88 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.4744 7.67578H6.6996C2.95586 7.67965 0.44458 10.1832 0.44458 13.9366V22.699C0.44458 26.4544 2.95586 28.9541 6.6996 28.9502H21.719C22.9708 28.9502 24.2303 27.7042 24.2303 26.4544V22.0577C24.2303 21.0218 23.3905 20.182 22.3546 20.182C21.3186 20.182 20.4788 21.0218 20.4788 22.0577V25.2007H6.69959C5.44781 25.2007 4.1883 23.9489 4.1883 22.6894V13.9366C4.1883 12.6848 5.44008 11.4369 6.69959 11.4253H25.4743C26.728 11.4253 27.9856 12.6732 27.9856 13.925V27.061C27.9856 28.0969 28.8254 28.9367 29.8614 28.9367C30.8973 28.9367 31.7371 28.0969 31.7371 27.061V13.925C31.739 10.1832 29.2278 7.67965 25.4744 7.67578Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85.8317 7.68214H79.7099V1.56427C79.6675 0.688354 78.9449 0 78.068 0C77.191 0 76.4685 0.688338 76.4259 1.56427V7.68214H70.3081C69.7025 7.65275 69.1299 7.95913 68.8182 8.47928C68.5066 8.99941 68.5066 9.64887 68.8182 10.169C69.1299 10.6891 69.7025 10.9955 70.3081 10.9661H76.424V17.084C76.4665 17.9599 77.189 18.6483 78.066 18.6483C78.943 18.6483 79.6655 17.9599 79.708 17.084V10.9681H85.8317C86.4373 10.9975 87.0099 10.6911 87.3216 10.171C87.6332 9.65083 87.6332 9.00137 87.3216 8.48124C87.0099 7.9611 86.4373 7.65472 85.8317 7.68411V7.68214Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.5224 7.68164H41.7476C37.9903 7.68164 35.4887 10.1929 35.4887 13.9425V39.3142C35.4887 40.3501 36.3285 41.1899 37.3645 41.1899C38.4004 41.1899 39.2402 40.3501 39.2402 39.3142V13.9386C39.2402 12.6868 40.492 11.4389 41.7515 11.4389H60.5263C61.7761 11.4389 63.0375 12.6926 63.0375 13.9502V22.7127C63.0375 23.9644 61.7857 25.224 60.5263 25.224H44.9466C43.9336 25.2565 43.1291 26.0871 43.1291 27.1007C43.1291 28.1142 43.9336 28.9448 44.9466 28.9774H60.5224C64.2738 28.9774 66.7774 26.4815 66.7774 22.7262V13.9386C66.7813 10.1852 64.2777 7.68167 60.5224 7.68167L60.5224 7.68164Z"
        fill="white"
      />
    </svg>
  `;

  /** Target URL for route when Logo is Clicked */
  @property({ type: String }) brandUrl = '';
  /** Name of the active system */
  @property({ type: String }) system?: 'test' | 'deploy' | 'prod';
  /** Name of the current product area */
  @property({ type: String }) moduleName = '';
  /** Display the app-bar in dark-mode */
  @property({ type: Boolean, reflect: true }) dark = false;
  /** Name of the current user */
  @property({ type: String }) profileName = '';
  /** Name of the current client */
  @property({ type: String }) profileClient = '';
  /** Color of the current client */ //TODO: use color name in the future when new design system
  @property({ type: String }) clientColor = '';
  /** Path to user image, if empty the first letters of the Name are Displayed here */
  @property({ type: String }) profileImage = '';
  /** If true, the search icon - bar will be rendered */
  @property({ type: Boolean }) showSearchBar = false;
  /** If showSearchBar is true, and this propierty will be set, the textfield will always be visible */
  @property({ type: Boolean }) showSearchTextfield = false;
  /** If true, all non slotted actions + profile will be shrunk to fit the remaining space */
  @property({ type: Boolean }) previewActive = false;
  /** If true, all non slotted content will be shrunk*/
  @property({ type: Boolean }) small = false;

  render() {
    const classes = {
      'fox-app-bar__small': this.small,
      'fox-app-bar__preview-active': this.previewActive,
      'fox-app-bar__system-test': this.system === 'test',
      'fox-app-bar__system-deploy': this.system === 'deploy',
      'fox-app-bar__client-color': this.clientColor,
    };

    return html`
      <div
        class="fox-app-bar ${classMap(classes)}"
        style="--fox-app-bar-client-color: ${this.clientColor}"
      >
        <div class="fox-app-bar__brand">${this.renderBrandLogo()}</div>
        <slot name="brand">
          <div class="fox-app-bar__brand-title">
            <div class="title">${this.brandName}</div>
            <div class="module">${this.moduleName}</div>
          </div>
        </slot>
        <slot name="breadcrumb"></slot>

        <div class="fox-app-bar__action-bar">
          <slot name="action"></slot>
          ${this.showSearchBar ? this.renderSearch() : nothing}
          <slot name="profile"> ${this.renderProfile()}</slot>
        </div>
      </div>
      <slot name="secondary-bar"></slot>
    `;
  }

  protected renderBrandLogo(): TemplateResult {
    return this.brandUrl
      ? html`<a
          class="fox-app-bar__brand-logo"
          href="${this.brandUrl}"
          @click="${this.onLogoClick}"
        >
          ${this.brandLogo}
        </a>`
      : html`<span class="fox-app-bar__brand-logo" @click="${this.onLogoClick}">
          ${this.brandLogo}
        </span>`;
  }

  protected renderProfile(): TemplateResult | void {
    if (!this.profileName && !this.profileImage) {
      return;
    }
    return html` <fox-user-profile
      @profile-click="${() => this.onProfileClick()}"
      profileName="${this.profileName}"
      profileClient="${this.profileClient}"
      profileImage="${this.profileImage}"
      ?small="${this.small || this.previewActive}"
    >
      <slot name="profileMenu"></slot>
    </fox-user-profile>`;
  }

  protected renderSearch(): TemplateResult | void {
    return html`
      ${this.showSearchTextfield
        ? html` <fox-textfield
            @input="${this.onSearchInputChange}"
            placeholder="Search"
          ></fox-textfield>`
        : nothing}

      <fox-icon-button
        icon="search"
        @click="${this.onToggleSearchBar}"
      ></fox-icon-button>
    `;
  }

  protected onSearchInputChange(e: { target: FoxTextField }) {
    this.dispatchEvent(customItemEvent('searchInputChange', e.target.value));
  }

  protected onToggleSearchBar() {
    this.showSearchTextfield = !this.showSearchTextfield;
  }

  protected onLogoClick() {
    this.dispatchEvent(new CustomEvent('logoClick'));
  }

  protected onProfileClick() {
    return this.dispatchEvent(new CustomEvent('profileClick'));
  }
}

export const NAME = 'fox-app-bar';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxAppBar);
} else {
  console.warn(`${NAME} is already defined`);
}
