import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { cloneDeep } from 'lodash-es';

import { PreviewFacade } from '@assecosolutions/ng-element-executor';
import { SharedFeatureReleaseProcessModule } from '@fox/administration/shared/feature-release-process';
import { TextfieldControlDirectiveModule } from '@fox/shared-ui-forms';
import { SharedFoxPreviewModule } from '@fox/shared-ui-preview';
import {
  DragGridBuilderComponentModule,
  PageElementComponentModule,
} from '@fox/shared/feature-drag-grid-builder';
import { DashboardConfiguration, PageConfiguration } from '@fox/shared/models';

@Component({
  selector: 'fox-dashboard-executor',
  templateUrl: './dashboard-executor.component.html',
  styleUrls: ['./dashboard-executor.component.scss'],
})
export class DashboardExecutorComponent {
  @Input()
  set page(page: PageConfiguration<DashboardConfiguration>) {
    if (page?.detailConfiguration) {
      this.pageConfiguration = page;
      this.configuration = cloneDeep(page.detailConfiguration);
    }
  }

  configuration!: DashboardConfiguration;

  pageConfiguration!: PageConfiguration<DashboardConfiguration>;

  constructor(public previewFacade: PreviewFacade) {}

  onClosePreview() {
    this.previewFacade.closePreview();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    TextfieldControlDirectiveModule,
    SharedFeatureReleaseProcessModule,
    SharedFoxPreviewModule,
    PageElementComponentModule,
    DragGridBuilderComponentModule,
  ],
  declarations: [DashboardExecutorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [DashboardExecutorComponent],
})
export class DashboardExecutorComponentModule {}
