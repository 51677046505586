import { NgModule } from '@angular/core';

import { MenuComponentModule } from './menu.component';
import { MenuService } from './menu.service';

@NgModule({
  imports: [MenuComponentModule],
  exports: [MenuComponentModule],
  providers: [MenuService],
})
export class SharedUiMenuModule {}
