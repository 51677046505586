import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import {
  ElementAction,
  ElementActionTriggerEvent,
} from '@assecosolutions/fox-common-models';
import { isNotEmpty, isNotNil } from '@assecosolutions/fox-common-utils';
import { MenuService } from '@assecosolutions/ng-menu';
import { ElementConfiguration } from '@fox/shared/models';
import { AutoUnsubscribe } from '@fox/shared/util-rxjs';

import { ActionExecutorMenuComponent } from './action-executor/action-executor-menu.component';
import { ActionExecutorService } from './action-executor/action-executor.service';
import { filterElementActionsByEvent } from './element-executor.utils';

@Component({ template: '' })
export class ElementExecutorBaseComponent
  extends AutoUnsubscribe
  implements OnDestroy
{
  @Output()
  updatedElementConfiguration = new EventEmitter<ElementConfiguration>();

  constructor(
    protected actionExecutorService: ActionExecutorService,
    protected menuService: MenuService
  ) {
    super();
  }

  protected triggerAction(
    event: ElementActionTriggerEvent,
    configuration: ElementConfiguration
  ) {
    if (configuration?.actions) {
      const filteredActions = filterElementActionsByEvent(event, configuration);

      if (isNotEmpty(filteredActions)) {
        // if multiple events of the same type exist, display menu at click position.
        if (filteredActions.length > 1 && isNotNil(event.mouseEvent)) {
          const x = event.mouseEvent.x / 2;
          const y = event.mouseEvent.y / 2;
          this.openElementMenu(x, y, filteredActions);
        } else {
          this.actionExecutorService.executeAction(
            filteredActions[0],
            event.selectedItem
          );
        }
      }
    }
  }

  protected openElementMenu(
    x: number,
    y: number,
    elementActions?: ElementAction[]
  ) {
    const options = { x, y, inputs: { elementActions } };
    const ref = this.menuService.openMenuWithComponent(
      ActionExecutorMenuComponent,
      options
    );

    this.subscribeTo(ref.instance.triggerClick, (elementAction) => {
      this.actionExecutorService.executeAction(elementAction);
      this.menuService.close();
    });
  }
}
