import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxIndicator.css';
import {
  FoxIndicatorTemplateEnum,
  FoxIndicatorThemeEnum,
} from './FoxIndicator.model';

/**
 *
 * FOX Indicator
 *
 * @element fox-button
 *
 * @slot action - Optional slot for custom action. will be placed at the upper right
 *
 */

export class FoxIndicator extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];
  @property({ type: String }) template: FoxIndicatorTemplateEnum = 'default';
  @property({ type: String }) theme: FoxIndicatorThemeEnum = 'default';
  @property({ type: Boolean }) compact = false;
  @property({ type: Boolean }) selectable = false;
  @property({ type: Boolean }) showPlaceholder = false;
  @property({ type: String }) heading = '';
  @property({ type: String }) icon = '';
  @property({ type: String }) primaryText = '';
  @property({ type: String }) secondaryText = '';

  render() {
    const classes = {
      ['fox-indicator__theme-' + this.theme]: this.theme,
      'fox-indicator__compact': this.compact,
      'fox-indicator__selectable': this.selectable,
      'fox-indicator__has-icon': this.icon,
      'fox-indicator__show-placeholder': this.showPlaceholder,
    };

    if (this.showPlaceholder) {
      this.heading = this.heading ?? 'placeholder';
      this.primaryText = this.primaryText || 'primaryText';
      this.secondaryText = this.secondaryText || 'secondaryText';
      this.icon = this.icon || 'info';
    }

    return html`
      <div class="fox-indicator ${classMap(classes)}">
        <div class="fox-indicator__header">
          <p class="fox-indicator__header-heading" title="${this.heading}">
            ${this.heading}
          </p>
          <div class="fox-indicator__header-link">
            <slot name="action"></slot>
          </div>
        </div>
        <div class="fox-indicator__body">
          ${this.renderIcon()}
          <div>${this.renderPrimaryText()} ${this.renderSecondaryText()}</div>
        </div>
      </div>
    `;
  }

  renderIcon() {
    return this.icon
      ? html`
          <div class="fox-indicator__body-icon">
            <fox-icon icon="${this.icon}"></fox-icon>
            <div class="fox-indicator__body-icon-divider"></div>
          </div>
        `
      : nothing;
  }
  renderSecondaryText() {
    return this.secondaryText
      ? html`<p
          class="fox-indicator__body-secondary-text"
          title="${this.secondaryText}"
        >
          ${this.secondaryText}
        </p>`
      : nothing;
  }

  renderPrimaryText() {
    return this.primaryText
      ? html`<div
          class="fox-indicator__body-primary-text"
          title="${this.primaryText}"
        >
          ${this.primaryText}
        </div>`
      : nothing;
  }
}
export const NAME = 'fox-indicator';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxIndicator);
} else {
  console.warn(`${NAME} is already defined`);
}
