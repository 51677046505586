/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/directive-selector,@angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Directive,
  ElementRef,
  forwardRef,
  Host,
  NgModule,
  Optional,
  Renderer2,
  StaticProvider,
} from '@angular/core';
import {
  FormsModule,
  NgSelectOption,
  NG_VALUE_ACCESSOR,
  SelectControlValueAccessor,
} from '@angular/forms';

export const SELECT_VALUE_ACCESSOR: StaticProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FoxSelectControlValueAccessor),
  multi: true,
};

@Directive({
  selector: 'fox-select',
  host: {
    '(action)': 'onChange($event.target.value)',
    '(blur)': 'onTouched()',
  },
  providers: [SELECT_VALUE_ACCESSOR],
})
export class FoxSelectControlValueAccessor extends SelectControlValueAccessor {}

@Directive({ selector: 'fox-list-item' })
export class FoxSelectOption extends NgSelectOption {
  constructor(
    _element: ElementRef,
    _renderer: Renderer2,
    @Optional() @Host() _select: FoxSelectControlValueAccessor
  ) {
    super(_element, _renderer, _select);
  }
}

@NgModule({
  declarations: [FoxSelectControlValueAccessor, FoxSelectOption],
  exports: [FoxSelectControlValueAccessor, FoxSelectOption],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelectControlDirectiveModule {}
