import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import '@assecosolutions/fox-icon';
import { styles } from './FoxNotification.css';
import { FoxNotificationTypes } from './FoxNotification.model';

export class FoxNotification extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: String }) type = FoxNotificationTypes.INFO;
  @property({ type: String }) header = '';
  @property({ type: String }) icon = '';
  @property({ type: String }) description = '';

  render() {
    const classes = {
      ['fox-notification__type-' + this.type]: this.type,
    };
    return html`
      <div class="fox-notification ${classMap(classes)}">
        <slot name="body">
          ${this.icon
            ? html` <div class="fox-notification__icon">
                <fox-icon icon="${this.icon}"></fox-icon>
              </div>`
            : nothing}

          <div class="fox-notification__body">
            <div class="fox-notification__header">${this.header}</div>
            <div class="fox-notification__description">${this.description}</div>
          </div>
        </slot>
      </div>
    `;
  }
}

export const FOX_NOTIFICATION = 'fox-notification';

if (!customElements.get(FOX_NOTIFICATION)) {
  customElements.define(FOX_NOTIFICATION, FoxNotification);
} else {
  console.warn(`${FOX_NOTIFICATION} is already defined`);
}
