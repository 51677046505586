import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FoxNotificationPayload } from '@fox/shared-util-ngrx';
import { FoxNotification } from '@fox/shared/models';

import { addNotification } from './notifications.actions';
import * as fromNotifications from './notifications.reducer';
import * as NotificationsSelectors from './notifications.selectors';
import * as notificationUtil from './notifications.util';

@Injectable()
export class NotificationsFacade {
  allNotifications$: Observable<FoxNotification[]> = this.store.pipe(
    select(NotificationsSelectors.selectAllNotifications)
  );

  constructor(
    private store: Store<fromNotifications.NotificationsPartialState>
  ) {}

  addNotification(notification: FoxNotificationPayload) {
    const payload = notificationUtil.createNotification(notification);
    this.store.dispatch(addNotification({ payload }));
  }
}
