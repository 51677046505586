export * from './admin.service';
import { AdminService } from './admin.service';
export * from './categories.service';
import { CategoriesService } from './categories.service';
export * from './config.service';
import { ConfigService } from './config.service';
export * from './dataSources.service';
import { DataSourcesService } from './dataSources.service';
export * from './elements.service';
import { ElementsService } from './elements.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './modules.service';
import { ModulesService } from './modules.service';
export * from './pages.service';
import { PagesService } from './pages.service';
export * from './requests.service';
import { RequestsService } from './requests.service';
export * from './schemas.service';
import { SchemasService } from './schemas.service';
export const APIS = [AdminService, CategoriesService, ConfigService, DataSourcesService, ElementsService, HealthService, ModulesService, PagesService, RequestsService, SchemasService];
