import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export abstract class AutoUnsubscribe implements OnDestroy {
  protected subscription = new Subscription();

  unsubscribeFrom(subscription: Subscription) {
    this.subscription.add(subscription);
  }

  subscribeTo<T>(obs: Observable<T>, observer?: (value: T) => void) {
    this.unsubscribeFrom(obs.subscribe(observer));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
