export const emptyValueChange = <T>(): {
  previousValue: T | null;
  currentValue: T | null;
  firstChange: boolean;
  isFirstChange(): boolean;
} => {
  return {
    previousValue: null,
    currentValue: null,
    firstChange: true,
    isFirstChange: () => true,
  };
};
