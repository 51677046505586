import { styles as controlStyle } from '@material/mwc-list/mwc-control-list-item.css';
import { styles as mwcStyles } from '@material/mwc-list/mwc-list-item.css';
import { RadioListItemBase } from '@material/mwc-list/mwc-radio-list-item-base';
import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';

import { styles } from './FoxListItem.css';

export {
  GraphicType,
  RequestSelectedDetail,
} from '@material/mwc-list/mwc-list-item-base';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxRadioListItem;
  }
}

export class FoxRadioListItem extends RadioListItemBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, controlStyle, styles];

  render() {
    const radioClasses = {
      'mdc-deprecated-list-item__graphic': this.left,
      'mdc-deprecated-list-item__meta': !this.left,
    };

    const text = this.renderText();
    const graphic =
      this.graphic && this.graphic !== 'control' && !this.left
        ? this.renderGraphic()
        : html``;
    const meta = this.hasMeta && this.left ? this.renderMeta() : html``;
    const ripple = this.renderRipple();

    return html` ${ripple} ${graphic} ${this.left ? '' : text}
      <fox-radio
        global
        class=${classMap(radioClasses)}
        tabindex=${this.tabindex}
        name=${ifDefined(this.group === null ? undefined : this.group)}
        .checked=${this.selected}
        ?disabled=${this.disabled}
        @checked=${this.onChange}
      >
      </fox-radio>
      ${this.left ? text : ''} ${meta}`;
  }
}

const name = 'fox-radio-list-item';

if (!customElements.get(name)) {
  customElements.define(name, FoxRadioListItem);
} else {
  console.warn(`${name} is already defined`);
}
