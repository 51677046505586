import { IconButtonToggleBase } from '@material/mwc-icon-button-toggle/mwc-icon-button-toggle-base';
import { styles as mwcStyles } from '@material/mwc-icon-button/mwc-icon-button.css';

import { styles } from './FoxIconButtonToggle.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxIconButtonToggle;
  }
}

/**
 *
 * Use any icon listed in https://fonts.google.com/icons?selected=Material+Icons and place the name inside the icon attribute
 *
 * @element fox-icon-button-toggle
 *
 * @fires [icon-button-toggle-change] - Indicates the button has been toggled. isOn indicates the on value of the toggle button.
 *
 */
export class FoxIconButtonToggle extends IconButtonToggleBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-icon-button-toggle';

if (!customElements.get(name)) {
  customElements.define(name, FoxIconButtonToggle);
} else {
  console.warn(`${name} is already defined`);
}
