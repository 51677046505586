<ng-container *ngIf="loading; else elementRenderer">
  <div class="loading">
    <fox-progress type="circular" indeterminate density="4"></fox-progress>
    Loading...
  </div>
</ng-container>
<ng-template #elementRenderer>
  <ng-container
    *ngIf="store.noDetailConfiguration$ | async; else renderElement"
  >
    <fox-card class="text-center p-5">
      <fox-icon class="h1 d-block">error</fox-icon>
      No configuration or data provided...
    </fox-card>
  </ng-container>

  <ng-template [ngSwitch]="store.elementType$ | async" #renderElement>
    <fox-element-executor-table
      *ngSwitchCase="'table'"
      [elementConfiguration]="store.tableConfiguration$ | async"
      [data]="store.data$ | async"
      [options]="store.options$ | async"
      (updatedElementConfiguration)="onUpdatedElementConfiguration($event)"
    ></fox-element-executor-table>
    <fox-element-executor-indicator
      *ngSwitchCase="'indicator'"
      [elementConfiguration]="store.indicatorConfiguration$ | async"
      [data]="store.data$ | async"
      [options]="store.options$ | async"
      (updatedElementConfiguration)="onUpdatedElementConfiguration($event)"
    ></fox-element-executor-indicator>
    <fox-element-executor-chart
      *ngSwitchCase="'chart'"
      [elementConfiguration]="store.chartConfiguration$ | async"
      [data]="store.data$ | async"
      (updatedElementConfiguration)="onUpdatedElementConfiguration($event)"
    ></fox-element-executor-chart>
    <fox-element-executor-kanban
      *ngSwitchCase="'kanban'"
      [options]="store.options$ | async"
      [elementConfiguration]="store.kanbanConfiguration$ | async"
      [data]="store.data$ | async"
    ></fox-element-executor-kanban>

    <div *ngSwitchDefault>
      <h2>Ups...</h2>
      <p>This type is not implemented right now :( 🦊</p>
      <p>We will implement generic elements soon</p>
    </div>
  </ng-template>
</ng-template>
