import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { PreviewConfiguration } from '@assecosolutions/fox-common-models';
import { UnknownRecord } from '@assecosolutions/fox-common-utils';

@Component({
  selector: 'fox-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewComponent {
  @Input()
  selectedItem: UnknownRecord | unknown = {};

  @Input()
  configuration?: PreviewConfiguration;

  @Output()
  closePreview = new EventEmitter<void>();

  onClose(): void {
    this.closePreview.emit();
  }
}
