import { ListBase } from '@material/mwc-list/mwc-list-base';
import { styles as mwcStyles } from '@material/mwc-list/mwc-list.css';

import { styles } from './FoxList.css';

export {
  ActionDetail,
  createSetFromIndex,
  IndexDiff,
  isEventMulti,
  isIndexSet,
  MWCListIndex,
  SelectedDetail,
} from '@material/mwc-list/mwc-list-foundation';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxList;
  }
}

export class FoxList extends ListBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-list';

if (!customElements.get(name)) {
  customElements.define(name, FoxList);
} else {
  console.warn(`${name} is already defined`);
}
