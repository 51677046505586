/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Version } from './version';
import { Dependency } from './dependency';


export interface FoxConfiguration { 
    configurationId: string;
    version: Version;
    state: FoxConfiguration.StateEnum;
    dependencies?: Array<Dependency>;
    resolvedDependencies?: Array<FoxConfiguration>;
}
export namespace FoxConfiguration {
    export type StateEnum = 'IN_DEVELOPMENT' | 'IN_REVIEW' | 'RELEASED' | 'RETRACTED';
    export const StateEnum = {
        InDevelopment: 'IN_DEVELOPMENT' as StateEnum,
        InReview: 'IN_REVIEW' as StateEnum,
        Released: 'RELEASED' as StateEnum,
        Retracted: 'RETRACTED' as StateEnum
    };
}


