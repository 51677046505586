/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DependencyRequest } from './dependencyRequest';
import { VersionRequest } from './versionRequest';


export interface CreateModuleVersionRequest { 
    configurationId: string;
    version: VersionRequest;
    state: CreateModuleVersionRequest.StateEnum;
    dependencies?: Array<DependencyRequest>;
    name: string;
    description: string;
    path: string;
    icon: string;
    isSPA: boolean;
}
export namespace CreateModuleVersionRequest {
    export type StateEnum = 'IN_DEVELOPMENT' | 'IN_REVIEW' | 'RELEASED' | 'RETRACTED';
    export const StateEnum = {
        InDevelopment: 'IN_DEVELOPMENT' as StateEnum,
        InReview: 'IN_REVIEW' as StateEnum,
        Released: 'RELEASED' as StateEnum,
        Retracted: 'RETRACTED' as StateEnum
    };
}


