import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';

import { SharedFeatureElementExecutorModule } from '@assecosolutions/ng-element-executor';
import { ElementConfiguration } from '@fox/shared/models';

@Component({
  selector: 'fox-element-loader',
  templateUrl: './element-loader.component.html',
  styleUrls: ['./element-loader.component.scss'],
})
export class ElementLoaderComponent {
  @Input()
  element!: ElementConfiguration;

  @Input()
  editable!: boolean;

  @Output()
  deleteElement = new EventEmitter<ElementConfiguration>();

  onDelete() {
    this.deleteElement.emit(this.element);
  }
}

@NgModule({
  imports: [CommonModule, DragDropModule, SharedFeatureElementExecutorModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ElementLoaderComponent],
  exports: [ElementLoaderComponent],
})
export class ElementLoaderComponentModule {}
