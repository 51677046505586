import { html, LitElement } from 'lit';

import { ConditionSet } from '@assecosolutions/fox-common-models';
import { customItemEvent } from '@assecosolutions/fox-common-utils';

import { styles } from './FoxConditionBuilder.css';
import * as helper from './FoxConditionBuilder.helper';
import { Field, Option } from './FoxConditionBuilder.model';
import { ConditionSetValueChanged } from './FoxConditionBuilderConditionSet/FoxConditionBuilderConditionSet.model';

export class FoxConditionBuilder extends LitElement {
  static styles = [styles];

  static properties = {
    configuration: {},
    fields: { type: Array },
    operators: { type: Array },
    options: { type: Array },
  };

  configuration: ConditionSet | undefined;

  fields: Field[] = [];

  operators: { [key: string]: string[] } = helper.OPERATORS;

  /**
   * default options for common types
   * if the selectedField has own options this will be ignored.
   * */
  options: { [key: string]: Option[] } = helper.OPTIONS;

  render() {
    if (!this.configuration) {
      this.configuration = helper.emptyConditionSet();
    }

    return html`
      <fox-condition-builder-condition-set
        @form-set-value-changed="${(e: ConditionSetValueChanged) =>
          this.onFormValueSetChanged(e)}"
        .fields="${this.fields}"
        .operators="${this.operators}"
        .formValueSet="${this.configuration}"
        .options="${this.options}"
        first
      ></fox-condition-builder-condition-set>
    `;
  }

  private onFormValueSetChanged(e: ConditionSetValueChanged) {
    if (this.configuration === e.detail.old) {
      this.configuration = e.detail.new;
    } else {
      this.setNewValue(this.configuration, e.detail.old, e.detail.new);
    }

    if (this.configuration) {
      this.dispatchEvent(
        customItemEvent<ConditionSet>(
          'configuration-changed',
          this.configuration
        )
      );
    }
  }

  private setNewValue(
    values: ConditionSet | undefined,
    oldValue: ConditionSet,
    newValue: ConditionSet
  ) {
    return values
      ? Object.values(values).map((value) => {
          if (value) {
            return value === oldValue ? newValue : value;
          }
        })
      : undefined;
  }
}

export const NAME = 'fox-condition-builder';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxConditionBuilder);
} else {
  console.warn(`${NAME} is already defined`);
}
