/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DependencyRequest } from './dependencyRequest';


export interface CreatePageRequest { 
    dependencies?: Array<DependencyRequest>;
    name: string;
    description?: string;
    type: CreatePageRequest.TypeEnum;
    detailConfiguration?: object;
}
export namespace CreatePageRequest {
    export type TypeEnum = 'dashboard' | 'spa';
    export const TypeEnum = {
        Dashboard: 'dashboard' as TypeEnum,
        Spa: 'spa' as TypeEnum
    };
}


