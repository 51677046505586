import { styles as mwcStyles } from '@material/mwc-dialog/mwc-dialog.css';
import { html, nothing, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxDialog.css';
import { DialogType } from './FoxDialog.model';
import { DialogBase } from './FoxDialogBase';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxDialog;
  }
}

/**
 *
 * FOX Dialog
 *
 * @element fox-dialog
 *
 * for full Docs visit the [docs tab](/?path=/docs/atoms-fox-dialog-docs--page)
 *
 * @cssprop [--fox-dialog-content-spacing] - content spacing
 * */

export class FoxDialog extends DialogBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];

  @property() type: DialogType = 'default';

  protected override render() {
    const classes = {
      'mdc-dialog-default': this.type === 'default',
      'mdc-dialog-sidebar-right': this.type === 'sidebar-right',
      'mdc-dialog-large': this.type === 'large',
      'mdc-dialog-medium': this.type === 'medium',
      'mdc-dialog-bottom': this.type === 'bottom',
    };

    let renderTemplate = this.renderDefaultDialog();

    switch (this.type) {
      case 'large':
        renderTemplate = this.renderLargeDialog();
        break;
      case 'sidebar-right':
        renderTemplate = this.renderSidebarRightDialog();
        break;
      case 'bottom':
        renderTemplate = this.renderBottomDialog();
        break;
    }

    return html` <div
      class="mdc-dialog ${classMap(classes)}"
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="title"
      aria-describedby="content"
    >
      ${renderTemplate}
      <div class="mdc-dialog__scrim"></div>
    </div>`;
  }

  renderDefaultDialog(): TemplateResult<1> {
    return html`
      <div class="mdc-dialog__container">
        <div class="mdc-dialog__surface">
          <slot name="header">
            ${this.heading ? this.renderHeading() : nothing}
          </slot>
          ${this.renderContent()} ${this.renderFooter()}
        </div>
      </div>
    `;
  }

  renderLargeDialog(): TemplateResult<1> {
    return html`
      <div class="mdc-dialog__container">
        <div class="mdc-dialog__surface">
          <slot name="header">
            <div class="mdc-dialog__header-actions">
              <slot name="leftAction"></slot>
              <div class="right">
                <slot name="rightAction"></slot>
              </div>
            </div>
            ${this.heading ? this.renderHeading() : nothing}
          </slot>

          ${this.renderContent()} ${this.renderFooter()}
        </div>
      </div>
    `;
  }

  renderSidebarRightDialog(): TemplateResult<1> {
    return html`
      <div class="mdc-dialog__container">
        <div class="mdc-dialog__surface">
          <slot name="header">
            <div class="mdc-dialog__header">
              <slot name="leftAction"></slot>
              ${this.heading ? this.renderHeading() : nothing}
              <div class="right">
                <slot name="rightAction"></slot>
              </div>
            </div>
          </slot>
          ${this.renderContent()} ${this.renderFooter()}
        </div>
      </div>
    `;
  }

  renderBottomDialog(): TemplateResult<1> {
    return html`
      <div class="mdc-dialog__container">
        <div class="mdc-dialog__surface">${this.renderContent()}</div>
      </div>
    `;
  }

  protected renderFooter(): TemplateResult<1> {
    return html`
      <footer
        id="actions"
        class="${classMap({
          'mdc-dialog__actions': !this.hideActions,
        })}"
      >
        <span>
          <slot name="secondaryAction"></slot>
        </span>
        <span>
          <slot name="primaryAction"></slot>
        </span>
      </footer>
    `;
  }
  protected renderContent(): TemplateResult<1> {
    return html`
      <div id="content" class="mdc-dialog__content">
        <slot id="contentSlot"></slot>
        <slot></slot>
      </div>
      <slot name="body"></slot>
    `;
  }

  protected renderHeading(): TemplateResult<1> {
    return html` <h2 id="title" class="mdc-dialog__title">${this.heading}</h2>`;
  }
}

const name = 'fox-dialog';

if (!customElements.get(name)) {
  customElements.define(name, FoxDialog);
} else {
  console.warn(`${name} is already defined`);
}
