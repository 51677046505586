import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface CombinedRouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export class CombinedRouterStateSerializer extends RouterStateSerializer<CombinedRouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): CombinedRouterStateUrl {
    let route = routerState.root;
    let data: Data = route.data;
    let params: Params = route.params;
    while (route.firstChild) {
      route = route.firstChild;
      data = {
        ...data,
        ...route.data,
      };
      params = {
        ...params,
        ...route.params,
      };
    }
    const {
      url,
      root: { queryParams },
    } = routerState;
    return { url, params, queryParams, data };
  }
}
