// FIXME type
//
/* eslint-disable @typescript-eslint/no-explicit-any */
export const enrichElementByValuePath = (element: any, item: any) => {
  const valuePathElements = (element as Element)?.querySelectorAll(
    '[value-path]'
  );
  element.slot = '';

  if (item && valuePathElements) {
    valuePathElements.forEach((vpElement: any) => {
      if (vpElement?.tagName?.toLowerCase() === 'img') {
        vpElement.src = item[vpElement.getAttribute('value-path')];
      } else {
        const innerElement = document.createElement('span');
        innerElement.innerText =
          item[vpElement.getAttribute('value-path')] || '';
        vpElement.prepend(innerElement);
      }
    });
  }

  return element;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
