import { SelectBase } from '@material/mwc-select/mwc-select-base';
import { styles as mwcStyles } from '@material/mwc-select/mwc-select.css';
import '@assecosolutions/fox-list';
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';

import { styles } from './FoxSelect.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxSelect;
  }
}
export class FoxSelect extends SelectBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
  @property({ type: String }) name = '';

  render() {
    const classes = {
      'mdc-select--disabled': this.disabled,
      'mdc-select--no-label': !this.label,
      'mdc-select--filled': !this.outlined,
      'mdc-select--outlined': this.outlined,
      'mdc-select--with-leading-icon': !!this.icon,
      'mdc-select--required': this.required,
      'mdc-select--invalid': !this.isUiValid,
    };

    const menuClasses = {
      'mdc-select__menu--invalid': !this.isUiValid,
    };

    const labelledby = this.label ? 'label' : undefined;
    const describedby = this.shouldRenderHelperText ? 'helper-text' : undefined;

    return html` <div class="mdc-select ${classMap(classes)}">
        <input
          class="formElement"
          name="${this.name}"
          .value="${this.value}"
          hidden
          ?disabled="${this.disabled}"
          ?required=${this.required}
        />
        <div
          class="mdc-select__anchor"
          aria-autocomplete="none"
          role="combobox"
          aria-expanded=${this.menuOpen}
          aria-invalid=${!this.isUiValid}
          aria-haspopup="listbox"
          aria-labelledby=${ifDefined(labelledby)}
          aria-required=${this.required}
          aria-describedby=${ifDefined(describedby)}
          @click=${this.onClick}
          @focus=${this.onFocus}
          @blur=${this.onBlur}
          @keydown=${this.onKeydown}
        >
          ${this.renderRipple()}
          ${this.outlined ? this.renderOutline() : this.renderLabel()}
          ${this.renderLeadingIcon()}

          <span class="mdc-select__selected-text-container">
            <span class="mdc-select__selected-text">${this.selectedText}</span>
          </span>

          <fox-icon class="mdc-select__dropdown-icon">arrow_drop_down</fox-icon>

          ${this.renderLineRipple()}
        </div>
        <fox-menu
          innerRole="listbox"
          wrapFocus
          class="mdc-select__menu mdc-menu mdc-menu-surface ${classMap(
            menuClasses
          )}"
          activatable
          .fullwidth=${this.fixedMenuPosition ? false : !this.naturalMenuWidth}
          .open=${this.menuOpen}
          .anchor=${this.anchorElement}
          .fixed=${this.fixedMenuPosition}
          @selected=${this.onSelected}
          @opened=${this.onOpened}
          @closed=${this.onClosed}
          @items-updated=${this.onItemsUpdated}
          @keydown=${this.handleTypeahead}
        >
          <slot></slot>
        </fox-menu>
      </div>
      ${this.renderHelperText()}`;
  }
}

const name = 'fox-select';

if (!customElements.get(name)) {
  customElements.define(name, FoxSelect);
} else {
  console.warn(`${name} is already defined`);
}
