import { LitVirtualizer } from '@lit-labs/virtualizer';

declare global {
  interface HTMLElementTagNameMap {
    'fox-virtualizer': FoxVirtualizer;
  }
}

/**
 * This is a fox-virtualizer
 *
 * @element fox-virtualizer
 *
 */
export class FoxVirtualizer extends LitVirtualizer {}

const name = 'fox-virtualizer';
if (!customElements.get(name)) {
  customElements.define(name, FoxVirtualizer);
} else {
  console.warn(`${name} is already defined`);
}
