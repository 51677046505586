import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

import { styles } from './FoxKanbanSlice.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxKanbanSlice;
  }
}

export class FoxKanbanSlice extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: String }) heading = '';

  render() {
    return html`
      <fox-card theme="outlined">
        <div class="fox-kanban-slice__text">${this.heading}</div>
      </fox-card>
    `;
  }
}

const name = 'fox-kanban-slice';

if (!customElements.get(name)) {
  customElements.define(name, FoxKanbanSlice);
} else {
  console.warn(`${name} is already defined`);
}
