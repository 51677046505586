import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ElementExecutorComponent,
  ElementExecutorComponentModule,
} from './element-executor/element-executor.component';

@NgModule({
  imports: [CommonModule, ElementExecutorComponentModule],
  exports: [ElementExecutorComponent],
})
export class SharedFeatureElementExecutorModule {}
