import { LinearProgressBase } from '@material/mwc-linear-progress/mwc-linear-progress-base';
import { styles as mwcStyles } from '@material/mwc-linear-progress/mwc-linear-progress.css';

import { styles } from './FoxLinearProgress.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxLinearProgress;
  }
}

export class FoxLinearProgress extends LinearProgressBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-linear-progress';

if (!customElements.get(name)) {
  customElements.define(name, FoxLinearProgress);
} else {
  console.warn(`${name} is already defined`);
}
