import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { ensureNotNil } from '@assecosolutions/fox-common-utils';
import { DialogType, FoxDialog } from '@assecosolutions/fox-dialog';

import { DEFAULT_DIALOG_OPTIONS, Dialog } from './dialog.model';
import { DialogService } from './dialog.service';

@Component({
  selector: 'fox-dialog-anchor',
  template: ` <fox-dialog
    data-test-id="dialog"
    [attr.open]="isOpen"
    [attr.heading]="heading"
    [attr.type]="type"
    [attr.no-border]="noBorder ? true : null"
    [attr.no-padding]="noPadding ? true : null"
    [attr.scrimClickAction]="scrimClickAction"
    [attr.hideActions]="hideActions ? true : null"
    [attr.escapeKeyAction]="escapeKeyAction"
    [attr.defaultAction]="defaultAction"
    (closing)="onClosing($event)"
  >
    <ng-container *ngTemplateOutlet="bodyTemplateRef"></ng-container>
    <ng-container *ngTemplateOutlet="slotTemplateRef"></ng-container>

    <ng-template #viewContainer></ng-template>
  </fox-dialog>`,
})
export class DialogComponent implements Dialog, OnInit {
  @ViewChild('viewContainer', { read: ViewContainerRef, static: false })
  private _viewContainer: ViewContainerRef | null = null;

  @Input()
  bodyTemplateRef: TemplateRef<unknown> | null = null;

  @Input()
  slotTemplateRef: TemplateRef<unknown> | null = null;

  //
  // Dialog Options
  //

  @Input()
  id = DEFAULT_DIALOG_OPTIONS.id;

  @Input()
  heading = DEFAULT_DIALOG_OPTIONS.heading;

  @Input()
  type: DialogType = DEFAULT_DIALOG_OPTIONS.type;

  @Input()
  noBorder: boolean = DEFAULT_DIALOG_OPTIONS.noBorder;

  @Input()
  noPadding: boolean = DEFAULT_DIALOG_OPTIONS.noPadding;

  @Input()
  scrimClickAction: string = DEFAULT_DIALOG_OPTIONS.scrimClickAction;

  @Input()
  escapeKeyAction: string = DEFAULT_DIALOG_OPTIONS.escapeKeyAction;

  @Input()
  defaultAction: string = DEFAULT_DIALOG_OPTIONS.defaultAction;

  @Input()
  hideActions = DEFAULT_DIALOG_OPTIONS.hideActions;

  private _isOpenNullable: true | null = null;

  set isOpen(value: boolean | null) {
    this._isOpenNullable = value === true ? true : null;
  }

  get isOpen(): true | null {
    return this._isOpenNullable;
  }

  get viewContainer(): ViewContainerRef {
    return ensureNotNil(this._viewContainer);
  }

  constructor(private _dialogService: DialogService) {}

  open() {
    this.isOpen = true;
  }

  close() {
    this.viewContainer?.clear();
    this.isOpen = false;
  }

  onClosing(event: Event) {
    if (event.target instanceof FoxDialog) {
      this.close();
    }
  }

  ngOnInit(): void {
    this._dialogService.register(this);
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [DialogComponent],
  declarations: [DialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogComponentModule {}
