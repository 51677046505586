<fox-button label="Preview" raised disabled (click)="onPreview()"></fox-button>
<fox-button
  label="Save"
  primary
  raised
  (click)="onSave()"
  [foxFormSubmit]="formGroup"
  type="submit"
></fox-button>
<fox-button
  label="Ask for Review"
  (click)="onUpdateState(states.InReview)"
  raised
  *ngIf="showReviewButton"
></fox-button>
<fox-button
  label="Release"
  raised
  (click)="onUpdateState(states.Released)"
  *ngIf="showReleaseButton"
></fox-button>
<fox-button
  label="Retract"
  raised
  (click)="onUpdateState(states.Retracted)"
  *ngIf="showRetractButton"
></fox-button>
