import '@vaadin/vaadin-grid/theme/material/vaadin-grid.js';
import { GridElement } from '@vaadin/vaadin-grid/src/vaadin-grid.js';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table': FoxTable;
  }
}

export class FoxTable extends GridElement {}

window.customElements.define('fox-table', FoxTable);
