import { html, LitElement } from 'lit';
import { property, query } from 'lit/decorators.js';

import { FoxCircularProgress } from './FoxCircularProgress';
import { FoxLinearProgress } from './FoxLinearProgress';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxProgress;
  }
}

/**
 *
 * FOX Progress
 *
 * @element fox-progress
 *
 * @cssprop [--fox-progress-color=var(--fox-client-base-color, #4599ff)] - Color of the Progress bar
 *
 */

export class FoxProgress extends LitElement {
  @property({ type: Boolean, reflect: true }) indeterminate = false;
  @property({ type: Number }) progress = 0;
  @property({ type: Number }) buffer = 1;
  @property({ type: Number }) density = 1;
  @property({ type: Boolean, reflect: true }) reverse = false;
  @property({ type: Boolean, reflect: true }) closed = false;
  @property({ type: String, reflect: true }) type = '';

  @query('fox-linear-progress')
  linearProgress: FoxLinearProgress | undefined;

  @query('fox-circular-progress')
  circularProgress: FoxCircularProgress | undefined;

  render() {
    if (this.type === 'linear') {
      return html`<fox-linear-progress></fox-linear-progress>`;
    }
    if (this.type === 'circular') {
      return html`<fox-circular-progress></fox-circular-progress>`;
    }
    return null;
  }

  firstUpdated() {
    if (this.circularProgress) {
      this.circularProgress.indeterminate = this.indeterminate;
      this.circularProgress.progress = this.progress;
      this.circularProgress.density = this.density;
      this.circularProgress.closed = this.closed;
    }

    if (this.linearProgress) {
      this.linearProgress.indeterminate = this.indeterminate;
      this.linearProgress.progress = this.progress;
      this.linearProgress.buffer = this.buffer;
      this.linearProgress.reverse = this.reverse;
      this.linearProgress.closed = this.closed;
    }
  }
}

const name = 'fox-progress';

if (!customElements.get(name)) {
  customElements.define(name, FoxProgress);
} else {
  console.warn(`${name} is already defined`);
}
