import { isArray, isNil, isString } from './strict-mode';

export const isEmpty = (value: unknown): boolean => {
  if (isNil(value)) {
    return true;
  } else if (isArray(value)) {
    return value.length === 0;
  } else if (isString(value)) {
    return value.trim() === '';
  }
  return false;
};

export const isNotEmpty = (value: unknown): boolean => {
  return !isEmpty(value);
};
