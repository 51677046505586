import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import '@assecosolutions/fox-icon';

import { styles } from './FoxBadge.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxBadge;
  }
}

/**
 *
 * FOX Badge
 *
 * @element fox-badge
 *
 * @slot icon - Optional slot for custom icon e.g : if an event on icon needed
 * @slot trailingIcon - Optional slot for custom trailingIcon e.g : if an event on icon needed
 *
 * @cssprop [--fox-badge-text-color=var(--fox-base-grey-100-color, #0f1310)] - Text color of a Badge
 * @cssprop [--fox-badge-primary-color=var(--fox-base-grey-20-color, #f5f7f9)] - Background color of a Badge
 * @cssprop [--fox-badge-icon-color=var(--fox-badge-text-color)] - Icon color of a Badge, default its the text color
 * @cssprop [--fox-badge-outlined-color=var(--fox-base-grey-100-color, #0f1310)] - Text and border color of a Badge in outline state
 *
 * @cssprop [--fox-badge-border=none] - Default Badges dont have border, but it will be overwritten when outlined is set
 * @cssprop [--fox-badge-font-weight=var(--fox-font-weight-semi-bold, 600)] - Font weight of a Badge
 * @cssprop [--fox-badge-font-size=var(--fox-font-size-s, 12px)] - Font size of a Badge
 * @cssprop [--fox-badge-border-radius=10rem] - Border radius of a Badge
 * @cssprop [--fox-badge-height=20px] - Height of a Badge
 *
 */

export class FoxBadge extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  /** Creates an outlined badge */
  @property({ type: Boolean, reflect: true }) outlined = false;
  /** When true, icon will be displayed after label. */
  @property({ type: Boolean, attribute: 'trailingicon' }) trailingIcon = false;
  /** Icon to display, and aria-label value when label is not defined. */
  @property({ type: String }) icon = '';
  /** Label to display for the badge, and aria-label.*/
  @property({ type: String, reflect: true }) label = '';

  render() {
    const classes = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fox-badge__outlined': this.outlined,
    };
    return html`
      <div class="fox-badge ${classMap(classes)}">
        <span class="leading-icon">
          <slot name="icon">
            ${this.icon && !this.trailingIcon ? this.renderIcon() : ''}
          </slot>
        </span>
        <span class="fox-badge__label" aria-label="${this.label || this.icon}">
          ${this.label}<slot></slot>
        </span>

        <span class="trailing-icon">
          <slot name="trailingIcon">
            ${this.icon && this.trailingIcon ? this.renderIcon() : ''}
          </slot>
        </span>
      </div>
    `;
  }

  renderIcon() {
    return html` <fox-icon class="fox-badge__icon"> ${this.icon} </fox-icon> `;
  }
}

const name = 'fox-badge';

if (!customElements.get(name)) {
  customElements.define(name, FoxBadge);
} else {
  console.warn(`${name} is already defined`);
}
