import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import '@assecosolutions/fox-table';
import '@assecosolutions/fox-activity-list';
import '@assecosolutions/fox-typed-field';
import '@assecosolutions/fox-table-executor';
import '@assecosolutions/fox-accordion';
import '@assecosolutions/fox-badge';
import '@assecosolutions/fox-button';
import '@assecosolutions/fox-text';
import '@assecosolutions/fox-icon-button';
import '@assecosolutions/fox-icon-grid';
import '@assecosolutions/fox-icon-button-toggle';
import '@assecosolutions/fox-card';
import '@assecosolutions/fox-card-list';
import '@assecosolutions/fox-checkbox';
import '@assecosolutions/fox-combobox';
import '@assecosolutions/fox-dialog';
import '@assecosolutions/fox-formfield';
import '@assecosolutions/fox-app-bar';
import '@assecosolutions/fox-navigation-bar';
import '@assecosolutions/fox-front-layer';
import '@assecosolutions/fox-back-layer';
import '@assecosolutions/fox-content-layer';
import '@assecosolutions/fox-indicator';
import '@assecosolutions/fox-icon';
import '@assecosolutions/fox-list';
import '@assecosolutions/fox-list-extended';
import '@assecosolutions/fox-select';
import '@assecosolutions/fox-sidebar';
import '@assecosolutions/fox-notification';
import '@assecosolutions/fox-tab';
import '@assecosolutions/fox-progress';
import '@assecosolutions/fox-chart';
import '@assecosolutions/fox-menu';
import '@assecosolutions/fox-kanban-board';
import '@assecosolutions/fox-textarea';
import '@assecosolutions/fox-textfield';
import '@assecosolutions/fox-radio';
import '@assecosolutions/fox-snackbar';
import '@assecosolutions/fox-condition-builder';
import { generateTheme } from '@assecosolutions/fox-common-styles';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

generateTheme();
