import { CustomItemEvent } from '@assecosolutions/fox-common-utils';

import { FoxIconGrid, NAME } from './FoxIconGrid';

export interface FoxGridIcon {
  name: string;
  version: number;
  popularity: number;
  codepoint: number;
  unsupported_families: string[];
  categories: string[];
  tags: string[];
  sizes_px: number[];
}

export type IconSelectedEvent<T> = CustomItemEvent<T>;

declare global {
  interface HTMLElementTagNameMap {
    [NAME]: FoxIconGrid;
  }

  interface HTMLElementEventMap {
    'icon-selected': IconSelectedEvent<string>;
  }
}
