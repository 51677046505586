import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { cloneDeep } from 'lodash-es';

import { FoxCard } from '@assecosolutions/fox-card';
import {
  ElementAction,
  ElementActionTargetEnum,
  ElementActionTriggerEnum,
  TableConfiguration,
} from '@assecosolutions/fox-common-models';
import { ensureNotNil, isNotNil } from '@assecosolutions/fox-common-utils';
import {
  FoxTableExecutor,
  TableCellClick,
} from '@assecosolutions/fox-table-executor';
import { hasChanged, NgChanges } from '@assecosolutions/ng-common';
import { MenuService } from '@assecosolutions/ng-menu';
import {
  SearchComponentModule,
  searchTableData,
} from '@assecosolutions/ng-search';
import { ElementConfiguration } from '@fox/shared/models';

import { ActionExecutorService } from '../action-executor/action-executor.service';
import { ElementExecutorBaseComponent } from '../element-executor-base.component';
import { ElementExecutorOptions } from '../element-executor.model';
import { filterElementActionsByEvent } from '../element-executor.utils';
import { ElementExecutorTableMenuComponent } from './element-executor-table-menu.component';
import { applyRequestOrderConfigurationToTableColumnConfiguration } from './element-executor-table.utils';

@Component({
  selector: 'fox-element-executor-table',
  templateUrl: './element-executor-table.component.html',
  styleUrls: ['./element-executor-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementExecutorTableComponent
  extends ElementExecutorBaseComponent
  implements OnChanges
{
  @Input()
  elementConfiguration?: ElementConfiguration<TableConfiguration>;

  @Input()
  data: unknown;

  @Input()
  options: ElementExecutorOptions | undefined = undefined;

  @ViewChild('table')
  table!: ElementRef<FoxTableExecutor>;

  @ViewChild('card', { read: ElementRef })
  card!: ElementRef<FoxCard>;

  filteredData: unknown;

  constructor(
    protected actionExecutorService: ActionExecutorService,
    protected menuService: MenuService
  ) {
    super(actionExecutorService, menuService);
  }

  ngOnChanges(changes: NgChanges<ElementExecutorTableComponent>) {
    if (
      hasChanged(changes, 'elementConfiguration') &&
      this.elementConfiguration
    ) {
      const detailConfiguration =
        applyRequestOrderConfigurationToTableColumnConfiguration(
          this.elementConfiguration
        );

      this.elementConfiguration = {
        ...this.elementConfiguration,
        detailConfiguration,
      };
    }
  }

  nameValue(): boolean | string | undefined {
    return this.options?.showName && this.elementConfiguration?.name;
  }

  countValue(): boolean | number | undefined {
    return this.options?.showCount && (this.data as Array<unknown>)?.length;
  }

  onOpenMenu(e: MouseEvent) {
    const filteredActions = filterElementActionsByEvent(
      {
        trigger: ElementActionTriggerEnum.LEFT_CLICK,
        target: ElementActionTargetEnum.HEADER,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );

    this.openElementMenu(e.x / 2, e.y / 2, filteredActions);
  }

  onSearch(query: string) {
    if (
      isNotNil(this.elementConfiguration) &&
      this.elementConfiguration?.detailConfiguration?.columns
    ) {
      this.filteredData = searchTableData(
        this.elementConfiguration.detailConfiguration.columns,
        query,
        <unknown[]>this.data
      );
    }
  }

  onCellClick(e: TableCellClick<unknown>) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.LEFT_CLICK,
        target: ElementActionTargetEnum.TABLE_ROW,
        mouseEvent: e.detail.initialEvent,
        selectedItem: e.detail.item || undefined,
      },
      ensureNotNil(this.elementConfiguration)
    );
  }

  onRightClick(e: TableCellClick<unknown>) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.RIGHT_CLICK,
        target: ElementActionTargetEnum.TABLE_ROW,
        mouseEvent: e.detail.initialEvent,
        selectedItem: e.detail.item || undefined,
      },
      ensureNotNil(this.elementConfiguration)
    );
  }

  onTableContextmenu() {
    return this.preventContextMenuIfHasAction(
      ElementActionTargetEnum.TABLE_ROW
    );
  }

  onContextmenu(e: MouseEvent) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.RIGHT_CLICK,
        target: ElementActionTargetEnum.HEADER,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );

    return this.preventContextMenuIfHasAction(ElementActionTargetEnum.HEADER);
  }

  protected openElementMenu(
    x: number,
    y: number,
    elementActions?: ElementAction[]
  ) {
    const tableConfiguration = cloneDeep(this.elementConfiguration);
    const options = { x, y, inputs: { elementActions, tableConfiguration } };
    const ref = this.menuService.openMenuWithComponent(
      ElementExecutorTableMenuComponent,
      options
    );

    this.subscribeTo(ref.instance.triggerClick, (elementAction) => {
      this.actionExecutorService.executeAction(elementAction);
      this.menuService.close();
    });

    this.subscribeTo(ref.instance.updatedTableConfiguration, (c) => {
      this.updatedElementConfiguration.emit(c);
    });
  }

  private preventContextMenuIfHasAction(target: ElementActionTargetEnum) {
    return !filterElementActionsByEvent(
      {
        trigger: ElementActionTriggerEnum.RIGHT_CLICK,
        target: target,
      },
      ensureNotNil(this.elementConfiguration)
    )?.length;
  }
}

@NgModule({
  imports: [CommonModule, SearchComponentModule],
  declarations: [ElementExecutorTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ElementExecutorTableComponent],
})
export class ElementExecutorTableComponentModule {}
