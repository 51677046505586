export {
  TextAreaCharCounter,
  TextFieldType,
} from '@material/mwc-textfield/mwc-textfield-base.js';
import { TextAreaBase } from '@material/mwc-textarea/mwc-textarea-base';

import { style, styles } from '@assecosolutions/fox-textfield';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxTextarea;
  }
}

export class FoxTextarea extends TextAreaBase {
  /**
   * @ignore
   */
  static styles = [style, styles];
}

const name = 'fox-textarea';

if (!customElements.get(name)) {
  customElements.define(name, FoxTextarea);
} else {
  console.warn(`${name} is already defined`);
}
