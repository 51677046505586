export interface FormulasConfiguration {
  path?: string;
  mapTo?: number;
}

export enum FoxFormulasTypes {
  SUM = 'sum',
  MEDIAN = 'median',
  AVERAGE = 'average',
  COUNT = 'count',
  MAX = 'max',
  MIN = 'min',
}
