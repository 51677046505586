import { CircularProgressBase } from '@material/mwc-circular-progress/mwc-circular-progress-base';
import { styles as mwcStyles } from '@material/mwc-circular-progress/mwc-circular-progress.css';

import { styles } from './FoxCircularProgress.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxCircularProgress;
  }
}

export class FoxCircularProgress extends CircularProgressBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-circular-progress';

if (!customElements.get(name)) {
  customElements.define(name, FoxCircularProgress);
} else {
  console.warn(`${name} is already defined`);
}
