import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedFeatureElementExecutorModule } from '@assecosolutions/ng-element-executor';
import { SharedFeatureReleaseProcessModule } from '@fox/administration/shared/feature-release-process';
import { TextfieldControlDirectiveModule } from '@fox/shared-ui-forms';
import {
  DashboardConfiguration,
  ElementConfiguration,
} from '@fox/shared/models';

import { ElementLoaderComponentModule } from './element-loader/element-loader.component';
import {
  Coordinate,
  PageElementForInsertion,
  Rectangle,
  RectangleSide,
} from './page-element.model';
import {
  insertionPossibleOnSide,
  pointIsAtEdgeOfRectangle,
  pointIsOnSideOfRectangle,
} from './page-element.utils';

@Component({
  selector: 'fox-page-element',
  templateUrl: './page-element.component.html',
  styleUrls: ['./page-element.component.scss'],
})
export class PageElementComponent {
  @ViewChild('elementLoader')
  elementLoader!: ElementRef;

  @Input()
  element!: DashboardConfiguration;

  @Input()
  parentElement!: DashboardConfiguration;

  @Input()
  pageId?: string;

  @Input()
  isEditable?: boolean;

  @Input()
  isDragging = false;

  @Output()
  dragStartedInside = new EventEmitter<DashboardConfiguration>();

  @Output()
  dragEnded = new EventEmitter<void>();

  @Output()
  registerPageElementForInsertion = new EventEmitter<PageElementForInsertion>();

  @Output()
  draggingOverElement = new EventEmitter<RectangleSide>();

  @Output()
  deleteElement = new EventEmitter<ElementConfiguration>();

  @Output()
  showBorderOnParent = new EventEmitter<RectangleSide>();

  borderSide?: RectangleSide;

  get parentId(): string {
    return this.parentElement?.id;
  }

  get dragDisabled(): boolean {
    return !this.parentId || !this.isEditable;
  }

  get hasChildren(): boolean {
    return !!this.element?.children?.length;
  }

  get borderClass(): string {
    return this.borderSide ? `insertion-marker-${this.borderSide}` : '';
  }

  onDeleteElement(element: ElementConfiguration) {
    const children = this.parentElement?.children;
    if (!children) {
      return;
    }
    const index = children?.indexOf(this.element);

    if (this.element.element === element) {
      children?.splice(index, 1);
    }

    //for the case that we delete the last element in the config and have empty children left
    children.filter((child) => {
      if (child.children && !child.children?.length) {
        children?.splice(children?.indexOf(child), 1);
      }
    });

    this.deleteElement.emit(element);
  }

  onMouseMove($event: MouseEvent) {
    if (this.isDragging) {
      const x: number = $event.offsetX;
      const y: number = $event.offsetY;
      const width: number = this.elementLoader.nativeElement.offsetWidth;
      const height: number = this.elementLoader.nativeElement.offsetHeight;

      const rectangle: Rectangle = { height, width };
      const point: Coordinate = { x, y };

      const pointIsAtEdge = pointIsAtEdgeOfRectangle(point, rectangle);
      const side = pointIsOnSideOfRectangle(point, rectangle) as RectangleSide;
      let insertionPossible = false;

      if (this.element.type) {
        insertionPossible = insertionPossibleOnSide(side, this.element.type);
      }

      // this does only report to direct parent if a border should be visible.
      // so there is no "border" communication over more than two levels.
      if (!insertionPossible) {
        // show border on this element, register this element to be sibling of drag element
        this.showBorderOnParent.emit(undefined);
        this.borderSide = side;
        this.registerThisElement(side);
      } else if (pointIsAtEdge && !!this.parentElement?.parentId) {
        // show border on parent, emit dragging event so parent can register itself to be sibling of drag element

        this.borderSide = undefined;
        this.showBorderOnParent.emit(side);
        this.draggingOverElement.emit(side);
      } else {
        // show border on this element, register this element as parent
        this.borderSide = side;
        this.showBorderOnParent.emit(undefined);
        this.registerThisElementAsParent(side);
      }
    }
  }

  // reset border classes
  onMouseLeave() {
    this.borderSide = undefined;
    this.showBorderOnParent.emit(undefined);
  }

  onDraggingOverElement(side: RectangleSide) {
    if (
      this.element.type &&
      !insertionPossibleOnSide(side, this.element.type)
    ) {
      this.registerThisElement(side);
    } else {
      this.draggingOverElement.emit(side);
    }
  }

  onShowBorder(side: RectangleSide) {
    this.borderSide = side;
  }

  // this marks this element to be a "new" parent with the dragged element as a child
  registerThisElementAsParent(side: RectangleSide) {
    const elementForInsertion: PageElementForInsertion = {
      side,
      element: null,
      parent: this.element,
    };
    this.onRegisterPageElementForInsertion(elementForInsertion);
  }

  // this marks this element to be the sibling of the new element
  registerThisElement(side: RectangleSide) {
    const elementForInsertion: PageElementForInsertion = {
      side,
      element: this.element,
      parent: this.parentElement,
    };
    this.onRegisterPageElementForInsertion(elementForInsertion);
  }

  // carry this event up until it reaches the page parent
  onRegisterPageElementForInsertion(
    elementForInsertion: PageElementForInsertion
  ) {
    this.registerPageElementForInsertion.emit(elementForInsertion);
  }

  onDragStartedInside(parent: DashboardConfiguration) {
    this.dragStartedInside.emit(parent);
  }

  onDragEnded() {
    this.dragEnded.emit();
  }

  elementConfiguration(element: DashboardConfiguration): ElementConfiguration {
    return element?.element as ElementConfiguration;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    TextfieldControlDirectiveModule,
    SharedFeatureElementExecutorModule,
    SharedFeatureReleaseProcessModule,
    ElementLoaderComponentModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [PageElementComponent],
  exports: [PageElementComponent],
})
export class PageElementComponentModule {}
