import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { FoxNotification } from '@fox/shared/models';
import { NotificationsService } from '@fox/shared/util-notifications';

@Component({
  selector: 'fox-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: FoxNotification[] = [];
  subscription: Subscription | undefined;

  constructor(public notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.subscription = this.notificationsService.notification$.subscribe(
      (incomingNotification: FoxNotification) => {
        if (!incomingNotification) {
          return;
        }
        this.notifications.push(incomingNotification);
        setTimeout(() => {
          this.notifications = this.notifications.filter(
            (notification) => notification !== incomingNotification
          );
        }, incomingNotification.duration ?? 4000);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
