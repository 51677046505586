import { html, LitElement, nothing, TemplateResult } from 'lit';

import { Condition, ConditionSet } from '@assecosolutions/fox-common-models';
import { ensureNotNil } from '@assecosolutions/fox-common-utils';

import {
  emptyCondition,
  emptyConditionSet,
  isCondition,
  isConditionSet,
} from '../FoxConditionBuilder.helper';
import { Field, Option } from '../FoxConditionBuilder.model';
import { styles } from './FoxConditionBuilderCondition.css';
import {
  ConditionValueChanged,
  ConditionValueDeleted,
} from './FoxConditionBuilderCondition.model';
import { ConditionSetValueChanged } from './FoxConditionBuilderConditionSet.model';

export class FoxConditionBuilderConditionSet extends LitElement {
  static styles = [styles];

  static properties = {
    fields: { type: Array },
    operators: { type: Array },
    options: { type: Array },
    formValueSet: { type: Object },
    first: { type: Boolean },
  };

  fields: Field[] = [];

  operators: { [key: string]: string[] } = {};

  options: { [key: string]: Option[] } = {};

  formValueSet?: ConditionSet;

  first = false;

  render(): TemplateResult {
    return html`
      <div class="fox-condition-builder-condition-set">
        <div class="fox-condition-builder-condition-set__action-bar">
          <fox-select
            fixedMenuPosition
            name="disjunction"
            @selected="${(e: { target: HTMLInputElement }) =>
              this.onDisjunctionChanged(e)}"
          >
            <fox-list-item
              .selected="${this.safeFormValueSet.disjunction === 'and'}"
              value="and"
            >
              AND
            </fox-list-item>
            <fox-list-item
              .selected="${this.safeFormValueSet.disjunction === 'or'}"
              value="or"
            >
              OR
            </fox-list-item>
          </fox-select>
          <div class="fox-condition-builder-condition-set__actions">
            <fox-button
              icon="add"
              label="condition"
              @click="${this.onCreateCondition}"
            ></fox-button>
            <fox-button
              icon="add"
              label="condition set"
              @click="${this.onCreateConditionSet}"
            ></fox-button>

            ${!this.first
              ? html` <fox-icon-button
                  icon="delete"
                  @click="${this.onFormValueSetDelete}"
                ></fox-icon-button>`
              : nothing}
          </div>
        </div>

        ${this.formValueSet?.conditions ? this.renderTree() : nothing}
      </div>
    `;
  }

  private get safeFormValueSet(): ConditionSet {
    return ensureNotNil(this.formValueSet);
  }

  private renderTree(): TemplateResult[] {
    return Object.values(this.safeFormValueSet.conditions)
      .map((conditions) => {
        if (isCondition(conditions)) {
          return this.renderCondition(conditions);
        } else if (isConditionSet(conditions)) {
          return this.renderConditionSet(conditions);
        } else {
          return html``;
        }
      })
      .filter((v) => v !== null);
  }

  private renderConditionSet(formValueSet: ConditionSet): TemplateResult {
    return html`
      <fox-condition-builder-condition-set
        @form-set-value-changed="${(e: ConditionSetValueChanged) =>
          this.onFormValueChanged(e)}"
        .fields="${this.fields}"
        .operators="${this.operators}"
        .formValueSet="${formValueSet}"
        .options="${this.options}"
      ></fox-condition-builder-condition-set>
    `;
  }

  private renderCondition(condition: Condition): TemplateResult {
    return html`
      <fox-condition-builder-condition
        @form-value-changed="${(e: ConditionValueChanged) =>
          this.onFormValueChanged(e)}"
        @form-value-delete="${(e: ConditionValueDeleted) =>
          this.onFormValueDelete(e)}"
        .fields="${this.fields}"
        .operators="${this.operators}"
        .formValue="${condition}"
        .options="${this.options}"
      ></fox-condition-builder-condition>
    `;
  }

  private onCreateCondition() {
    this.dispatchChangedEvent({
      ...this.safeFormValueSet,
      conditions: [
        ...(this.safeFormValueSet?.conditions ?? []),
        emptyCondition(),
      ],
    });
  }

  private onCreateConditionSet() {
    this.dispatchChangedEvent({
      ...this.safeFormValueSet,
      conditions: [
        ...(this.safeFormValueSet.conditions ?? []),
        emptyConditionSet(),
      ],
    });
  }

  private onDisjunctionChanged(e: { target: HTMLInputElement }) {
    this.dispatchChangedEvent({
      ...this.safeFormValueSet,
      disjunction: e.target.value,
    });
  }

  private onFormValueDelete(e: ConditionValueDeleted) {
    const updatedItems = this.safeFormValueSet.conditions.filter(
      (el) => el !== e.detail.value
    );

    this.dispatchChangedEvent({
      ...this.safeFormValueSet,
      conditions: updatedItems,
    });
  }

  private onFormValueSetDelete() {
    // TODO should rewrite this, null will not work here
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.dispatchChangedEvent({});
  }

  private onFormValueChanged(
    e: ConditionValueChanged | ConditionSetValueChanged
  ) {
    const updatedItems = Object.values(this.safeFormValueSet.conditions).map(
      (el) => (el === e.detail.old ? e.detail.new : el)
    );

    this.dispatchChangedEvent({
      ...this.safeFormValueSet,
      conditions: updatedItems,
    });
  }

  private dispatchChangedEvent(updatedItems: ConditionSet | null) {
    if (updatedItems) {
      const event: ConditionSetValueChanged = new CustomEvent(
        'form-set-value-changed',
        {
          detail: {
            new: updatedItems,
            old: this.safeFormValueSet,
          },
        }
      );
      this.dispatchEvent(event);
    }
  }
}

export const CONDITION_SET_NAME = 'fox-condition-builder-condition-set';

if (!customElements.get(CONDITION_SET_NAME)) {
  customElements.define(CONDITION_SET_NAME, FoxConditionBuilderConditionSet);
} else {
  console.warn(`${CONDITION_SET_NAME} is already defined`);
}
