import { CardListItem } from '@assecosolutions/fox-card-list';
import { ElementTypes } from '@fox/shared/models';

export const ELEMENT_TYPES: CardListItem[] = [
  { name: ElementTypes.ELEMENT_TYPE_TABLE, icon: 'table_rows' },
  { name: ElementTypes.ELEMENT_TYPE_INDICATOR, icon: '123' },
  { name: ElementTypes.ELEMENT_TYPE_CHART, icon: 'pie_chart' },
  { name: ElementTypes.ELEMENT_TYPE_KANBAN, icon: 'view_kanban' },
];

export interface ElementExecutorOptions {
  showPlaceholder?: boolean;
  selectable?: boolean;
  showName?: boolean;
  showCount?: boolean;
  showSmartFilter?: boolean;
  showPagination?: boolean;
  showStatus?: boolean;
  showIndex?: boolean;
  disableActions?: boolean;
  compact?: boolean;
  collapsable?: boolean;
}
