import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { isEqual, omit } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, ignoreElements, map, tap } from 'rxjs/operators';

import { StringRecord, isNotEmpty } from '@assecosolutions/fox-common-utils';
import { urlSearchParams } from '@assecosolutions/ng-common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DynamicContentMenuItem } from '@fox/shared-ui-dynamic-content';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FoxValidators } from '@fox/shared-ui-forms';
import {
  CreateRequestRequest,
  CreateRequestVersionRequest,
  DataSource,
  Dependency,
  Parameter,
  Request,
} from '@fox/shared/api-administration';

import {
  BuilderRestTabService,
  ResponsePreviewTabs,
} from './builder-rest-tabs.service';
import { ControlsOf } from './utils';

export type ParameterType = keyof Pick<
  Request,
  'queryParameterList' | 'headerParameterList'
>;

export type RequestForm = ControlsOf<
  Omit<
    Request,
    'configurationId' | '_id' | 'state' | 'version' | 'dataSourceDependency'
  >
> & {
  dataSource: FormControl<DataSource | null>;
};

export type FormEntityChangeHandler = () => void;

@Injectable({ providedIn: 'root' })
export class BuilderRestFormService {
  form: FormGroup<RequestForm>;

  readonly initialFormValues = {
    name: '',
    description: '',
    method: Request.MethodEnum.Get,
    endpoint: '',
    headerParameterList: [] as Parameter[],
    queryParameterList: [] as Parameter[],
    requestInputList: [] as Parameter[],
    body: '',
    transform: '',
    isAction: false,
    dataSource: null as DataSource | null,
  };

  endpointChanges = new Subject<void>();
  queryParameterListChanges = new Subject<void>();

  private _appliedRequest?: Request;

  private _onFormEntityChangePreHandler: FormEntityChangeHandler | undefined =
    undefined;
  private _onFormEntityChangePostHandler: FormEntityChangeHandler | undefined =
    undefined;

  constructor(private tabService: BuilderRestTabService) {
    this.form = new FormGroup<RequestForm>({
      name: new FormControl(this.initialFormValues.name, {
        validators: FoxValidators.notEmpty,
        nonNullable: true,
      }),
      description: new FormControl(this.initialFormValues.description, {
        validators: FoxValidators.notEmpty,
        nonNullable: true,
      }),
      method: new FormControl(this.initialFormValues.method, {
        validators: FoxValidators.notEmpty,
        nonNullable: true,
      }),
      endpoint: new FormControl(this.initialFormValues.endpoint, {
        validators: FoxValidators.notEmpty,
        nonNullable: true,
      }),
      headerParameterList: new FormControl(
        this.initialFormValues.headerParameterList,
        { nonNullable: true }
      ),
      queryParameterList: new FormControl(
        this.initialFormValues.queryParameterList,
        { nonNullable: true }
      ),
      requestInputList: new FormControl(
        this.initialFormValues.requestInputList,
        { nonNullable: true }
      ),
      body: new FormControl(this.initialFormValues.body, { nonNullable: true }),
      transform: new FormControl(this.initialFormValues.transform, {
        nonNullable: true,
      }),
      isAction: new FormControl(this.initialFormValues.isAction, {
        nonNullable: true,
      }),
      dataSource: new FormControl(this.initialFormValues.dataSource),
    });
  }

  get hasId(): boolean {
    return !!this._appliedRequest;
  }

  get request(): Request {
    const formValue = this.form.getRawValue();
    const dataSource = formValue.dataSource;
    const merged = {
      ...(this._appliedRequest as Request),
      ...formValue,
    };

    return {
      ...omit(merged, ['dataSource']),
      dataSourceDependency: this.toDataSourceDependency(
        dataSource as DataSource
      ),
    };
  }

  get createRequestRequest(): CreateRequestRequest | undefined {
    return this.request
      ? {
          ...omit(this.request, ['configurationId', '_id', 'version', 'state']),
        }
      : undefined;
  }

  get createRequestVersionRequest(): CreateRequestVersionRequest | undefined {
    return this.request ? { ...omit(this.request, ['_id']) } : undefined;
  }

  registerFormEntityChangeHandler(
    pre?: FormEntityChangeHandler,
    post?: FormEntityChangeHandler
  ) {
    this._onFormEntityChangePreHandler = pre;
    this._onFormEntityChangePostHandler = post;
  }

  getTypedFormArray(formControlName: string) {
    return this.form.get(formControlName) as FormArray;
  }

  applyRequest(request: Request) {
    this.resetFormArrays();

    this._appliedRequest = request;

    if (this._onFormEntityChangePreHandler) {
      this._onFormEntityChangePreHandler();
    }

    this.form.patchValue(request);

    if (this._onFormEntityChangePostHandler) {
      this._onFormEntityChangePostHandler();
    }
  }

  reset() {
    this._appliedRequest = undefined;
    this._onFormEntityChangePreHandler = undefined;
    this._onFormEntityChangePostHandler = undefined;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.form.reset(this.initialFormValues);
  }

  getRequestInputs(): DynamicContentMenuItem[] {
    return this.form.value.requestInputList as DynamicContentMenuItem[];
  }

  addNewParameterRow(parameterType: ParameterType) {
    this.getTypedFormArray(parameterType).push(this.createParameterFormGroup());
    if (parameterType === 'queryParameterList') {
      this.queryParameterListChanges.next();
    }
  }

  removeParameterRow(index: number, parameterType: ParameterType) {
    this.getTypedFormArray(parameterType).removeAt(index);
    if (parameterType === 'queryParameterList') {
      this.queryParameterListChanges.next();
    }
  }

  addNewRequestInput() {
    this.tabService.activeResponsePreviewTab = ResponsePreviewTabs.INPUT;
    this.getTypedFormArray('requestInputList').push(
      this.createRequestInputFormGroup()
    );
  }

  removeRequestInput(i: number) {
    this.getTypedFormArray('requestInputList').removeAt(i);
  }

  syncEndpointWithQueryParameterList(): Observable<void> {
    return this.endpointChanges.pipe(
      map((_) => this.form.controls.endpoint.value),
      distinctUntilChanged(),
      map((endpoint: string) => urlSearchParams(endpoint)),
      tap((p: StringRecord) => this.createQueryParameterListFormArray(p)),
      ignoreElements()
    );
  }

  syncQueryParameterListWithEndpoint(): Observable<void> {
    return this.queryParameterListChanges.pipe(
      map((_) => this.form.controls.queryParameterList.value),
      distinctUntilChanged(isEqual),
      map((list: Parameter[]) => this.mapToStringRecord(list)),
      tap((p: StringRecord) => this.updateQueryParametersOfEndpoint(p)),
      ignoreElements()
    );
  }

  toDataSourceDependency(dataSource: DataSource): Dependency {
    return {
      configurationId: dataSource.configurationId,
      range: '*',
      configurationType: Dependency.ConfigurationTypeEnum.Datasource,
    };
  }

  private mapToStringRecord(list: Parameter[]): StringRecord {
    const params: StringRecord = {};
    list.forEach((parameter) => {
      params[parameter.key] = parameter.value;
    });
    return params;
  }

  private updateQueryParametersOfEndpoint(params: StringRecord) {
    let [endpoint] = this.form.controls.endpoint.value.split('?');

    const queryParamString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    if (isNotEmpty(queryParamString)) {
      endpoint += `?${queryParamString}`;
    }

    this.form.controls.endpoint.patchValue(endpoint);
  }

  private createQueryParameterListFormArray(params: StringRecord) {
    this.getTypedFormArray('queryParameterList').clear();
    for (const [k, v] of Object.entries(params)) {
      const parameterFormGroup = this.createParameterFormGroup(k, v);
      this.getTypedFormArray('queryParameterList').push(parameterFormGroup);
    }
  }

  private createParameterFormGroup(key = '', value = '') {
    return new FormGroup({
      key: new FormControl(key, {
        validators: [FoxValidators.notEmpty],
        nonNullable: true,
      }),
      value: new FormControl(value, {
        validators: [FoxValidators.notEmpty],
        nonNullable: true,
      }),
    });
  }

  private createRequestInputFormGroup(key = '', value = '') {
    return new FormGroup({
      key: new FormControl(key, {
        validators: [FoxValidators.notEmpty],
        nonNullable: true,
      }),
      value: new FormControl(value, { nonNullable: true }),
    });
  }

  private resetFormArrays() {
    const newControl = () =>
      new FormControl<Parameter[]>([], { nonNullable: true });
    this.form.setControl('requestInputList', newControl());
    this.form.setControl('queryParameterList', newControl());
    this.form.setControl('headerParameterList', newControl());
  }
}
