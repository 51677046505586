import { createAction, props } from '@ngrx/store';

import { PreviewConfiguration } from '@assecosolutions/fox-common-models';

export const openPreview = createAction(
  '[Preview] Open',
  props<{ payload: { item: unknown; configuration: PreviewConfiguration } }>()
);

export const closePreview = createAction('[Preview] Close');
