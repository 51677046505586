import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PreviewConfiguration } from '@assecosolutions/fox-common-models';

import { closePreview, openPreview } from './preview.actions';
import { State } from './preview.reducer';
import {
  selectPreviewActive,
  selectSelectedConfiguration,
  selectSelectedItem,
} from './preview.selectors';

@Injectable()
export class PreviewFacade {
  previewActive$ = this.store.select(selectPreviewActive);
  selectedItem$ = this.store.select(selectSelectedItem);
  selectedItemConfiguration$ = this.store.select(selectSelectedConfiguration);

  constructor(private store: Store<State>) {}

  openPreview(item: unknown, configuration: PreviewConfiguration) {
    this.store.dispatch(openPreview({ payload: { item, configuration } }));
  }

  closePreview() {
    this.store.dispatch(closePreview());
  }
}
