import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { assertUnreachable } from '@assecosolutions/fox-common-utils';
import { removeRedundantSlashes } from '@assecosolutions/ng-common';
import {
  UnknownHandler,
  UnknownHandlerBuilder,
} from '@assecosolutions/ng-common';
import { Parameter, Request } from '@fox/shared/api-administration';

import { replaceRequestInputs } from './request-input.util';

export const mapToAngularTypes = (
  request: Request
): {
  url: string;
  params: HttpParams;
  headers: HttpHeaders;
  body: string;
  withCredentials: boolean;
} => {
  request = replaceRequestInputs(request);

  const url = requestUrl(request);
  const params = httpParams(request.queryParameterList || []);
  const headers = httpHeaders(request.headerParameterList || []);
  const body = request.body || '';

  // FIXME: generate dataSource into Request interface or what ever
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const withCredentials = request.dataSource.withCredentials || false;

  return { url, params, headers, body, withCredentials };
};

// FIXME: generate dataSource into Request interface
// eslint-disable-next-line
export const requestUrl = (request: any): string => {
  const [endpoint] = request.endpoint.split('?');
  const url = `${request.dataSource.baseUrl}/${endpoint}`;
  return removeRedundantSlashes(url);
};

export const httpHeaders = (parameters: Parameter[]): HttpHeaders => {
  return parameters.reduce(
    (httpHeaders, { key, value }) => httpHeaders.append(key, value),
    new HttpHeaders()
  );
};

export const httpParams = (parameters: Parameter[]): HttpParams => {
  return parameters.reduce(
    (httpParams, { key, value }) => httpParams.append(key, value),
    new HttpParams()
  );
};

export const transformDataHandler: UnknownHandler<unknown, string> =
  new UnknownHandlerBuilder<unknown, string>()
    .withArrayHandler((data, transform) => {
      return data.map((v) => transformDataHandler.handle(v, transform));
    })
    .withObjectHandler((data, transformBy) => {
      return data[transformBy];
    })
    .withDefaultHandler((data) => data)
    .build();

@Injectable({ providedIn: 'root' })
export class RequestExecutorService {
  // isolated instance to not interfere with e.g. global interceptors
  private _httpClient: HttpClient;

  constructor(private backend: HttpBackend) {
    this._httpClient = new HttpClient(backend);
  }

  execute<T = unknown>(request: Request): Observable<T> {
    return this.executeRequest<T>(request).pipe(
      map((data: T) => {
        return request.transform
          ? (transformDataHandler.handle(data, request.transform) as T)
          : data;
      })
    );
  }

  private executeRequest<T = unknown>(request: Request): Observable<T> {
    switch (request.method) {
      case 'GET':
        return this.executeGet(request);
      case 'POST':
        return this.executePost(request);
      case 'PUT':
        return this.executePut(request);
      case 'PATCH':
        return this.executePatch(request);
      case 'DELETE':
        return this.executeDelete(request);
      default:
        assertUnreachable(request.method);
    }
    throw new Error('This code should be unreachable!');
  }

  private executeGet<T>(request: Request): Observable<T> {
    const { url, params, headers, withCredentials } =
      mapToAngularTypes(request);

    return this._httpClient.get<T>(url, {
      params,
      headers,
      withCredentials,
    });
  }

  private executePost<T = unknown>(request: Request): Observable<T> {
    const { url, params, headers, body, withCredentials } =
      mapToAngularTypes(request);
    return this._httpClient.post<T>(url, body, {
      params,
      headers,
      withCredentials,
    });
  }

  private executePut<T = unknown>(request: Request): Observable<T> {
    const { url, params, headers, body, withCredentials } =
      mapToAngularTypes(request);
    return this._httpClient.put<T>(url, body, {
      params,
      headers,
      withCredentials,
    });
  }

  private executePatch<T = unknown>(request: Request): Observable<T> {
    const { url, params, headers, body, withCredentials } =
      mapToAngularTypes(request);
    return this._httpClient.patch<T>(url, body, {
      params,
      headers,
      withCredentials,
    });
  }

  private executeDelete<T = unknown>(request: Request): Observable<T> {
    const { url, params, headers, withCredentials } =
      mapToAngularTypes(request);
    return this._httpClient.delete<T>(url, {
      params,
      headers,
      withCredentials,
    });
  }
}
