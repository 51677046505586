import { RadioBase } from '@material/mwc-radio/mwc-radio-base';
import { styles as mwcStyles } from '@material/mwc-radio/mwc-radio.css';

import { styles } from './FoxRadio.css';

export { SingleSelectionController } from '@material/mwc-radio/single-selection-controller';
declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxRadio;
  }
}

export class FoxRadio extends RadioBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-radio';

if (!customElements.get(name)) {
  customElements.define(name, FoxRadio);
} else {
  console.warn(`${name} is already defined`);
}
