import { UntypedFormGroup } from '@angular/forms';

import { isNotEmpty } from '@assecosolutions/fox-common-utils';

export type RequiredKeys<T> = {
  [K in keyof T]-?: unknown extends Pick<T, K> ? never : K;
}[keyof T];

export const checkRequiredKeys = <T>(
  values: Array<RequiredKeys<T>>,
  formGroup: UntypedFormGroup
): boolean => {
  return values.every((key) =>
    isNotEmpty(formGroup?.get(key as string)?.value)
  );
};
