import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActionRouteResolver } from './action-route.resolver';

@NgModule({
  imports: [CommonModule],
  providers: [ActionRouteResolver],
})
export class SharedUtilNgrxActionRouteResolverModule {}
