/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Version } from './version';
import { FoxConfiguration } from './foxConfiguration';
import { Dependency } from './dependency';


export interface Page { 
    configurationId: string;
    _id: string;
    version: Version;
    state: Page.StateEnum;
    dependencies?: Array<Dependency>;
    resolvedDependencies?: Array<FoxConfiguration>;
    name: string;
    description?: string;
    type: Page.TypeEnum;
    detailConfiguration?: object;
}
export namespace Page {
    export type StateEnum = 'IN_DEVELOPMENT' | 'IN_REVIEW' | 'RELEASED' | 'RETRACTED';
    export const StateEnum = {
        InDevelopment: 'IN_DEVELOPMENT' as StateEnum,
        InReview: 'IN_REVIEW' as StateEnum,
        Released: 'RELEASED' as StateEnum,
        Retracted: 'RETRACTED' as StateEnum
    };
    export type TypeEnum = 'dashboard' | 'spa';
    export const TypeEnum = {
        Dashboard: 'dashboard' as TypeEnum,
        Spa: 'spa' as TypeEnum
    };
}


