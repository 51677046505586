import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

import { styles } from './FoxAccordion.css';
import { FoxAccordionItem } from './FoxAccordionItem';

/**
 *  *
 * FOX accordion
 *
 * @element fox-accordion
 * This Component is not properly defined from the UX Team
 * it will have breaking changes
 *
 *
 */
export class FoxAccordion extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  /** enable open state on multiple accordion items */
  @property({ type: Boolean, reflect: true }) multi = false;

  foxAccordionItems: NodeListOf<FoxAccordionItem> | undefined;

  render() {
    return html` <slot></slot>`;
  }

  firstUpdated() {
    this.foxAccordionItems = this.querySelectorAll('fox-accordion-item');
    this.foxAccordionItems.forEach((element) => {
      element.addEventListener(
        'accordionOpened',
        this.onFoxAccordionItemsChanged.bind(this)
      );
    });
  }

  onFoxAccordionItemsChanged(e: Event) {
    const targetElement = e.target as FoxAccordionItem;
    if (this.foxAccordionItems && !this.multi) {
      this.foxAccordionItems.forEach((element) => {
        if (targetElement !== element) {
          element.open = false;
        }
      });
    }
  }
}

export const NAME = 'fox-accordion';

if (!customElements.get(NAME)) {
  customElements.define(NAME, FoxAccordion);
} else {
  console.warn(`${NAME} is already defined`);
}
