import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { PreviewFacade } from './preview.facade';
import { PREVIEW_FEATURE_KEY, reducer } from './preview.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(PREVIEW_FEATURE_KEY, reducer)],
  providers: [PreviewFacade],
})
export class PreviewStoreModule {}
