import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ReleaseProcessComponent,
  ReleaseProcessComponentModule,
} from './release-process/release-process.component';

@NgModule({
  imports: [CommonModule, ReleaseProcessComponentModule],
  exports: [ReleaseProcessComponent],
})
export class SharedFeatureReleaseProcessModule {}
