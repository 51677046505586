import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';

import { isNotNil } from '@assecosolutions/fox-common-utils';
import { NotificationsService } from '@fox/shared/util-notifications';

import * as NotificationActions from './notifications.actions';
import { FoxNotificationAction } from './notifications.model';
import * as notificationUtil from './notifications.util';

@Injectable()
export class NotificationsEffects {
  createNotification$ = createEffect(() =>
    this.actions$.pipe(
      map((action: FoxNotificationAction) => action.notification),
      filter(isNotNil),
      map((notification) => {
        const payload = notificationUtil.createNotification(notification);
        return NotificationActions.addNotification({ payload });
      })
    )
  );

  addNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.addNotification),
        tap(({ payload }) => {
          this.notificationsService.showNotification(payload);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService
  ) {}
}
