export enum UrlParam {
  ID = 'Id',
}

export interface Route {
  path: string;
  name?: string;
  icon?: string;

  /**
   * Parent of this route, used to construct route paths for navigation.
   */
  parent?: Route;

  /**
   * Hide this route when iterating (to e.g. fill navigation bar items).
   * Default true (undefined / null means true).
   */
  iterable?: boolean;
}

export class Routes {}

export class AdministrationRoutes extends Routes {
  static overview: Route = {
    path: 'overview',
    name: 'Dashboard',
    icon: 'dashboard',
    iterable: false,
  };
  static elements: Route = {
    path: 'elements',
    name: 'Elements',
    icon: 'grid_view',
  };
  static requests: Route = {
    path: 'requests',
    name: 'Requests',
    icon: 'storage',
  };
  static pages: Route = {
    path: 'pages',
    name: 'Pages',
    icon: 'description',
  };
  static modules: Route = {
    path: 'modules',
    name: 'Modules',
    icon: 'view_module',
  };
  static settings: Route = {
    path: 'settings',
    name: 'Settings',
    icon: 'settings',
  };
}

export class SettingsRoutes extends Routes {
  static dataSourceList: Route = {
    path: 'data-sources',
    name: 'Data Sources',
    icon: 'dashboard',
    parent: AdministrationRoutes.settings,
  };
}

export class RequestRoutes {
  static builder: Route = {
    path: 'builder',
    parent: AdministrationRoutes.requests,
  };
}
export class ModulesRouts {
  static builder: Route = {
    path: 'builder',
    parent: AdministrationRoutes.modules,
  };
}

export class ElementRoutes {
  static builder: Route = {
    path: 'builder',
    parent: AdministrationRoutes.elements,
  };

  static builderIndicator: Route = {
    path: 'indicator',
    parent: ElementRoutes.builder,
  };
  static builderChart: Route = {
    path: 'chart',
    parent: ElementRoutes.builder,
  };
  static builderTable: Route = {
    path: 'table',
    parent: ElementRoutes.builder,
  };
  static builderKanban: Route = {
    path: 'kanban',
    parent: ElementRoutes.builder,
  };
}

export class PageRoutes {
  static builder: Route = {
    path: 'builder',
    parent: AdministrationRoutes.pages,
  };

  static builderDashboard: Route = {
    path: 'dashboard',
    parent: PageRoutes.builder,
  };

  static builderSpa: Route = {
    path: 'spa',
    parent: PageRoutes.builder,
  };
}
