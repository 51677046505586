import { FormfieldBase } from '@material/mwc-formfield/mwc-formfield-base';
import { styles as mwcStyles } from '@material/mwc-formfield/mwc-formfield.css';

import { styles } from './FoxFormfield.css';

declare global {
  interface HTMLElementTagNameMap {
    'fox-formfield': FoxFormfield;
  }
}

export class FoxFormfield extends FormfieldBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-formfield';

if (!customElements.get(name)) {
  customElements.define(name, FoxFormfield);
} else {
  console.warn(`${name} is already defined`);
}
