import { HttpErrorResponse } from '@angular/common/http';

export interface FoxNotificationPayload {
  header: string;
  description: string;
  type: 'error' | 'success' | 'warning' | 'info';
  icon?: string;
}

export const defaultHttpErrorNotification = (
  error: HttpErrorResponse,
  description?: string
): FoxNotificationPayload => {
  return {
    header: `Error ${error.status} ${error.statusText}`,
    description: description
      ? description
      : `Something went wrong: ${error.message}`,
    icon: 'error',
    type: 'error',
  };
};

export const defaultSuccessNotification = (
  description: string
): FoxNotificationPayload => {
  return {
    header: 'Success!',
    description,
    icon: 'done',
    type: 'success',
  };
};

export const defaultErrorNotification = (
  description: string
): FoxNotificationPayload => {
  return {
    header: `Error!`,
    description,
    icon: 'error',
    type: 'error',
  };
};
