<fox-kanban-board *ngIf="data" class="h-100">
  <ng-container *ngIf="type === 'dynamic'">
    <fox-kanban-column
      *ngFor="let column of toUnknownRecord(data) | keyvalue"
      [heading]="column.key"
      [collapsable]="options?.collapsable"
      [count]="
        options?.showCount ? toUnknownRecord(column.value)?.length : undefined
      "
    >
      <fox-icon-button
        slot="action"
        [icon]="menuIcon"
        (click)="onOpenMenu($event)"
        (contextmenu)="onContextmenu($event)"
        *ngIf="!options?.disableActions && menuIcon"
      ></fox-icon-button>

      <div slot="body">
        <fox-element-executor-kanban-card
          *ngFor="let card of toUnknownRecord(column.value)"
          [title]="configurationToCardTitle(card)?.title"
          [link]="configurationToCardTitle(card)?.link"
          [enrichedKanbanCardsConfiguration]="enrichedKanbanCardsConfiguration"
          [data]="card"
          (rightClick)="onCardRightClick($event)"
          (leftClick)="onCardLeftClick($event)"
        ></fox-element-executor-kanban-card>
      </div>
    </fox-kanban-column>
  </ng-container>

  <ng-container *ngIf="type === 'static'">
    <fox-kanban-column
      *ngFor="let column of columns"
      [heading]="column.header"
      [collapsable]="options?.collapsable"
      [count]="options?.showCount ? toUnknownRecord(data)?.length : undefined"
    >
      <div slot="body">
        <fox-element-executor-kanban-card
          *ngFor="let card of toUnknownRecord(data)"
          [title]="configurationToCardTitle(card)?.title"
          [link]="configurationToCardTitle(card)?.link"
          [enrichedKanbanCardsConfiguration]="enrichedKanbanCardsConfiguration"
          [data]="card"
          (rightClick)="onCardRightClick($event)"
          (leftClick)="onCardLeftClick($event)"
        ></fox-element-executor-kanban-card>
      </div>
    </fox-kanban-column>
  </ng-container>
</fox-kanban-board>
