import { TextFieldBase } from '@material/mwc-textfield/mwc-textfield-base';
import { styles as mwcStyles } from '@material/mwc-textfield/mwc-textfield.css';
import { html, PropertyValues, TemplateResult } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';

import { styles } from './FoxTextfield.css';

export { TextFieldType } from '@material/mwc-textfield/mwc-textfield-base';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxTextField;
  }
}

export class FoxTextField extends TextFieldBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];

  /** @soyTemplate */
  override render(): TemplateResult {
    const shouldRenderCharCounter = this.charCounter && this.maxLength !== -1;
    const shouldRenderHelperText =
      !!this.helper || !!this.validationMessage || shouldRenderCharCounter;

    /** @classMap */
    const classes = {
      'mdc-text-field--disabled': this.disabled,
      'mdc-text-field--no-label': !this.label,
      'mdc-text-field--filled': !this.outlined,
      'mdc-text-field--outlined': this.outlined,
      'mdc-text-field--with-leading-icon': this.icon,
      'mdc-text-field--with-trailing-icon': this.iconTrailing,
      'mdc-text-field--end-aligned': this.endAligned,
    };

    return html`
      <label class="mdc-text-field ${classMap(classes)}">
        ${this.renderRipple()}
        ${this.outlined ? this.renderOutline() : this.renderLabel()}
        ${this.renderLeadingIcon()} ${this.renderPrefix()}
        ${this.renderInput(shouldRenderHelperText)} ${this.renderSuffix()}
        ${this.renderTrailingIcon()} ${this.renderLineRipple()}
      </label>
      ${this.renderHelperText(shouldRenderHelperText, shouldRenderCharCounter)}
    `;
  }

  /** @soyTemplate */
  protected renderInput(shouldRenderHelperText: boolean): TemplateResult {
    const minOrUndef = this.minLength === -1 ? undefined : this.minLength;
    const maxOrUndef = this.maxLength === -1 ? undefined : this.maxLength;
    const autocapitalizeOrUndef = this.autocapitalize
      ? (this.autocapitalize as
          | 'off'
          | 'none'
          | 'on'
          | 'sentences'
          | 'words'
          | 'characters')
      : undefined;
    const showValidationMessage = this.validationMessage && !this.isUiValid;
    const ariaLabelledbyOrUndef = this.label ? 'label' : undefined;
    const ariaControlsOrUndef = shouldRenderHelperText
      ? 'helper-text'
      : undefined;
    const ariaDescribedbyOrUndef =
      this.focused || this.helperPersistent || showValidationMessage
        ? 'helper-text'
        : undefined;
    // TODO: live() directive needs casting for lit-analyzer
    // https://github.com/runem/lit-analyzer/pull/91/files
    // TODO: lit-analyzer labels min/max as (number|string) instead of string
    return html`
      <div style="width: 100%; display: flex">
        <slot name="badges"> </slot>
        <input
          style="flex: 1 0 25%"
          aria-labelledby=${ifDefined(ariaLabelledbyOrUndef)}
          aria-controls="${ifDefined(ariaControlsOrUndef)}"
          aria-describedby="${ifDefined(ariaDescribedbyOrUndef)}"
          class="mdc-text-field__input"
          type="${this.type}"
          .value="${live(this.value) as unknown as string}"
          ?disabled="${this.disabled}"
          placeholder="${this.placeholder}"
          ?required="${this.required}"
          ?readonly="${this.readOnly}"
          minlength="${ifDefined(minOrUndef)}"
          maxlength="${ifDefined(maxOrUndef)}"
          pattern="${ifDefined(this.pattern ? this.pattern : undefined)}"
          min="${ifDefined(this.min === '' ? undefined : (this.min as number))}"
          max="${ifDefined(this.max === '' ? undefined : (this.max as number))}"
          step="${ifDefined(
            this.step === null ? undefined : (this.step as number)
          )}"
          size="${ifDefined(this.size === null ? undefined : this.size)}"
          name="${ifDefined(this.name === '' ? undefined : this.name)}"
          inputmode="${ifDefined(this.inputMode)}"
          autocapitalize="${ifDefined(autocapitalizeOrUndef)}"
          @input="${this.handleInputChange}"
          @focus="${this.onInputFocus}"
          @blur="${this.onInputBlur}"
        />
      </div>
    `;
  }

  override updated(changedProperties: PropertyValues) {
    if (
      changedProperties.has('value') &&
      changedProperties.get('value') !== undefined
    ) {
      this.mdcFoundation.setValue(this.value);

      if (this.autoValidate) {
        this.reportValidity();
      }
    }
  }

  showPicker() {
    if ('showPicker' in HTMLInputElement.prototype) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      //https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/showPicker
      this.formElement.showPicker();
    }
  }
}

const name = 'fox-textfield';

if (!customElements.get(name)) {
  customElements.define(name, FoxTextField);
} else {
  console.warn(`${name} is already defined`);
}
