import { ComponentRef, Injectable, Type } from '@angular/core';

import { Menu, MenuOptions } from './menu.model';

@Injectable()
export class MenuService {
  menu?: Menu;

  openMenuWithComponent<C extends object>(
    component: Type<C>,
    options: MenuOptions<C>
  ): ComponentRef<C> {
    const menu = this.menu;
    if (!menu) {
      throw new Error('no menu provided');
    }

    menu.viewContainer?.clear();
    menu.y = options.y;
    menu.x = options.x;
    menu.open();

    const ref = menu.viewContainer.createComponent<C>(component);

    if (options?.inputs) {
      Object.assign(ref.instance, options.inputs);
    }

    if (options?.styles) {
      menu.styles = options?.styles;
    }

    ref.changeDetectorRef.detectChanges();

    return ref;
  }

  close() {
    this.menu?.close();
  }
}
