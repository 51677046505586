import { isArray } from '@assecosolutions/fox-common-utils';
import { Parameter } from '@fox/shared/api-administration';

/**
 * Combines the request inputs from the request entity with the ones from the element config.
 * Since the request entity can be customized independently of the element config,
 * the combined inputs need to be stored in the element config.
 *
 * @param requestInputList
 * @param draftRequestInputList
 */
export function combineRequestInputLists(
  requestInputList: Parameter[],
  draftRequestInputList: Parameter[] = []
): Parameter[] {
  if (!isArray(draftRequestInputList)) {
    return requestInputList;
  }

  return (
    requestInputList &&
    requestInputList.map((requestInput) => ({
      ...requestInput,
      ...draftRequestInputList.find(
        (dRequestInput) => dRequestInput.key === requestInput.key
      ),
    }))
  );
}
