<div class="loading" *ngIf="loading">
  <fox-progress type="circular" indeterminate density="4"></fox-progress>
  Loading...
</div>

<iframe
  [src]="iframeUrl"
  (load)="onLoad($event)"
  (error)="onError($event)"
  *ngIf="iframeUrl"
></iframe>
