import { Component } from '@angular/core';

@Component({
  selector: 'fox-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  //TODO: this is only to demonstrate the BACKEND!!
  tenant = '';

  constructor() {
    this.tenant = localStorage.getItem('tenant');
    if (!this.tenant) {
      this.tenant = 'master';
      localStorage.setItem('tenant', 'master');
    }
  }

  updateXTenantId(tenantName: string) {
    localStorage.setItem('tenant', tenantName);
    this.tenant = tenantName;
    location.reload();
  }
}
