import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PREVIEW_FEATURE_KEY, State } from './preview.reducer';

export const state = createFeatureSelector<State>(PREVIEW_FEATURE_KEY);

export const selectSelectedItem = createSelector(
  state,
  (state: State) => state.item
);

export const selectSelectedConfiguration = createSelector(
  state,
  (state: State) => state.configuration
);

export const selectPreviewActive = createSelector(
  state,
  (state: State) => state.previewActive
);
