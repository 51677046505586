import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectorRouteResolver } from './selector-route.resolver';

@NgModule({
  imports: [CommonModule],
  providers: [SelectorRouteResolver],
})
export class SharedUtilNgrxSelectorRouteResolverModule {}
