export type KeysOfOpts = ObjectVisitorOpts;
export type FlattenOpts = ObjectVisitorOpts;

const DEFAULT_OBJECT_VISITOR_OPTS: ObjectVisitorOpts = {
  includeIntermediateKeys: false,
  includeArrayLeafs: false,
  includeObjectLeafs: false,
};

export const DEFAULT_KEYS_OF_OPTS = DEFAULT_OBJECT_VISITOR_OPTS;
export const DEFAULT_FLATTEN_OPTS = DEFAULT_OBJECT_VISITOR_OPTS;

export interface ObjectVisitorOpts {
  includeIntermediateKeys?: boolean;
  includeArrayLeafs?: boolean;
  includeObjectLeafs?: boolean;
}
