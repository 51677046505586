import '@vaadin/vaadin-grid/theme/material/vaadin-grid-filter-column.js';
import { GridFilterColumnElement } from '@vaadin/vaadin-grid/src/vaadin-grid-filter-column.js';

import { findHostGrid } from './FoxTableHelper';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-filter-column': FoxTableFilterColumn;
  }
}

export class FoxTableFilterColumn extends GridFilterColumnElement {
  _findHostGrid() {
    return findHostGrid(this);
  }
}

window.customElements.define('fox-table-filter-column', FoxTableFilterColumn);
