import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AdministrationNavigationBarComponent } from './administration-navigation-bar/administration-navigation-bar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AdministrationNavigationBarComponent],
  exports: [AdministrationNavigationBarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdministrationSharedFeatureNavigationModule {}
