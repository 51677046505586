import { ListItemBase } from '@material/mwc-list/mwc-list-item-base';
import { html } from 'lit';

import { styles } from './FoxListItem.css';

export {
  GraphicType,
  RequestSelectedDetail,
} from '@material/mwc-list/mwc-list-item-base';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxListItem;
  }
}

export class FoxListItem extends ListItemBase {
  /**
   * @ignore
   */
  static styles = [styles];

  render() {
    const text = this.renderText();
    const graphic = this.graphic ? this.renderGraphic() : html``;
    const meta = this.hasMeta ? this.renderMeta() : html``;

    return html`${this.renderBorder()} ${this.renderRipple()} ${graphic} ${text}
    ${meta}`;
  }

  protected renderBorder() {
    return html`<div
      class="activated-border ${this.activated ? 'active' : ''}"
    ></div>`;
  }
}

const name = 'fox-list-item';

if (!customElements.get(name)) {
  customElements.define(name, FoxListItem);
} else {
  console.warn(`${name} is already defined`);
}
