import { html, LitElement, nothing, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';

import { styles } from './FoxAccordionItem.css';
import '@assecosolutions/fox-icon';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxAccordionItem;
  }
}

/**
 *  *
 * FOX accordion item
 *
 * @element fox-accordion-item
 * This Component is not properly defined from the UX Team
 * it will have breaking changes
 *
 * @slot heading - Optional slot for heading to the left on the header.
 * @slot leadingIcon - Optional slot for leadingIcon on the first place of the header.
 * @slot description - Optional slot for description to the left on the header.
 * @slot indicatorIcon - Optional slot for content to the right on the header.
 *
 */
export class FoxAccordionItem extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  /** Opens the accordion-item. */
  @property({ type: Boolean, reflect: true }) open = false;

  /** Heading of the accordion-item. */
  @property({ type: String }) heading = '';

  /** Description of the accordion-item. */
  @property({ type: String }) description = '';

  /** Sets the done visualisation of the accordion-item. */
  @property({ type: Boolean, reflect: true }) done = false;

  /** disable the accordion-item. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /** hides the indicator icon of the accordion-item. */
  @property({ type: Boolean, reflect: true, attribute: 'hideindicator' })
  hideIndicator = false;

  /** Icon name. */
  @property({ type: String }) icon = 'expand_more';

  /** leading name. */
  @property({ type: String, attribute: 'leadingicon' }) leadingIcon = '';

  render() {
    const classes = {
      'fox-accordion-item__disabled': this.disabled,
      'fox-accordion-item__expanded': this.open,
      'fox-accordion-item__leading-icon': this.leadingIcon,
    };

    if (this.done) {
      this.leadingIcon = 'done';
    }

    return html`
      <div class="fox-accordion-item ${classMap(classes)}">
        <header class="fox-accordion-item__header" @click="${this.toggle}">
          <div class="fox-accordion-item__header-leading-icon">
            <slot name="leadingIcon">
              ${this.leadingIcon
                ? html`
                    <fox-icon class="fox-icon">${this.leadingIcon}</fox-icon>
                  `
                : nothing}
            </slot>
          </div>
          <span class="fox-accordion-item__header-heading">
            <slot name="heading">${this.heading}</slot>
          </span>
          <div class="fox-accordion-item__header-description">
            <slot name="description">${this.description}</slot>
          </div>

          <span class="fox-accordion-item__header-indicator">
            <slot name="indicatorIcon">
              ${this.icon && !this.hideIndicator
                ? html` <fox-icon class="fox-icon">${this.icon}</fox-icon> `
                : nothing}
            </slot>
          </span>
        </header>
        <div
          class="fox-accordion-item__content"
          aria-hidden="${this.open.toString() as 'true' | 'false'}"
          tabindex="${ifDefined(this.open ? undefined : -1)}"
        >
          <slot></slot>
        </div>
      </div>
    `;
  }

  toggle() {
    if (!this.disabled) {
      this.open = !this.open;
    }
  }

  protected updated(props: PropertyValues) {
    if (props.get('open') != null) {
      this.dispatchEvent(
        new CustomEvent('changed', {
          detail: {
            open: this.open,
            element: this,
          },
          bubbles: true,
        })
      );
      if (this.open) {
        this.dispatchEvent(
          new CustomEvent('accordionOpened', {
            detail: {
              open: this.open,
              element: this,
            },
            bubbles: true,
          })
        );
      }
    }
  }
}
const name = 'fox-accordion-item';

if (!customElements.get(name)) {
  customElements.define(name, FoxAccordionItem);
} else {
  console.warn(`${name} is already defined`);
}
