import '@vaadin/vaadin-grid/theme/material/vaadin-grid-sort-column.js';
import { GridSortColumnElement } from '@vaadin/vaadin-grid/src/vaadin-grid-sort-column.js';

import { findHostGrid } from './FoxTableHelper';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-sort-column': FoxTableSortColumn;
  }
}

export class FoxTableSortColumn extends GridSortColumnElement {
  _findHostGrid() {
    return findHostGrid(this);
  }
}

window.customElements.define('fox-table-sort-column', FoxTableSortColumn);
