import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  CheckboxControlDirectiveModule,
  ComboboxControlComponentModule,
  IconSelectionControlComponentModule,
  LabelControlDirectiveModule,
  MultiselectComboboxControlComponentModule,
  SelectControlDirectiveModule,
  TextfieldControlDirectiveModule,
  DatepickerControlDirectiveModule,
} from './form-controls';
import {
  RequiredDirectiveModule,
  SubmitDirectiveModule,
  ValidationMessageDirectiveModule,
} from './form-directives';

const controls = [
  CheckboxControlDirectiveModule,
  DatepickerControlDirectiveModule,
  ComboboxControlComponentModule,
  IconSelectionControlComponentModule,
  LabelControlDirectiveModule,
  MultiselectComboboxControlComponentModule,
  TextfieldControlDirectiveModule,
  SelectControlDirectiveModule,
];

const directives = [
  SubmitDirectiveModule,
  RequiredDirectiveModule,
  ValidationMessageDirectiveModule,
];

const modules = [FormsModule, ReactiveFormsModule, ...controls, ...directives];

@NgModule({
  exports: [...modules],
  imports: [...modules],
})
export class FoxFormsModule {}
