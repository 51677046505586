import '@vaadin/vaadin-grid/theme/material/vaadin-grid-tree-column.js';
import { GridTreeColumnElement } from '@vaadin/vaadin-grid/src/vaadin-grid-tree-column.js';

import { findHostGrid } from './FoxTableHelper';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-tree-column': FoxTableTreeColumn;
  }
}

export class FoxTableTreeColumn extends GridTreeColumnElement {
  _findHostGrid() {
    return findHostGrid(this);
  }
}

window.customElements.define('fox-table-tree-column', FoxTableTreeColumn);
