<ng-container *ngIf="configuration.length" [formGroup]="service.formGroup">
  <div *ngFor="let config of configuration">
    <div *ngIf="isTextFieldType(config?.type)">
      <fox-textfield
        class="pb-4"
        [label]="config.header"
        [type]="config?.type"
        [formControlName]="config.path"
      ></fox-textfield>
    </div>
  </div>
</ng-container>
