import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxKanbanColumn.css';
import '@assecosolutions/fox-icon-button';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxKanbanColumn;
  }
}

/**
 * Fox-kanban-column
 *
 * We recommend using the kanban-column with a virtual scroller <br />
 * If you are inside an Angular Project, we recommend using [Angular CDK Virtuall Scroller](https://material.angular.io/cdk/scrolling/overview) <br />
 *
 * @element fox-kanban-board
 *
 * @slot footer - This slot is for elements placed fixed at the bottom
 * @slot body - This slot contains the content of the Kanban column.
 * @slot action - This slot is for actions, placed inside the header
 * @slot header - In this slot you can set additional elements inside the Header like a subtitle
 */
export class FoxKanbanColumn extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];
  @property({ type: String }) heading = '';
  @property({ type: Boolean }) collapsable = false;
  @property({ type: Boolean }) collapsed = false;
  @property({ type: Boolean }) hideHeader = false;
  @property({ type: Boolean }) hideCounter = false;
  @property({ type: Number }) count!: number;

  render() {
    const collapsable = html`
      <fox-icon-button
        small
        @click="${this.collapseColumn}"
        class="fox-kanban-column__collapsable-link "
        icon=" ${this.collapsed ? 'arrow_right' : 'arrow_left'}"
      ></fox-icon-button>
    `;
    const classes = {
      'fox-kanban-column__collapsed': this.collapsed,
    };

    let innerContent = html``;
    if (this.collapsed) {
      innerContent = html`
        ${collapsable}
        ${!this.hideCounter && this.count
          ? html`<p class="fox-kanban-column__collapsed-counter">
              ${this.count}
            </p>`
          : nothing}
        <div class="fox-kanban-column__collapsed-title">${this.heading}</div>
      `;
    } else {
      innerContent = html`
        ${!this.hideHeader
          ? html`
              <div class="fox-kanban-column__header">
                <div class="fox-kanban-column__header-title-container">
                  ${this.collapsable ? collapsable : nothing}
                  <div
                    class="fox-kanban-column__header-title-container-title"
                    title="${this.heading}"
                  >
                    ${this.heading}
                  </div>

                  ${this.count && !this.hideCounter
                    ? html` <fox-badge
                        class="fox-kanban-column__header-title-container-counter"
                      >
                        ${this.count}
                      </fox-badge>`
                    : nothing}

                  <div class="fox-kanban-column__header-title-container-action">
                    <slot name="action"> </slot>
                  </div>
                </div>
                <slot name="header"> </slot>
              </div>
            `
          : nothing}
        <div class="fox-kanban-column__body">
          <slot name="body"></slot>
          <slot></slot>
        </div>
        <div class="fox-kanban-column__footer">
          <slot name="footer"> </slot>
        </div>
      `;
    }

    return html`<div class="fox-kanban-column ${classMap(classes)}">
      ${innerContent}
    </div>`;
  }

  collapseColumn() {
    this.collapsed = !this.collapsed;
  }
}

const name = 'fox-kanban-column';

if (!customElements.get(name)) {
  customElements.define(name, FoxKanbanColumn);
} else {
  console.warn(`${name} is already defined`);
}
