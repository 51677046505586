import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CreatePageRequest, Page } from '@fox/shared/api-administration';

import { ControlsOf } from './utils';

export type PageFormConfiguration = ControlsOf<
  Omit<CreatePageRequest, 'detailConfiguration'>
> & {
  detailConfiguration?: FormGroup;
};

@Injectable({ providedIn: 'root' })
export class PageBuilderFormService {
  pageConfiguration?: Page;

  formGroup = new FormGroup<PageFormConfiguration>({
    name: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    type: new FormControl(Page.TypeEnum.Dashboard, {
      validators: Validators.required,
      nonNullable: true,
    }),
    description: new FormControl('', {
      nonNullable: true,
    }),
  });

  get detailConfiguration() {
    return this.formGroup.get('detailConfiguration') as FormGroup | null;
  }

  get state() {
    return this.pageConfiguration?.state;
  }

  mergedPageConfigurationWithFormValues() {
    return {
      ...this.pageConfiguration,
      ...this.formGroup.getRawValue(),
    } as Page;
  }

  patchFormValues(page: Page) {
    this.pageConfiguration = page;
    this.formGroup.patchValue(page);
  }

  setDetailConfiguration(formGroup: FormGroup) {
    this.formGroup.setControl('detailConfiguration', formGroup, {
      emitEvent: false,
    });
  }

  reset() {
    this.formGroup.removeControl('detailConfiguration');
    this.formGroup.reset();
    this.pageConfiguration = undefined;
  }
}
