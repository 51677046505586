import { isNil } from './guards';

/**
 *
 * - Assert -
 *
 * Assertions that narrow a given type.
 * See: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
 */

/**
 * Assert that thing is not `null` or `undefined`.
 *
 * @param thing
 */
export function assertNotNil<T>(
  thing: T | null | undefined
): asserts thing is T {
  if (isNil(thing)) {
    throw new Error('Object is null | undefined!');
  }
}

/**
 * Assert that this code block is unreachable.
 * Use e.g. in switch case statements to make sure that no case is missed.
 *
 * @param _x
 */
export function assertUnreachable(_x: never): never {
  throw new Error('This code should be unreachable!');
}
