import { isEqual } from 'lodash-es';

/**
 * Angular's SimpleChanges are untyped.
 * For a little more type safety this can be used instead.
 * See here: https://netbasal.com/create-a-typed-version-of-simplechanges-in-angular-451f86593003
 *
 * Example:
 *
 * ngOnChanges(changes: NgChanges<MyComponent>): void {
 *
 *  if (hasChanged(changes, 'myInput')) { <-- typed
 *    // do something
 *    console.log(changes.myInput.currentValue) <-- typed
 *  }
 *
 *
 */
export type NgChanges<
  Component extends object,
  Props = Partial<ExcludeFunctions<Component>>
> = {
  [Key in keyof Props]: {
    previousValue: Props[Key];
    currentValue: Props[Key];
    firstChange: boolean;
    isFirstChange(): boolean;
  };
};

interface SubscriptionLike<T> {
  next(value?: T): void;
  complete(): void;
  unsubscribe(): void;
  readonly closed: boolean;
}

type MarkFunctionPropertyNames<Component> = {
  [Key in keyof Component]: Component[Key] extends  // eslint-disable-next-line @typescript-eslint/ban-types
    | Function
    | SubscriptionLike<unknown>
    ? never
    : Key;
};

type ExcludeFunctionPropertyNames<T extends object> =
  MarkFunctionPropertyNames<T>[keyof T];

type ExcludeFunctions<T extends object> = Pick<
  T,
  ExcludeFunctionPropertyNames<T>
>;

export const hasChanged = <T extends object>(
  changes: NgChanges<T>,
  key: keyof NgChanges<T>
): boolean => {
  return (
    changes[key] &&
    !isEqual(changes[key].currentValue, changes[key].previousValue)
  );
};
