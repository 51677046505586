import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import '@assecosolutions/fox-icon';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxText.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxText;
  }
}

export class FoxText extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];
  /** When true, icon will be displayed after label. */
  @property({ type: Boolean, attribute: 'trailingicon' }) trailingIcon = false;
  /** Icon to display, and aria-label value when label is not defined. */
  @property({ type: String }) icon = '';
  /** Label to display for the button, and aria-label.*/
  @property({ type: String }) label = '';

  render() {
    return html`
      ${this.icon && !this.trailingIcon ? this.renderIcon() : ''}
      <div class="label">${this.label}</div>
      ${this.icon && this.trailingIcon ? this.renderIcon() : ''}
    `;
  }

  renderIcon() {
    const classes = {
      'leading-icon': this.icon && !this.trailingIcon,
      'trailing-icon': this.icon && this.trailingIcon,
    };

    return html`
      <fox-icon
        class="${classMap(classes)} fox-text__icon"
        icon="${this.icon}"
        small
      ></fox-icon>
    `;
  }
}

const name = 'fox-text';

if (!customElements.get(name)) {
  customElements.define(name, FoxText);
} else {
  console.warn(`${name} is already defined`);
}
