/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/directive-selector,@angular-eslint/no-host-metadata-property,@typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Directive,
  forwardRef,
  NgModule,
  StaticProvider,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from './base-control-value-accessor';

export const DATEPICKER_VALUE_ACCESSOR: StaticProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FoxDatepickerControlValueAccessor),
  multi: true,
};

@Directive({
  selector: 'fox-datepicker',
  host: {
    '(change)': 'onChange($event.detail.item)',
    '(input-changed)': 'onChange($event.detail.item)',
    '(blur)': 'onTouched()',
  },
  providers: [DATEPICKER_VALUE_ACCESSOR],
})
export class FoxDatepickerControlValueAccessor extends BaseControlValueAccessor {}

@NgModule({
  declarations: [FoxDatepickerControlValueAccessor],
  exports: [FoxDatepickerControlValueAccessor],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DatepickerControlDirectiveModule {}
