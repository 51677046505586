import { ConditionSet } from '@assecosolutions/fox-common-models';
import { CustomItemEvent } from '@assecosolutions/fox-common-utils';

import { FoxConditionBuilder, NAME } from './FoxConditionBuilder';

declare global {
  interface HTMLElementTagNameMap {
    [NAME]: FoxConditionBuilder;
  }

  interface HTMLElementEventMap {
    'configuration-changed': FoxConditionBuilderConfigurationChanged;
  }
}

export type FoxConditionBuilderConfigurationChanged =
  CustomItemEvent<ConditionSet>;

export interface Option {
  name: string;
  value: string;
}

export interface Field {
  name: string;
  value?: string;
  type: string;
  nullable?: boolean;
  options?: Option[];
  operators?: string[];
  defaultValue?: unknown;
  defaultOperator?: unknown;
  entity?: string;
}
