export interface Icon {
  name: string;
}

export interface Theme {
  'client-base-color': string;
  'text-color': string;
  'icon-color': string;
}

// TODO: Move to Backend and build Editor in FOX System after API rework
export const ICONS: Icon[] = [
  { name: 'check_circle' },
  { name: 'error' },
  { name: 'error_outline' },
  { name: 'notification_important' },
  { name: 'warning' },
  { name: 'warning_amber' },
  { name: 'block' },
  { name: 'event_available' },
  { name: 'event_busy' },
  { name: 'directions_run' },
  { name: 'priority_high' },
  { name: 'running_with_errors' },
  { name: 'dangerous' },
  { name: 'announcement' },
  { name: 'report_off' },
  { name: 'report' },
  { name: 'favorite' },
  { name: 'favorite_border' },
  { name: 'visibility' },
  { name: 'highlight_off' },
  { name: 'star_rate' },
  { name: 'history' },
  { name: 'build' },
  { name: 'verified_user' },
  { name: 'watch_later' },
  { name: 'done_all' },
  { name: 'contact_support' },
  { name: 'leaderboard' },
  { name: 'bug_report' },
  { name: 'stars' },
  { name: 'work_outline' },
  { name: 'alarm' },
  { name: 'query_builder' },
  { name: 'build_circle' },
  { name: 'circle_notifications' },
  { name: 'thumb_up' },
  { name: 'thumb_up_off_alt' },
  { name: 'thumb_down' },
  { name: 'thumb_down_off_alt' },
  { name: 'recommend' },
];

// TODO: Move to Backend and build Editor in FOX System after API rework
export const themes: { [key: number]: Theme } = {
  1: {
    'client-base-color': '#00A04A',
    'text-color': '#00A04A',
    'icon-color': '#00A04A',
  },
  2: {
    'client-base-color': '#FDC50D',
    'text-color': '#FDC50D',
    'icon-color': '#FDC50D',
  },
  3: {
    'client-base-color': '#CE0707',
    'text-color': '#CE0707',
    'icon-color': '#CE0707',
  },
  4: {
    'client-base-color': '#204496',
    'text-color': '#204496',
    'icon-color': '#204496',
  },
  5: {
    'client-base-color': '#F18A3F',
    'text-color': '#F18A3F',
    'icon-color': '#F18A3F',
  },
  6: {
    'client-base-color': '#FABE3A',
    'text-color': '#FABE3A',
    'icon-color': '#FABE3A',
  },
  7: {
    'client-base-color': '#4599FF',
    'text-color': '#4599FF',
    'icon-color': '#4599FF',
  },
  8: {
    'client-base-color': '#76DDFF',
    'text-color': '#76DDFF',
    'icon-color': '#76DDFF',
  },
  9: {
    'client-base-color': '#B0BFCD',
    'text-color': '#B0BFCD',
    'icon-color': '#B0BFCD',
  },
  10: {
    'client-base-color': '#E7ECF0',
    'text-color': '#E7ECF0',
    'icon-color': '#E7ECF0',
  },
  11: {
    'client-base-color': '#515C67',
    'text-color': '#515C67',
    'icon-color': '#515C67',
  },
  12: {
    'client-base-color': '#0F1310',
    'text-color': '#0F1310',
    'icon-color': '#0F1310',
  },
};

export const generateTheme = () => {
  const styleElement = document.createElement('style');

  let styles = '';
  Object.entries(themes).forEach(([themeKey, theme]) => {
    const parentClassName = `.fox-theme-${themeKey}`;

    styles = styles + `${parentClassName} {`;
    Object.entries(theme).forEach(
      ([key, value]) => (styles += `--fox-${key}: ${value};`)
    );
    styles += `}`;
  });

  styleElement.innerHTML = styles;

  document.getElementsByTagName('head')[0].appendChild(styleElement);
};
