import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

import {
  enrichElementByValuePath,
  isObject,
} from '@assecosolutions/fox-common-utils';

import { styles } from './FoxListExtended.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxListExtended;
  }
}

export class FoxListExtended extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property() items: unknown[];
  @property({ type: String }) labelPath = 'label';
  @property({ type: Boolean }) activatable = true;
  @property({ type: String }) activePath = 'active';
  @property({ type: Boolean }) multi = false;

  @property() protected listItemTemplate: Node;

  render() {
    return html`
      <fox-list .activatable="${this.activatable}" .multi="${this.multi}">
        ${this.items?.map((item) => this.renderListItem(item))}
      </fox-list>
      <div class="hidden">
        <slot
          name="listItemTemplate"
          @slotchange=${this.handleListItemTemplate}
        ></slot>
      </div>
    `;
  }

  protected renderListItem(item: unknown) {
    let element: HTMLElement = document.createElement('fox-list-item');
    element.innerHTML = `<span value-path="${this.labelPath}"></span>`;

    if (this.listItemTemplate) {
      element = this.listItemTemplate.cloneNode(true) as HTMLElement;
    }

    const enrichedElement = enrichElementByValuePath(element, item);
    if (this.activatable && isObject(item) && item[this.activePath]) {
      enrichedElement.selected = true;
      enrichedElement.activated = true;
    }

    enrichedElement.addEventListener('click', () => {
      this.dispatchEvent(
        new CustomEvent('list-item-clicked', {
          detail: {
            item: item,
          },
        })
      );
    });
    return enrichedElement;
  }

  protected handleListItemTemplate(e: Event) {
    const target = e.target as HTMLSlotElement;
    const childNodes = target?.assignedNodes({ flatten: true });
    this.listItemTemplate = childNodes[0].cloneNode(true);
  }
}

const name = 'fox-list-extended';

if (!customElements.get(name)) {
  customElements.define(name, FoxListExtended);
} else {
  console.warn(`${name} is already defined`);
}
