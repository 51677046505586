import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { cloneDeep } from 'lodash-es';

import { PageFormConfiguration } from '@fox/shared-util-forms';
import {
  DASHBOARD_TYPE_ENUM,
  DashboardConfiguration,
  PageConfiguration,
} from '@fox/shared/models';

import { PageElementComponentModule } from './page-element/page-element.component';
import { PageElementForInsertion } from './page-element/page-element.model';
import {
  addElementAsSiblingTo,
  createPageElementFromElementAndParentType,
  removeFromPreviousParent,
  repositionElement,
} from './page-element/page-element.utils';

@Component({
  selector: 'fox-drag-grid-builder',
  templateUrl: './drag-grid-builder.component.html',
  styleUrls: ['./drag-grid-builder.component.scss'],
})
export class DragGridBuilderComponent {
  @Input()
  set page(page: PageConfiguration<DashboardConfiguration>) {
    if (page?.detailConfiguration) {
      this.pageConfiguration = page;
      this.configuration = cloneDeep(page.detailConfiguration);
    }
  }

  @Input()
  isDragging = false;

  @Input()
  formGroup!: FormGroup<PageFormConfiguration>;

  isDraggingInside?: DashboardConfiguration;

  elementRegisteredForInsertion?: PageElementForInsertion;

  configuration!: DashboardConfiguration;

  pageConfiguration!: PageConfiguration<DashboardConfiguration>;

  get hasChildren(): boolean {
    return !!this.configuration?.children?.length;
  }

  onDrop($event: CdkDragDrop<DashboardConfiguration>) {
    let dashboardConfiguration: DashboardConfiguration;

    if (this.configuration?.children?.length === 0) {
      dashboardConfiguration = createPageElementFromElementAndParentType(
        $event.item.data.element,
        this.configuration.id,
        this.configuration.type ?? DASHBOARD_TYPE_ENUM.Column
      );

      this.updateDetailConfiguration({
        ...this.configuration,
        children: [dashboardConfiguration],
      });

      return;
    } else if (!this.elementRegisteredForInsertion?.parent) {
      return;
    } else {
      const parent = this.elementRegisteredForInsertion.parent;
      let sibling = this.elementRegisteredForInsertion.element;
      const side = this.elementRegisteredForInsertion.side;

      if (!sibling?.id) {
        sibling = createPageElementFromElementAndParentType(
          parent.element,
          parent.id,
          parent.type
        );
        parent.element = undefined;
        parent.children = [sibling];
      }

      if ($event.previousContainer.id === 'sidebar-list') {
        dashboardConfiguration = createPageElementFromElementAndParentType(
          $event.item.data.element,
          parent.id,
          parent.type
        );
        addElementAsSiblingTo(sibling, dashboardConfiguration, parent, side);
      } else if (
        $event.item.data.parentGuid !==
        this.elementRegisteredForInsertion.parent?.id
      ) {
        // remove from previous parent
        dashboardConfiguration = $event.item.data;
        if (this.isDraggingInside) {
          removeFromPreviousParent(
            dashboardConfiguration,
            this.isDraggingInside
          );
        }
        addElementAsSiblingTo(sibling, dashboardConfiguration, parent, side);
      } else {
        // rearrange in same parent
        dashboardConfiguration = $event.item.data;
        repositionElement(sibling, dashboardConfiguration, parent, side);
      }

      this.updateDetailConfiguration(this.configuration);
    }

    this.elementRegisteredForInsertion = undefined;
    this.isDraggingInside = undefined;
  }

  onRegisterPageElementForInsertion(element: PageElementForInsertion) {
    this.elementRegisteredForInsertion = element;
  }

  onDragStartedInside(parent: DashboardConfiguration) {
    this.isDraggingInside = parent;
    this.isDragging = true;
  }

  onDragEnded() {
    this.isDragging = false;
  }

  updateDetailConfiguration(configuration: DashboardConfiguration) {
    this.configuration = configuration;
    this.formGroup?.get('detailConfiguration')?.patchValue(configuration);
  }
}

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    PageElementComponentModule,
  ],
  declarations: [DragGridBuilderComponent],
  exports: [DragGridBuilderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DragGridBuilderComponentModule {}
