import { DashboardConfiguration } from '@fox/shared/models';

export interface Rectangle {
  height: number;
  width: number;
}

export interface Coordinate {
  x: number;
  y: number;
}

export interface Triangle {
  corner1: Coordinate;
  corner2: Coordinate;
  corner3: Coordinate;
}

// eslint-disable-next-line no-shadow
export enum RectangleSide {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export interface PageElementForInsertion {
  side: RectangleSide;
  element: DashboardConfiguration | null;
  parent: DashboardConfiguration;
}
