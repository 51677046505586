import { html, LitElement } from 'lit';

import { styles } from './FoxKanbanBoard.css';

import './FoxKanbanColumn';
import './FoxKanbanSlice';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxKanbanBoard;
  }
}

export class FoxKanbanBoard extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  render() {
    return html`<slot></slot>`;
  }
}

const name = 'fox-kanban-board';

if (!customElements.get(name)) {
  customElements.define(name, FoxKanbanBoard);
} else {
  console.warn(`${name} is already defined`);
}
