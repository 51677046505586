/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/directive-selector,@angular-eslint/no-host-metadata-property */
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Directive,
  forwardRef,
  NgModule,
  StaticProvider,
} from '@angular/core';
import {
  DefaultValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

export const TEXTFIELD_VALUE_ACCESSOR: StaticProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FoxTextfieldControlValueAccessor),
  multi: true,
};

@Directive({
  selector: 'fox-textarea,fox-textfield',
  host: {
    '(input)': '$any(this)._handleInput($event.target.value)',
    '(blur)': 'onTouched()',
    '(compositionstart)': '$any(this)._compositionStart()',
    '(compositionend)': '$any(this)._compositionEnd($event.target.value)',
  },
  providers: [TEXTFIELD_VALUE_ACCESSOR],
})
export class FoxTextfieldControlValueAccessor extends DefaultValueAccessor {}

@NgModule({
  declarations: [FoxTextfieldControlValueAccessor],
  exports: [FoxTextfieldControlValueAccessor],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TextfieldControlDirectiveModule {}
