import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  FoxCombobox,
  FoxComboBoxInputChanged,
  FoxComboboxItem,
  FoxComboBoxSelectedItemChanged,
  FoxComboBoxValueChanged,
} from '@assecosolutions/fox-combobox';

import { BaseControlValueAccessor } from './base-control-value-accessor';

@Component({
  selector: 'fox-combobox-control',
  template: `
    <fox-combobox
      fixedMenuPosition
      #combobox
      [allowCustomValue]="allowCustomValue"
      [label]="label"
      [items]="items"
      [itemLabelPath]="itemLabelPath"
      [itemValuePath]="itemValuePath"
      [value]="value"
      [selectedItem]="selectedItem"
      [hidden]="hidden"
      [opened]="opened"
      [loading]="loading"
      [required]="required"
      (selected-item-changed)="onSelectedItemChanged($event)"
      (value-changed)="onValueChangeEvent($event)"
      (input-changed)="onInputChanged($event)"
      (blur)="onTouched()"
    >
      <ng-content></ng-content>
    </fox-combobox>
  `,
  styles: [
    `
      :host,
      fox-combobox {
        width: 100%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ComboboxControlComponent,
      multi: true,
    },
  ],
})
export class ComboboxControlComponent extends BaseControlValueAccessor {
  @Input()
  items: unknown[] = [];

  @Input()
  label = '';

  @Input()
  selectedItem?: FoxComboboxItem;

  @Input()
  itemLabelPath = '';

  @Input()
  itemValuePath = '';

  @Input()
  allowCustomValue = false;

  @Input()
  hidden = false;

  @Input()
  opened = false;

  @Input()
  required = false;

  @Input()
  loading = false;

  @Output()
  inputChanged = new EventEmitter();

  @Output()
  itemChanged = new EventEmitter();

  @ViewChild('combobox', { read: ElementRef })
  combobox: ElementRef<FoxCombobox> | null = null;

  onInputChanged(event: FoxComboBoxInputChanged) {
    this.inputChanged.emit(event);
  }

  onSelectedItemChanged(event: FoxComboBoxSelectedItemChanged<unknown>) {
    this.itemChanged.emit(event.detail.item);
    this.onChange(event.detail.item);
  }

  onValueChangeEvent(event: FoxComboBoxValueChanged) {
    if (this.selectedItem !== event.detail.value && this.itemValuePath) {
      this.onChange(event.detail.value);
    }
  }
}

@NgModule({
  declarations: [ComboboxControlComponent],
  exports: [ComboboxControlComponent],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComboboxControlComponentModule {}
