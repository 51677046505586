import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxButton.css';

import '@assecosolutions/fox-icon';
import '@assecosolutions/fox-progress';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxButton;
  }
}

/**
 * This is a Button
 *
 * @element fox-button
 *
 * @slot icon - For manual  icon placement if wanted
 * @slot trailingIcon - For manual  trailingIcon placement if wanted
 *
 * @cssprop [--fox-button-font-size=14px]
 * @cssprop [--fox-button-font-family="Nunito, Roboto, sans-serif"]
 * @cssprop [--fox-button-font-weight=600]
 * @cssprop [--fox-button-text-color="var(--fox-base-grey-100-color, #0f1310)"]
 * @cssprop [--fox-button-primary-color="var(--fox-base-grey-30-color, #e7ecf0)"]
 * @cssprop [--fox-button-text-on-primary-color="var(--fox-base-grey-100-color, #0f1310)"]
 */
export class FoxButton extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: Boolean, reflect: true }) small = false;

  @property({ type: Boolean, reflect: true }) primary = false;

  @property({ type: Boolean, reflect: true }) raised = false;

  @property({ type: Boolean, reflect: true }) outlined = false;

  @property({ type: Boolean, reflect: true }) disabled = false;

  @property({ type: Boolean, attribute: 'trailingicon' }) trailingIcon = false;

  @property({ type: Boolean, reflect: true }) fullwidth = false;

  @property({ type: Boolean, reflect: true }) justifyStart = false;

  @property({ type: String }) icon = '';

  @property({ type: String }) label = '';

  @property({ type: Boolean }) progress = false;

  @property({ type: String }) type?: 'submit' | 'reset';

  protected render() {
    const classes = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fox-button__raised': this.raised,
      'fox-button__outlined': this.outlined,
      'fox-button__icon-button': !this.label,
    };
    return html`
      <button
        id="button"
        class="fox-button ${classMap(classes)}"
        ?disabled="${this.disabled}"
        aria-label="${this.label || this.icon}"
        @click="${this.onClick}"
      >
        <span class="leading-icon">
          <slot name="icon">
            ${this.icon && !this.trailingIcon ? this.renderIcon() : ''}
          </slot>
        </span>
        <span class="fox-button__label"> ${this.label} </span>
        <span class="trailing-icon">
          <slot name="trailingIcon">
            ${this.icon && this.trailingIcon ? this.renderIcon() : ''}
          </slot>
        </span>
        ${this.progress ? this.renderProgress() : ''}
      </button>
    `;
  }

  protected renderIcon() {
    return html` <fox-icon class="fox-button__icon"> ${this.icon}</fox-icon>`;
  }

  protected renderProgress() {
    return html` <fox-linear-progress
      indeterminate
      class="fox-button__progress"
    ></fox-linear-progress>`;
  }
  protected onClick() {
    if (this.type) {
      const form: HTMLFormElement | null | undefined =
        this.parentElement?.closest('Form');

      if (this.type === 'submit' && form) {
        form.requestSubmit();
      } else if (this.type === 'reset' && form) {
        form.reset();
      }
    }
  }
}

const name = 'fox-button';

if (!customElements.get(name)) {
  customElements.define(name, FoxButton);
} else {
  console.warn(`${name} is already defined`);
}
