import { startCase as lodashStartCase } from 'lodash-es';

import { HasId, isNil, StringRecord } from '@assecosolutions/fox-common-utils';

export const startCase = (value: string): string => {
  return lodashStartCase(value);
};

export const removeRedundantSlashes = (url: string): string => {
  return isNil(url) ? url : url.replace(/(https?:\/\/)|(\/)+/g, '$1$2');
};

export const urlSearchParams = (urlOrEndpoint: string): StringRecord => {
  try {
    const search = urlOrEndpoint.split('?')[1] ?? '';
    return Object.fromEntries(new URLSearchParams(search));
  } catch {
    return {};
  }
};

export const compareById = (
  item1: { _id: string },
  item2: { _id: string }
): boolean => {
  return item1?._id === item2?._id;
};

export const trackById = <T extends HasId>(_: number, item: T): string | T => {
  return item._id;
};
