import { css, html, LitElement } from 'lit';

export class FoxNotificationContainer extends LitElement {
  /**
   * @ignore
   */
  static styles = css`
    :host {
      display: block;
      position: absolute;
      right: 0;
      overflow: hidden;
      top: 0;
    }
  `;

  render() {
    return html`<slot></slot> `;
  }
}

export const FOX_NOTIFICATION_CONTAINER = 'fox-notification-container';

if (!customElements.get(FOX_NOTIFICATION_CONTAINER)) {
  customElements.define(FOX_NOTIFICATION_CONTAINER, FoxNotificationContainer);
} else {
  console.warn(`${FOX_NOTIFICATION_CONTAINER} is already defined`);
}
