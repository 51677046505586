import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  NOTIFICATIONS_FEATURE_KEY,
  notificationsAdapter,
  State,
} from './notifications.reducer';

// Lookup the 'Notifications' feature state managed by NgRx
export const getNotificationsState = createFeatureSelector<State>(
  NOTIFICATIONS_FEATURE_KEY
);

const { selectAll } = notificationsAdapter.getSelectors();

export const selectAllNotifications = createSelector(
  getNotificationsState,
  (state: State) => selectAll(state)
);
