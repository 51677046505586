<fox-content-layer>
  <div
    slot="body"
    cdkDropList
    cdkDropListSortingDisabled
    [cdkDropListConnectedTo]="'sidebar-list'"
    (cdkDropListDropped)="onDrop($event)"
    id="drag-grid"
  >
    <div class="container py-4">
      <fox-page-element
        *ngIf="hasChildren; else noChildren"
        [element]="configuration"
        [isEditable]="true"
        [isDragging]="isDragging"
        (dragStartedInside)="onDragStartedInside($event)"
        (dragEnded)="onDragEnded()"
        (registerPageElementForInsertion)="
          onRegisterPageElementForInsertion($event)
        "
      ></fox-page-element>
      <ng-template #noChildren>
        <div class="placeholder-for-no-children"></div>
      </ng-template>
    </div>
  </div>
</fox-content-layer>
