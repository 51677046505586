import { isPlainObject as lodashIsPlainObject } from 'lodash-es';

import { Nil, Primitive } from './types';

// Primitives

export const isNull = (v: unknown): v is null => {
  return v === null;
};

export const isUndefined = (v: unknown): v is undefined => {
  return v === undefined;
};

export const isString = (v: unknown): v is string => {
  return typeof v === 'string';
};

export const isBoolean = (v: unknown): v is boolean => {
  return typeof v === 'boolean';
};

export const isNumber = (v: unknown): v is number => {
  return typeof v === 'number';
};

export const isBigInt = (v: unknown): v is bigint => {
  return typeof v === 'bigint';
};

// Object-ish

export const isDate = (v: unknown): v is Date => {
  return v instanceof Date;
};

export const isObject = (v: unknown): v is Record<string, unknown> => {
  return typeof v === 'object' && v !== null && !isArray(v);
};

export const isPlainObject = (v: unknown): v is Record<string, unknown> => {
  return lodashIsPlainObject(v);
};

export const isArray = (v: unknown): v is Array<unknown> => {
  return Array.isArray(v);
};

// Combined

export const isNil = (v: unknown): v is Nil => {
  return v === undefined || v === null;
};

export const isNotNil = <T>(v: T | Nil): v is T => {
  return !isNil(v);
};

export const isPrimitive = (v: unknown): v is Primitive => {
  return isString(v) || isBoolean(v) || isNumber(v) || isBigInt(v);
};
