import { Component, TemplateRef, ViewChild } from '@angular/core';

export interface DialogBase {
  slotTemplateRef: TemplateRef<unknown> | null;
}

@Component({ template: '' })
export class DialogBaseComponent implements DialogBase {
  @ViewChild('slotTemplate', { read: TemplateRef })
  slotTemplateRef: TemplateRef<unknown> | null = null;
}
