import { Injectable } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ReplaySubject, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import {
  ElementAction,
  TableConfiguration,
} from '@assecosolutions/fox-common-models';
import {
  DisplayTypeMap,
  displayTypeMapProvider,
  DisplayTypes,
  toDisplayTypes,
  toSelectableFields,
  transformKeysOf,
} from '@fox/shared-util-element-configurator';
import { RequestExecutorService } from '@fox/shared-util-request-executor';
import {
  Element,
  FoxConfiguration,
  Parameter,
} from '@fox/shared/api-administration';
import {
  CreateElementConfiguration,
  DataTransformationConfiguration,
  ElementConfiguration,
  ElementRequestConfiguration,
} from '@fox/shared/models';
import { AutoUnsubscribe } from '@fox/shared/util-rxjs';

import { combineRequestInputLists, ControlsOf } from './utils';

export type ElementFormConfiguration = ControlsOf<
  Omit<CreateElementConfiguration, 'requestConfiguration'>
> & {
  requestConfiguration?: FormGroup;
  detailConfiguration?: FormGroup;
  actions?: FormControl;
};

export interface ElementFormRequestConfiguration {
  request: FormControl;
  dataTransformation: FormGroup<{
    filter: FormArray;
    order: FormArray;
    group: FormArray;
  }>;
  requestInputList: FormArray;
}

@Injectable({ providedIn: 'root' })
export class ElementBuilderFormService extends AutoUnsubscribe {
  requestData$ = new ReplaySubject(1);

  selectableFields$ = new ReplaySubject<string[]>(1);

  displayTypeMap$ = new ReplaySubject<DisplayTypeMap>(1);

  displayTypes$ = new ReplaySubject<DisplayTypes>(1);

  elementConfiguration?: Element;

  formGroup = new FormGroup<ElementFormConfiguration>({
    name: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    //the UI will override the value anyway, we set table as default.
    type: new FormControl<Element.TypeEnum>(Element.TypeEnum.Table, {
      validators: Validators.required,
      nonNullable: true,
    }),
    description: new FormControl<string | undefined>('', {
      nonNullable: true,
    }),
    requestConfiguration: this.requestConfigurationFormGroup(),
    actions: new FormControl([]),
    categories: new FormControl(
      {
        value: [],
        disabled: true, //disable until we decide on a solution
      },
      {
        nonNullable: true,
      }
    ),
  });

  constructor(private requestExecutorService: RequestExecutorService) {
    super();

    this.subscribeTo(this.prepareObservables());
  }

  get tableConfiguration(): ElementConfiguration<TableConfiguration> {
    return <ElementConfiguration<TableConfiguration>>(
      this.formGroup.getRawValue()
    );
  }

  get state(): FoxConfiguration.StateEnum | undefined {
    return this.elementConfiguration?.state;
  }

  get formGroupElementActions() {
    return this.formGroup.get('actions');
  }

  get formGroupElementRequestConfiguration() {
    return this.formGroup.get(
      'requestConfiguration'
    ) as FormGroup<ElementFormRequestConfiguration>;
  }

  get detailConfiguration() {
    return this.formGroup.get('detailConfiguration') as FormGroup;
  }

  prepareObservables() {
    return this.formGroupElementRequestConfiguration?.valueChanges.pipe(
      map((requestConfiguration) => requestConfiguration.request),
      distinctUntilChanged(),
      filter((request) => !!request),
      switchMap((request) =>
        this.requestExecutorService.execute(request).pipe(
          tap({
            next: (data) => {
              const fields = transformKeysOf(data);
              const map = displayTypeMapProvider(toSelectableFields(fields));
              this.selectableFields$.next(fields);
              this.requestData$.next(data);
              this.displayTypeMap$.next(map);
              this.displayTypes$.next(toDisplayTypes(map));
              this.initRequestInputListFormArray(request?.requestInputList);
            },
          })
        )
      )
    );
  }

  patchFormValues(element: Element) {
    this.elementConfiguration = element;
    this.formGroup.patchValue(element);

    this.initDataTransformationFormArrays();
  }

  mergedElementConfigurationWithFormValues() {
    return {
      ...this.elementConfiguration,
      ...this.formGroup.getRawValue(),
    } as Element;
  }

  patchElementAction(elementActions: ElementAction[]) {
    this.formGroupElementActions?.patchValue(elementActions);
  }

  setDetailConfiguration(formGroup: FormGroup) {
    this.formGroup.setControl('detailConfiguration', formGroup, {
      emitEvent: false,
    });
  }

  reset() {
    this.formGroup.removeControl('detailConfiguration');
    this.formGroup.reset();

    this.elementConfiguration = undefined;
  }

  private requestConfigurationFormGroup(): FormGroup {
    return new FormGroup<ElementFormRequestConfiguration>({
      request: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      requestInputList: new UntypedFormArray([]),
      dataTransformation: new FormGroup({
        filter: new UntypedFormArray([]),
        order: new UntypedFormArray([]),
        group: new UntypedFormArray([]),
      }),
    });
  }

  /* for request */

  private initRequestInputListFormArray(requestInputList: Parameter[]) {
    const requestInputListForm = this.formGroup
      ?.get('requestConfiguration')
      ?.get('requestInputList') as FormArray;

    while (requestInputListForm?.controls?.length > 0) {
      requestInputListForm.removeAt(0, {
        emitEvent: false,
      });
    }
    const requestConfiguration = this.elementConfiguration
      ?.requestConfiguration as ElementRequestConfiguration;

    const combinedRequestInputs = combineRequestInputLists(
      requestInputList,
      requestConfiguration?.requestInputList
    );

    const formArray = new UntypedFormArray([]);

    combinedRequestInputs.forEach((input) => {
      const formGroup = this.emptyInputListControl();
      formGroup.patchValue(input);
      formArray.controls.push(formGroup);
      formArray['_registerControl'](formGroup);
    });

    this.formGroupElementRequestConfiguration.setControl(
      'requestInputList',
      formArray,
      {
        emitEvent: false,
      }
    );
  }

  private initDataTransformationFormArrays() {
    const dataTransformationFormGroup =
      this.formGroupElementRequestConfiguration?.get(
        'dataTransformation'
      ) as FormGroup;

    const requestConfiguration = this.elementConfiguration
      ?.requestConfiguration as ElementRequestConfiguration;
    const dataTransformation = requestConfiguration?.dataTransformation;

    if (dataTransformation) {
      Object.entries(dataTransformation).forEach(([name, configs]) => {
        const formArray = new UntypedFormArray([]);

        (configs as DataTransformationConfiguration<unknown>[]).forEach(
          (config) => {
            const formGroup =
              this.emptyDataTransformationConfigurationControl();
            formGroup.patchValue(config);
            formArray.controls.push(formGroup);
            formArray['_registerControl'](formGroup);
          }
        );

        dataTransformationFormGroup.setControl(name, formArray, {
          emitEvent: false,
        });
      });
    }
  }

  private emptyInputListControl = () => {
    return new UntypedFormGroup({
      value: new UntypedFormControl(''),
      key: new UntypedFormControl('', Validators.required),
    });
  };

  emptyDataTransformationConfigurationControl = () => {
    return new UntypedFormGroup({
      name: new UntypedFormControl(''),
      configuration: new UntypedFormControl({}),
    });
  };
}
