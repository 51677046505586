import { Guid } from 'guid-typescript';

import { FoxNotificationPayload } from '@fox/shared-util-ngrx';
import { FoxNotification } from '@fox/shared/models';

export const createNotification = (
  notificationPayload: FoxNotificationPayload
): FoxNotification => {
  return {
    ...notificationPayload,
    timestamp: Date.now(),
    guid: Guid.create().toString(),
    duration: durationMap[notificationPayload.type],
  };
};

const durationMap = {
  error: 7000,
  warning: 7000,
  success: 3000,
  info: 4000,
};
