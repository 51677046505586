import { groupBy, orderBy } from 'lodash-es';

import {
  ElementAction,
  ElementActionTargetEnum,
  ElementActionTriggerEnum,
  ElementActionTriggerEvent,
} from '@assecosolutions/fox-common-models';
import { isArray, isNotNil, isObject } from '@assecosolutions/fox-common-utils';
import { isNotEmpty } from '@assecosolutions/fox-common-utils';
import {
  buildConditionString,
  executeCondition,
} from '@assecosolutions/fox-condition-executor';
import { ElementConfiguration, ElementTypes } from '@fox/shared/models';

export const orderDataByRequestConfiguration = (
  data: unknown,
  configuration: ElementConfiguration
) => {
  const order = configuration?.requestConfiguration?.dataTransformation?.order;
  if (
    isNotNil(order) &&
    isArray(data) &&
    configuration?.type !== ElementTypes.ELEMENT_TYPE_TABLE
  ) {
    const orderObject = order.reduce((map, o) => {
      return {
        ...map,
        [o.configuration.path]: o.configuration.direction,
      };
    }, {});

    data = orderBy(data, Object.keys(orderObject), Object.values(orderObject));
  }
  return data;
};

export const groupDataByRequestConfiguration = (
  data: unknown,
  configuration: ElementConfiguration
) => {
  const group = configuration?.requestConfiguration?.dataTransformation?.group;
  let groupedData = data;
  if (
    isNotNil(group) &&
    isArray(groupedData) &&
    configuration?.type !== ElementTypes.ELEMENT_TYPE_TABLE //remove this when we have group tables
  ) {
    group.forEach(
      (g) =>
        (groupedData = groupBy(<unknown[]>groupedData, g.configuration.path))
    );
  }
  return groupedData;
};

export const filterDataByRequestConfiguration = (
  data: unknown,
  configuration: ElementConfiguration
) => {
  const filters =
    configuration?.requestConfiguration?.dataTransformation?.filter;

  if (isNotEmpty(filters) && isNotNil(filters)) {
    filters.map((filter) => {
      //parameter can be set inside the client UI (smart-filter feature)
      if (filter.inactive) {
        return;
      }

      if (isArray(data)) {
        const conditionString = buildConditionString(filter.configuration);
        data = data.filter((o) => {
          if (isObject(o)) {
            return executeCondition(conditionString, o);
          }
        });
      }
    });
  }
  return data;
};

/** action  **/
export const filterElementActionsByEvent = (
  event: ElementActionTriggerEvent,
  configuration: ElementConfiguration
): ElementAction[] => {
  if (!configuration?.actions) {
    return [];
  }

  return configuration?.actions.filter(
    (elementAction: ElementAction) =>
      event.trigger &&
      elementAction.trigger === event.trigger &&
      event.target &&
      elementAction.target === event.target
  );
};

export const menuIcon = (
  configuration: ElementConfiguration,
  target: ElementActionTargetEnum
): 'keyboard_arrow_right' | 'more_vert' | undefined => {
  const filteredActions = filterElementActionsByEvent(
    {
      trigger: ElementActionTriggerEnum.LEFT_CLICK,
      target: target,
    },
    configuration
  );

  if (filteredActions.length === 1) {
    return 'keyboard_arrow_right';
  } else if (filteredActions.length >= 1) {
    return 'more_vert';
  }
  return undefined;
};
