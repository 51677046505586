import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IconSelectedEvent } from '@assecosolutions/fox-icon-grid';

import { BaseControlValueAccessor } from './base-control-value-accessor';

@Component({
  selector: 'fox-icon-selection-control',
  template: `
    <fox-icon-grid
      class="py-3"
      (icon-selected)="onChange($event)"
      [selectedIcon]="value"
    ></fox-icon-grid>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: IconSelectionControlComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSelectionControlComponent extends BaseControlValueAccessor {
  @Input()
  icons: string[] = [];

  onChange(event: IconSelectedEvent<string>) {
    super.onChange(event.detail.item);
    this.value = event.detail.item;
  }
}

@NgModule({
  declarations: [IconSelectionControlComponent],
  exports: [IconSelectionControlComponent],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IconSelectionControlComponentModule {}
