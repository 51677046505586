import {
  isArray,
  isObject,
  isPlainObject,
  UnknownRecord,
} from '../strict-mode';
import {
  DEFAULT_KEYS_OF_OPTS,
  KeysOfOpts,
  DEFAULT_FLATTEN_OPTS,
  FlattenOpts,
  ObjectVisitorOpts,
} from './object-visitor.model';

export const keysOf = (
  value: unknown,
  opts: KeysOfOpts = DEFAULT_KEYS_OF_OPTS
): Array<string> => {
  const paths = new Set<string>();
  const visitFn = (key: string, _: unknown) => paths.add(key);
  visitObject(value, null, opts, visitFn);
  return Array.from(paths);
};

export const flatten = (
  object: UnknownRecord,
  opts: FlattenOpts = DEFAULT_FLATTEN_OPTS
): UnknownRecord => {
  const flattened: UnknownRecord = {};
  const visitFn = (key: string, value: unknown) => (flattened[key] = value);
  visitObject(object, null, opts, visitFn);
  return flattened;
};

export const visitObject = (
  value: unknown,
  parentKey: string | null = null,
  opts: ObjectVisitorOpts,
  visitFn: (key: string, value: unknown) => void
) => {
  if (isPlainObject(value)) {
    Object.keys(value).forEach((key) => {
      const path = parentKey ? [parentKey, key].join('.') : key;
      const item = value[key];

      if (isPlainObject(item)) {
        opts.includeIntermediateKeys && visitFn(path, item);
        visitObject(item, path, opts, visitFn);
      } else if (isArray(item)) {
        opts.includeArrayLeafs && visitFn(path, item);
      } else if (isObject(item)) {
        opts.includeObjectLeafs && visitFn(path, item);
      } else {
        visitFn(path, item);
      }
    });
  }
};
