import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
  DashboardConfiguration,
  PageConfiguration,
  SpaConfiguration,
} from '@fox/shared/models';

export interface PageExecutorState {
  configuration: PageConfiguration;
}

@Injectable()
export class PageExecutorStore extends ComponentStore<PageExecutorState> {
  readonly configuration$: Observable<PageConfiguration> = this.select(
    (state) => state.configuration
  );

  readonly type$ = this.select((state) => state?.configuration?.type);

  readonly noDetailConfiguration$ = this.select(
    (state) => !state?.configuration?.detailConfiguration
  );

  readonly spaConfiguration$ = this.configuration$ as Observable<
    PageConfiguration<SpaConfiguration>
  >;
  readonly dashboardConfiguration$ = this.configuration$ as Observable<
    PageConfiguration<DashboardConfiguration>
  >;

  setPageExecutorState(state: PageExecutorState) {
    this.setState(state);
  }
}
