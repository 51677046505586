import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  HostListener,
  Input,
  NgModule,
  OnChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { NgChanges } from '@assecosolutions/ng-common';
import { isValidUrl } from '@fox/shared-ui-forms';
import { SpaConfiguration } from '@fox/shared/models';

@Component({
  selector: 'fox-spa-executor',
  templateUrl: './spa-executor.component.html',
  styleUrls: ['./spa-executor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaExecutorComponent implements OnChanges {
  @Input()
  configuration?: SpaConfiguration;

  iframeUrl?: SafeUrl;
  loading = true;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnChanges(changes: NgChanges<SpaExecutorComponent>): void {
    const path = this.configuration?.path;

    const queryIframeUrl = this.route.snapshot.queryParams['iframeUrl'];
    if (queryIframeUrl) {
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.route.snapshot.queryParams['iframeUrl']
      );
    } else if (
      path &&
      isValidUrl(path) &&
      changes.configuration?.currentValue?.path !==
        changes.configuration?.previousValue?.path
    ) {
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(path);
    }
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    const url = event.data?.location?.href;
    const iframeUrl = this.route.snapshot.queryParams['iframeUrl'];

    if (url && url !== iframeUrl) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        replaceUrl: true,
        queryParams: { iframeUrl: decodeURI(url) },
      });
    }
  }

  onLoad(e: Event) {
    if ((<HTMLIFrameElement>e?.target)?.src) {
      this.loading = false;
    }
  }

  onError(e: Event) {
    //TODO: well i need some error handling
    console.error(e);
    this.loading = false;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [SpaExecutorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SpaExecutorComponent],
})
export class SpaExecutorComponentModule {}
