import { assertNotNil } from './assert';

/**
 *
 * - Ensure -
 *
 * Take a parameter, internally apply assertions and return value with narrowed type.
 * Use when the value's type absolutely needs to be narrowed and other cases are errors.
 */

/**
 * Ensure that value is not `null` | `undefined`.
 *
 * @param thing | `null` | `undefined`
 * @returns thing
 * @throws error if thing is `null` | `undefined`
 */
export const ensureNotNil = <T>(thing: T | null | undefined): T => {
  assertNotNil(thing);
  return thing;
};
