import { CommonModule } from '@angular/common';
import {
  Directive,
  HostBinding,
  Input,
  NgModule,
  Optional,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'label[controlName]',
})
export class LabelControlDirective {
  @Input()
  controlName = '';

  constructor(@Optional() private parent: ControlContainer) {}

  @HostBinding('textContent')
  get controlValue() {
    return this.parent?.control?.get(this.controlName)?.value ?? '';
  }
}

@NgModule({
  declarations: [LabelControlDirective],
  imports: [CommonModule],
  exports: [LabelControlDirective],
})
export class LabelControlDirectiveModule {}
