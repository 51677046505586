import { ElementSettings } from './element-configuration.model';

export const CHART_TYPES = [
  'bar',
  'line',
  'scatter',
  'bubble',
  'pie',
  'doughnut',
  'polarArea',
  'radar',
] as const;
export type ChartTypes = typeof CHART_TYPES[number];

export interface ChartConfiguration {
  settings?: ElementSettings;
  type?: ChartTypes;
  dataSet?: ChartDataSetConfiguration;
}

export interface ChartDataSetConfiguration {
  path?: string; //x
  pathType?: string;
  yPath?: string;
  groupPath?: string;
}
