import { CustomItemEvent } from '@assecosolutions/fox-common-utils';

import { FoxNavigationBar, NAME } from './FoxNavigationBar';

export interface NavigationListItem {
  path?: string;
  icon?: string;
  name?: string;
  active?: boolean;
  slot?: string;
}
export type ListItemClicked<T> = CustomItemEvent<T>;

declare global {
  interface HTMLElementTagNameMap {
    [NAME]: FoxNavigationBar;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  interface HTMLElementEventMap {
    'list-item-clicked': ListItemClicked<any>;
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
}
