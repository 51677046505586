import { ControlValueAccessor } from '@angular/forms';

import { FoxCheckbox } from '@assecosolutions/fox-checkbox';
import { FoxComboBoxSelectedItemsChanged } from '@assecosolutions/fox-combobox';
import { FoxTextarea } from '@assecosolutions/fox-textarea';
import { FoxTextField } from '@assecosolutions/fox-textfield';

import {
  ControlOnChangeHandler,
  ControlOnTouchedHandler,
} from './base-control-value-accessor.model';

/* eslint-disable @typescript-eslint/no-empty-function */
export class BaseControlValueAccessor implements ControlValueAccessor {
  value: unknown = '';
  isDisabled = false;

  onChangeHandler: ControlOnChangeHandler = (_: unknown) => {};
  onTouchHandler: ControlOnTouchedHandler = () => {};

  writeValue(value: unknown): void {
    this.value = value || '';
  }

  registerOnChange(fn: ControlOnChangeHandler): void {
    this.onChangeHandler = fn;
  }

  onChange(value: unknown) {
    this.onChangeHandler(value);
  }

  registerOnTouched(fn: ControlOnTouchedHandler): void {
    this.onTouchHandler = fn;
  }

  onTouched() {
    this.onTouchHandler();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  //
  // Specific change handlers.
  //

  onTextAreaChangeEvent(event: Event) {
    this.onChange((event.target as FoxTextarea).value);
  }

  onTextFieldChangeEvent(event: Event) {
    this.onChange((event.target as FoxTextField).value);
  }

  onMultiSelectComboboxChangeEvent(
    event: FoxComboBoxSelectedItemsChanged<unknown>
  ) {
    this.onChange(event.detail.item);
  }

  onCheckboxChangeEvent(event: Event) {
    this.onChange((event.target as FoxCheckbox).checked);
  }
}
