import { TableColumnConfiguration } from '@assecosolutions/fox-common-models';
import { isNil, isObject } from '@assecosolutions/fox-common-utils';
import { primitiveToStringHandler } from '@assecosolutions/ng-common';

export function searchTableData<T>(
  fields: TableColumnConfiguration[],
  searchString: string,
  data: T[]
): T[] {
  const filters = searchString
    .trim()
    .split(' ')
    .map((filter) => new RegExp(filter, 'i'));

  const elementKeys = Object.values(fields)
    .map((field) => (field.hidden ? undefined : field.path))
    .filter((key): key is string => !isNil(key));

  return (data ?? []).filter((item) =>
    filters.some((filter) =>
      Object.values(elementKeys).some((key) => {
        if (isObject(item)) {
          return filter.test(primitiveToStringHandler.handle(item[key]));
        }
        return false;
      })
    )
  );
}
