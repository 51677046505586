import { CheckListItemBase } from '@material/mwc-list/mwc-check-list-item-base';
import { styles as controlStyle } from '@material/mwc-list/mwc-control-list-item.css';
import { styles as mwcStyles } from '@material/mwc-list/mwc-list-item.css';
import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { styles } from './FoxListItem.css';

export {
  GraphicType,
  RequestSelectedDetail,
} from '@material/mwc-list/mwc-list-item-base';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxCheckListItem;
  }
}

export class FoxCheckListItem extends CheckListItemBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, controlStyle, styles];

  render() {
    const checkboxClasses = {
      'mdc-deprecated-list-item__graphic': this.left,
      'mdc-deprecated-list-item__meta': !this.left,
    };

    const text = this.renderText();
    const graphic =
      this.graphic && this.graphic !== 'control' && !this.left
        ? this.renderGraphic()
        : html``;
    const meta = this.hasMeta && this.left ? this.renderMeta() : html``;
    const ripple = this.renderRipple();

    return html` ${ripple} ${graphic} ${this.left ? '' : text}
      <span class=${classMap(checkboxClasses)}>
        <fox-checkbox
          reducedTouchTarget
          tabindex=${this.tabindex}
          .checked=${this.selected}
          ?disabled=${this.disabled}
          @change=${this.onChange}
        >
        </fox-checkbox>
      </span>
      ${this.left ? text : ''} ${meta}`;
  }
}

const name = 'fox-check-list-item';

if (!customElements.get(name)) {
  customElements.define(name, FoxCheckListItem);
} else {
  console.warn(`${name} is already defined`);
}
