<fox-card style="height: 400px">
  <div slot="header" class="d-flex">
    <div class="p-3 pr-0">
      <p class="m-0 semi-bold">{{ name }}</p>
    </div>
    <div class="ml-auto d-flex pr-0">
      <div class="menu-wrapper">
        <fox-icon-button
          *ngIf="menuIcon"
          [icon]="menuIcon"
          (contextmenu)="onRightClick($event)"
          (click)="onLeftClick($event)"
        ></fox-icon-button>
      </div>
    </div>
  </div>
  <fox-chart
    *ngIf="type"
    [type]="type"
    [data]="chartData()"
    [options]="chartOptions"
  ></fox-chart>
</fox-card>
