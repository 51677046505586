import { html, LitElement } from 'lit';

import { styles } from './FoxCard.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxCard;
  }
}

/**
 *
 * FOX Card
 *
 * @element fox-card
 * The Card spacings must be handled inside the implementation to guarantee multiple use cases with the default component
 * @slot header - Optional slot for custom header content
 * @slot body - Default slot for content, if the card has a fixed height the body will be overflow-scroll
 * @slot footer - Optional slot for custom footer content, will be fixed if the card has a fixed height
 *
 * @cssprop [--fox-card-background-color=white] - Background color of a Card
 * @cssprop [--fox-card-box-shadow=0 1px 4px 0 rgb(21 27 38 / 8%)] - Box shadow of the Card
 * @cssprop [--fox-card-border-radius=6px] - Border radius of the Card
 * @cssprop [--fox-card-border=none] - Default border of the default Card
 *
 */
export class FoxCard extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  render() {
    return html`
      <div class="fox-card">
        <div class="fox-card__header">
          <slot name="header"></slot>
        </div>
        <div class="fox-card__body">
          <slot></slot>
          <slot name="body"></slot>
        </div>
        <div class="fox-card__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    `;
  }
}

const name = 'fox-card';

if (!customElements.get(name)) {
  customElements.define(name, FoxCard);
} else {
  console.warn(`${name} is already defined`);
}
