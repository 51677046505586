import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { FoxNotification } from '@fox/shared/models';

import * as NotificationsActions from './notifications.actions';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export type State = EntityState<FoxNotification>;

export interface NotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: State;
}

export const notificationsAdapter: EntityAdapter<FoxNotification> =
  createEntityAdapter<FoxNotification>({
    selectId: ({ guid }: FoxNotification) => guid,
  });

export const initialState: State = notificationsAdapter.getInitialState({});

const notificationsReducer = createReducer(
  initialState,
  on(NotificationsActions.addNotification, (state, { payload }) =>
    notificationsAdapter.addOne(payload, { ...state })
  )
);

export const reducer = (state: State | undefined, action: Action) =>
  notificationsReducer(state, action);
