import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { map, switchMap, takeWhile, tap, withLatestFrom } from 'rxjs/operators';

import {
  ChartConfiguration,
  IndicatorConfiguration,
  KanbanConfiguration,
  TableConfiguration,
} from '@assecosolutions/fox-common-models';
import { combineRequestInputLists } from '@fox/shared-util-forms';
import { RequestExecutorService } from '@fox/shared-util-request-executor';
import { Request } from '@fox/shared/api-administration';
import { ElementConfiguration } from '@fox/shared/models';

import { ElementExecutorOptions } from '../../element-executor.model';
import {
  filterDataByRequestConfiguration,
  groupDataByRequestConfiguration,
  orderDataByRequestConfiguration,
} from '../../element-executor.utils';

export interface ElementExecutorState {
  configuration: ElementConfiguration;
  options?: ElementExecutorOptions;
  disableActions?: boolean;
  loadDataByRequest?: boolean;
  data?: unknown;
}

@Injectable()
export class ElementExecutorStore extends ComponentStore<ElementExecutorState> {
  readonly configuration$: Observable<ElementConfiguration> = this.select(
    (state) => state.configuration
  );

  readonly data$ = this.select((state) => {
    return groupDataByRequestConfiguration(
      orderDataByRequestConfiguration(
        filterDataByRequestConfiguration(state.data, state.configuration),
        state.configuration
      ),
      state.configuration
    );
  });

  readonly elementType$ = this.select((state) => state?.configuration?.type);

  readonly noDetailConfiguration$ = this.select(
    (state) => !state?.configuration?.detailConfiguration
  );

  readonly tableConfiguration$ = this.configuration$ as Observable<
    ElementConfiguration<TableConfiguration>
  >;

  readonly chartConfiguration$ = this.configuration$ as Observable<
    ElementConfiguration<ChartConfiguration>
  >;

  readonly kanbanConfiguration$ = this.configuration$ as Observable<
    ElementConfiguration<KanbanConfiguration>
  >;

  readonly indicatorConfiguration$ = this.configuration$ as Observable<
    ElementConfiguration<IndicatorConfiguration>
  >;

  readonly options$: Observable<ElementExecutorOptions> = this.select(
    (state) => {
      return {
        ...state.configuration?.detailConfiguration?.settings,
        ...state.options,
        disableActions: state.disableActions,
      };
    }
  );

  readonly loadData = this.effect((loadData$: Observable<void>) => {
    return loadData$.pipe(
      takeWhile(() => this.get((state) => !!state.loadDataByRequest)),
      withLatestFrom(this.configuration$),
      map(([_, configuration]) => ({
        ...configuration.requestConfiguration.request,
        requestInputList: combineRequestInputLists(
          configuration.requestConfiguration.request.requestInputList || [],
          configuration.requestConfiguration.requestInputList
        ),
      })),
      switchMap((request: Request) =>
        this.requestExecutorService.execute(request).pipe(
          tap({
            next: (data) => this.updateData(data),
          })
        )
      )
    );
  });

  constructor(private requestExecutorService: RequestExecutorService) {
    super();
  }

  updateConfiguration(configuration: ElementConfiguration) {
    this.patchState({ configuration });
  }

  updateData(data: unknown) {
    this.patchState({ data });
  }

  setElementExecutorState(state: ElementExecutorState) {
    this.setState(state);
  }
}
