import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
} from '@angular/core';
import { groupBy, mapValues, toLower, upperFirst } from 'lodash-es';

import {
  ChartConfiguration,
  ChartTypes,
  ElementActionTargetEnum,
  ElementActionTriggerEnum,
} from '@assecosolutions/fox-common-models';
import {
  ensureNotNil,
  isArray,
  isObject,
  UnknownRecord,
} from '@assecosolutions/fox-common-utils';
import {
  foxFormulasMap,
  FoxFormulasTypes,
  sum,
} from '@assecosolutions/ng-formulas';
import { ElementConfiguration } from '@fox/shared/models';

import { ElementExecutorBaseComponent } from '../element-executor-base.component';
import { menuIcon } from '../element-executor.utils';

const colorScale = [
  '#4599ff',
  '#9e0142',
  '#d53e4f',
  '#f46d43',
  '#fdae61',
  '#fee08b',
  '#e6f598',
  '#abdda4',
  '#66c2a5',
  '#3288bd',
  '#5e4fa2',
  '#737373',
  '#f0f0f0',
  '#ffa500',
  '#ff2d37',
];

@Component({
  selector: 'fox-element-executor-chart',
  templateUrl: './element-executor-chart.component.html',
  styleUrls: ['./element-executor-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementExecutorChartComponent extends ElementExecutorBaseComponent {
  @Input()
  elementConfiguration?: ElementConfiguration<ChartConfiguration> | undefined;

  @Input()
  data: unknown;

  colorScale = colorScale;

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  get menuIcon() {
    if (!this.elementConfiguration) {
      return undefined;
    }
    return menuIcon(this.elementConfiguration, ElementActionTargetEnum.HEADER);
  }

  get name(): string | undefined {
    return this.elementConfiguration?.name;
  }

  get type(): ChartTypes | string {
    return this.elementConfiguration?.detailConfiguration?.type ?? '';
  }

  get path(): string {
    return this.elementConfiguration?.detailConfiguration?.dataSet?.path ?? '';
  }

  get pathType(): FoxFormulasTypes {
    return (this.elementConfiguration?.detailConfiguration?.dataSet?.pathType ??
      '') as FoxFormulasTypes;
  }

  get yPath(): string {
    return this.elementConfiguration?.detailConfiguration?.dataSet?.yPath ?? '';
  }

  get groupPath(): string {
    return (
      this.elementConfiguration?.detailConfiguration?.dataSet?.groupPath ?? ''
    );
  }

  onLeftClick(e: MouseEvent) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.LEFT_CLICK,
        target: ElementActionTargetEnum.HEADER,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );
  }

  onRightClick(e: MouseEvent) {
    this.triggerAction(
      {
        trigger: ElementActionTriggerEnum.RIGHT_CLICK,
        target: ElementActionTargetEnum.HEADER,
        mouseEvent: e,
      },
      ensureNotNil(this.elementConfiguration)
    );

    return false;
  }

  chartData() {
    const pathKeys = new Set();

    if (isArray(this.data)) {
      this.data.map((data) => isObject(data) && pathKeys.add(data[this.path]));
    }

    //todo: use some options for sorting to enable or even set some order (maybe inside request)
    const filteredPathKeys = Array.from(pathKeys.values())?.sort();

    return {
      datasets: this.chartDataSets(filteredPathKeys), //generate y axis
      labels: filteredPathKeys, //X axis
    };
  }

  filteredData(filteredPathKeys: unknown[]) {
    const filteredData = new Map();

    if (isArray(this.data)) {
      mapValues(groupBy(this.data, this.groupPath), (group, groupKey) => {
        const filteredDataKeys = new Map();
        filteredPathKeys.map((key) => {
          const groupedAndFilteredData = group.filter(
            (data) => isObject(data) && data[this.path] === key
          );
          const value: number | undefined = (
            foxFormulasMap[this.pathType] ? foxFormulasMap[this.pathType] : sum
          )(groupedAndFilteredData as UnknownRecord[], { path: this.yPath });
          filteredDataKeys.set(key, value);
        });
        filteredData.set(groupKey, filteredDataKeys);
      });
    }

    return filteredData;
  }

  //Y
  chartDataSets(filteredPathKeys: unknown[]) {
    const dataSetConfiguration: {
      label: string;
      data: unknown;
      backgroundColor: string | string[];
    }[] = [];

    this.filteredData(filteredPathKeys).forEach((data, key) => {
      data = Array.from(data.values());
      const label = upperFirst(
        toLower(key && key !== 'undefined' ? key : this.yPath)
      );
      switch (this.type) {
        case 'line':
        case 'bar':
          dataSetConfiguration.push({
            label: label,
            data: data,
            backgroundColor:
              this.colorScale[
                Math.floor(Math.random() * this.colorScale.length)
              ],
          });
          break;
        default:
          dataSetConfiguration.push({
            label: label,
            data: data,
            backgroundColor: this.colorScale,
          });
          break;
      }
    });

    return dataSetConfiguration;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ElementExecutorChartComponent],
  exports: [ElementExecutorChartComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ElementExecutorChartComponentModule {}
