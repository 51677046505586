import {
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';

export class FormControlWithProperties<T> extends UntypedFormControl {
  properties: T;
  constructor(
    formState: unknown,
    properties: T,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this.properties = properties;
  }
}
