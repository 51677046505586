import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
  OnChanges,
} from '@angular/core';

import { Page } from '@fox/shared/api-administration';
import { PageConfiguration } from '@fox/shared/models';

import {
  PageExecutorState,
  PageExecutorStore,
} from './+state/page-executor.store';
import { DashboardExecutorComponentModule } from './dashboard-executor/dashboard-executor.component';
import { SpaExecutorComponentModule } from './spa-executor/spa-executor.component';

@Component({
  selector: 'fox-page-executor',
  templateUrl: './page-executor.component.html',
  styleUrls: ['./page-executor.component.scss'],
  providers: [PageExecutorStore],
})
export class PageExecutorComponent implements OnChanges {
  @Input()
  configuration?: PageConfiguration;

  typeEnum = Page.TypeEnum;

  constructor(public store: PageExecutorStore) {}

  ngOnChanges(): void {
    if (!this.configuration) {
      return;
    }

    const state: PageExecutorState = {
      configuration: this.configuration,
    };

    this.store.setPageExecutorState(state);
  }
}

@NgModule({
  imports: [
    CommonModule,
    SpaExecutorComponentModule,
    DashboardExecutorComponentModule,
  ],
  declarations: [PageExecutorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [PageExecutorComponent],
})
export class PageExecutorComponentModule {}
