/**
 * Use at SPA Builder iframe pages to enable communication between fox and Iframes / SPA.
 * Note that if no origin is set (FOX URL), the postMessage will be sent to every parent that hosts the page (not what you want)
 * @param data
 * @param origin
 */
export const setIframePostMessage = (data?: unknown, origin = '*') => {
  if (parent) {
    const messageBody = {
      url: window.location.href,
      location: window.location,
      data,
    };
    parent.postMessage(JSON.parse(JSON.stringify(messageBody)), origin);
  }
};
