import '@vaadin/vaadin-grid/theme/material/vaadin-grid-column-group.js';
import { GridColumnGroupElement } from '@vaadin/vaadin-grid/src/vaadin-grid-column-group.js';

import { findHostGrid } from './FoxTableHelper';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-column-group': FoxTableColumnGroup;
  }
}

export class FoxTableColumnGroup extends GridColumnGroupElement {
  _findHostGrid() {
    return findHostGrid(this);
  }
}

window.customElements.define('fox-table-column-group', FoxTableColumnGroup);
