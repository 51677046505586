import { FoxIndicator, NAME } from './FoxIndicator';

declare global {
  interface HTMLElementTagNameMap {
    [NAME]: FoxIndicator;
  }
}

export type FoxIndicatorTemplateEnum = 'default' | 'test';
export type FoxIndicatorThemeEnum = 'default' | 'primary';
