/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ParameterRequest } from './parameterRequest';
import { DependencyRequest } from './dependencyRequest';


export interface CreateRequestRequest { 
    dependencies?: Array<DependencyRequest>;
    name: string;
    description: string;
    endpoint: string;
    method: CreateRequestRequest.MethodEnum;
    body: string;
    queryParameterList: Array<ParameterRequest>;
    headerParameterList: Array<ParameterRequest>;
    requestInputList: Array<ParameterRequest>;
    transform: string;
    isAction: boolean;
    dataSourceDependency: DependencyRequest;
}
export namespace CreateRequestRequest {
    export type MethodEnum = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    export const MethodEnum = {
        Get: 'GET' as MethodEnum,
        Post: 'POST' as MethodEnum,
        Put: 'PUT' as MethodEnum,
        Delete: 'DELETE' as MethodEnum,
        Patch: 'PATCH' as MethodEnum
    };
}


