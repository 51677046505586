import { html, nothing } from 'lit';
import { query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { templateContent } from 'lit/directives/template-content.js';
import { isArray, isEmpty, isEqual } from 'lodash-es';

import {
  customItemEvent,
  hasOverflow,
  StringRecord,
} from '@assecosolutions/fox-common-utils';

import { styles } from './FoxCombobox.css';
import { FoxComboboxItem } from './FoxCombobox.model';
import { FoxComboboxBase } from './FoxComboboxBase';

export class FoxMultiselectCombobox extends FoxComboboxBase {
  /**
   * @ignore
   */
  static styles = [styles];

  @query('.fox-combobox--badges') badgesContainer?: HTMLElement;

  /**
   * Variable to store the overflow count
   */
  @state() overflow = 0;

  /**
   * Variable to check if the last item should be deleted
   */
  @state() deleteLast = true;

  setAddItemFn = () => {
    this.addItemFn = (label) => {
      const newItem = this.areObjects ? { [this.itemLabelPath]: label } : label;
      this.items?.push(newItem);
      this.selectedItems = [...this.selectedItems, newItem];
      this.dispatchEvent(
        customItemEvent('custom-value-set', label, { element: this })
      );
    };
  };

  firstUpdated() {
    window.requestAnimationFrame(() => {
      this.addFloatingLabel();
    });
  }

  updated(changedProperties: Map<string, unknown>) {
    super.updated(changedProperties);
    if (changedProperties.has('overflow')) {
      setTimeout(() => {
        this.setOverflow();
      }, 5);
    }
    if (changedProperties.has('selectedItems')) {
      if (!isArray(this.selectedItems)) {
        return;
      }

      const nextItems = changedProperties.get(
        'selectedItems'
      ) as FoxComboboxItem[];

      if (isEqual(nextItems, this.selectedItems)) {
        return;
      }
      if (nextItems?.length > this.selectedItems?.length) {
        if (this.overflow > 0) {
          this.overflow--;
        }
      } else {
        setTimeout(() => {
          this.setOverflow();
        }, 0);
      }
      this.dispatchEvent(
        customItemEvent('selected-items-change', this.selectedItems, {
          element: this,
        })
      );
      this.dispatchEvent(
        customItemEvent(
          'values-changed',
          this.selectedItems?.map((item) => this.valueAccessorFn(item)),
          {
            element: this,
          }
        )
      );
    }
  }

  render() {
    return html`
      <fox-textfield
        @click="${this.openMenu}"
        @keyup="${(event: KeyboardEvent) => this.onTextFieldChange(event)}"
        @focusout="${() => this.onCloseMenu()}"
        ?disabled="${this.disabled}"
        ?required="${this.required}"
        label="${this.label}"
        placeholder="${this.placeholder}"
        validationMessage="${this.validationMessage}"
        pattern="${this.pattern}"
        maxLength="${ifDefined(this.maxLength)}"
        helper="${this.helperText}"
        @blur="${() => this.addFloatingLabel()}"
      >
        ${this.selectedItems?.length
          ? html`
              <div class="fox-combobox--badges" slot="badges">
                ${this.selectedItems
                  .slice(
                    0,
                    this.overflow > 0
                      ? this.selectedItems.length - this.overflow
                      : this.selectedItems.length
                  )
                  .map((item) =>
                    this.badgeTemplate
                      ? this.renderBadgeTemplate(item)
                      : this.renderBadge(item)
                  )}
              </div>
              ${this.overflow >= 1
                ? html` <fox-badge
                    class="fox-combobox--badge-overflow"
                    slot="badges"
                    >+${this.overflow}
                  </fox-badge>`
                : html` <div style="width: 5px" slot="badges"></div>`}
            `
          : nothing}
      </fox-textfield>
      ${this.renderToggle()} ${this.loading ? this.renderProgress() : nothing}
      ${this.menuOpen ? this.renderMenu() : nothing}
    `;
  }

  renderBadge(item: FoxComboboxItem) {
    return html`
      <fox-badge
        @click="${() => this.onItemClicked(item)}"
        class="fox-combobox--badge"
        icon="close"
        trailingIcon
        >${this.labelAccessorFn(item)}
      </fox-badge>
    `;
  }

  renderBadgeTemplate(item: FoxComboboxItem) {
    const copy = this.badgeTemplate?.cloneNode(true) as HTMLTemplateElement;
    copy.innerHTML = this.replaceWithObjectValue(
      copy.innerHTML,
      item as StringRecord
    );
    return html`${templateContent(copy)}`;
  }

  addFloatingLabel() {
    if (!this.selectedItems?.length) return;
    const floatingLabel = this.inputElement.shadowRoot?.querySelector(
      '#label'
    ) as HTMLSpanElement;
    if (floatingLabel) {
      floatingLabel.className =
        'mdc-floating-label mdc-floating--above mdc-floating-label--above mdc-floating-label--float-above';
    }
  }

  onCloseMenu() {
    super.onCloseMenu();
  }

  addItem(item: FoxComboboxItem) {
    if (this.selectedItems.includes(item)) {
      return;
    }
    this.selectedItems = [item, ...this.selectedItems];
  }

  removeItem(item: FoxComboboxItem) {
    this.selectedItems = this.selectedItems.filter((i) => i !== item);
  }

  scrollToListItem() {
    // Scroll to last selected item in list.
    if (!this.selectedItems || !this.scrollerElement) return;
    const index = this.items.findIndex((item) =>
      this.isEqualFn(item, this.selectedItems[0])
    );
    setTimeout(() => {
      const child = this.scrollerElement?.querySelector('fox-list-item');
      this.scrollerElement?.scrollTo({
        top: index * child!.getBoundingClientRect().height,
      });
    }, 1);
  }

  protected onTextFieldChange(event: KeyboardEvent) {
    if (this.inputElement.value.length === 1) {
      this.deleteLast = false;
    }
    super.onTextFieldChange(event);
    if (event.key === 'Backspace' && isEmpty(this.inputElement.value)) {
      if (this.deleteLast) {
        this.selectedItems = this.selectedItems.slice(1);
      } else {
        this.deleteLast = true;
      }
    }
    if (event.key === 'Enter') {
      if (this.isItemFocused) {
        return this.onItemClicked(this.filteredItems[this.focusedItemIndex]);
      }
      if (isEmpty(this.inputElement.value)) {
        return;
      }
      if (this.allowCustomValue) {
        const item = this.findItemByLabelFn(this.inputElement.value);
        item ? this.addItem(item) : this.addItemFn(this.inputElement.value);
        this.inputElement.value = '';
        this.filteredItems = this.items;
      }
    }
  }

  protected onItemClicked(item: FoxComboboxItem) {
    this.selectedItems.includes(item)
      ? this.removeItem(item)
      : this.addItem(item);
    this.inputElement.value = '';
    this.filteredItems = this.items;
  }

  private setOverflow() {
    if (!this.badgesContainer) return;
    while (hasOverflow(this.badgesContainer)) {
      const lastElement = this.badgesContainer.lastElementChild;
      lastElement?.remove();
    }
    this.overflow =
      this.selectedItems?.length - this.badgesContainer.childElementCount;
  }
}

const name = 'fox-multiselect-combobox';
if (!customElements.get(name)) {
  customElements.define(name, FoxMultiselectCombobox);
} else {
  console.warn(`${name} is already defined`);
}
