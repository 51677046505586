import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  NgModule,
  OnInit,
} from '@angular/core';

import { FieldConfiguration } from '@assecosolutions/fox-common-models';
import { FoxFormsModule } from '@fox/shared-ui-forms';

import { FormExecutorService } from './form-executor.service';

@Component({
  selector: 'fox-form-executor',
  templateUrl: './form-executor.component.html',
  styleUrls: ['./form-executor.component.scss'],
})
export class FormExecutorComponent implements OnInit {
  @Input()
  configuration: FieldConfiguration[] = [];

  constructor(public service: FormExecutorService) {}

  ngOnInit() {
    this.service.generateFormGroup(this.configuration);
  }

  isTextFieldType(type: string = ''): boolean {
    const textFieldTypes = [
      'text',
      'search',
      'tel',
      'url',
      'email',
      'password',
      'date',
      'month',
      'week',
      'time',
      'datetime-local',
      'number',
      'color',
    ];

    return textFieldTypes.includes(type);
  }
}

@NgModule({
  imports: [CommonModule, FoxFormsModule],
  declarations: [FormExecutorComponent],
  exports: [FormExecutorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormExecutorComponentModule {}
