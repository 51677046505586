import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import {
  UntypedFormGroup,
  FormGroupDirective,
  FormsModule,
  FormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';

const FORM_STATUS_INVALID = 'INVALID';

@Directive({ selector: '[foxFormSubmit],fox-button[type=submit]' })
export class SubmitDirective implements OnInit, OnDestroy {
  @Input()
  foxFormSubmit: FormGroup | null = null;

  @Input()
  type = '';

  subscription: Subscription | null = null;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Optional() private fgd: FormGroupDirective
  ) {}

  ngOnInit(): void {
    const formGroup: UntypedFormGroup = this.foxFormSubmit ?? this.fgd.form;

    formGroup.status === FORM_STATUS_INVALID
      ? this.setDisabled()
      : this.removeDisabled();

    this.subscription = formGroup.statusChanges.subscribe((newStatus) =>
      newStatus === FORM_STATUS_INVALID
        ? this.setDisabled()
        : this.removeDisabled()
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private setDisabled() {
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
  }

  private removeDisabled() {
    this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
  }
}

@NgModule({
  declarations: [SubmitDirective],
  exports: [SubmitDirective],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SubmitDirectiveModule {}
