import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { isNotEmpty } from '@assecosolutions/fox-common-utils';

import { styles } from './FoxKanbanCard.css';
import { FoxKanbanCardRow, UserProfile } from './FoxKanbanCard.interface';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxKanbanCard;
  }
}

/**
 *
 * FOX Kanban Card
 *
 * @element fox-kanban-card
 *
 *  * @cssprop [--fox-kanban-card-background = #fbfdff]
 *  * @cssprop [--fox-kanban-card-highlighted-image-url = url('/assets/card-background.png')]
 *  * @cssprop [--fox-kanban-card-highlighted-selected-image-url = url('/assets/card-background.png')]
 *  * @cssprop [--fox-kanban-card-text-color = var(--fox-font-base-color , black)]
 *  * @cssprop [--fox-kanban-card-box-shadow = 0 17px 10px rgba(0, 6, 44, 0.02), 0 7px 7px rgba(0, 6, 44, 0.03), 0 2px 4px rgba(0, 6, 44, 0.03), 0 0 0 rgba(0, 6, 44, 0.03)]
 *  * @cssprop [--fox-kanban-card-box-shadow-hover = 0 44px 18px rgba(0, 6, 44, 0.01), 0 25px 15px rgba(0, 6, 44, 0.04), 0 11px 11px rgba(0, 6, 44, 0.07), 0 3px 6px rgba(0, 6, 44, 0.08), 0 0 0 rgba(0, 6, 44, 0.08)]
 *  * @cssprop [--fox-kanban-card-border-color = var(rgba(255, 255, 255, 0.5))]
 *  * @cssprop [--fox-kanban-card-footer-background-color = rgba(255, 255, 255, 0.4)]
 *  * @cssprop [--fox-kanban-card-footer-border-color = #edeff1]
 *  * @cssprop [--fox-kanban-card-footer-second-border-color = rgba(255, 255, 255, 0.2)]
 *  * @cssprop [--fox-kanban-card-list-label-width = 40%]
 *  * @cssprop [--fox-kanban-card-list-value-width = 60%]
 *  * @cssprop [--fox-kanban-card-list-value-font-size = 12px]
 *
 *  */
export class FoxKanbanCard extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: Boolean, reflect: true })
  condensed = false;

  @property({ type: Boolean, reflect: true })
  selected = false;

  @property({ type: String })
  indicator = '';

  @property({ type: Boolean, reflect: true })
  loading = false;

  @property({ type: Boolean, reflect: true })
  busy = false;

  @property({ type: Boolean, reflect: true })
  highlighted = false;

  @property()
  userProfile: UserProfile = {};

  @property({ type: String })
  link = '';

  @property()
  rows: FoxKanbanCardRow[] = [];

  @property({ type: String })
  title = '';

  @property({ type: Boolean })
  private hideFooter = false;

  render() {
    return html` ${this.loading ? this.renderSkeleton() : this.renderCard()} `;
  }

  protected firstUpdated() {
    const slot = this.shadowRoot?.querySelector('slot[name="footer"]');

    const assignedNodes = (slot as HTMLSlotElement)?.assignedNodes();

    if (
      this.userProfile.name ||
      this.userProfile.initials ||
      this.validImg(this.userProfile.image)
    ) {
      this.hideFooter = false;
    } else if (assignedNodes?.length === 0) {
      this.hideFooter = true;
    }
  }

  renderCard() {
    const classes = {
      'fox-kanban-card--condensed': this.condensed,
      'fox-kanban-card--selected': this.selected,
      'fox-kanban-card--highlighted': this.highlighted,
      'fox-kanban-card--has-indicator': this.indicator,
      'fox-kanban-card--busy': this.busy,
    };

    return html` <div
      class="fox-kanban-card ${classMap(classes)}"
      style="--indicator-color: ${this.indicator}"
    >
      <div class="fox-kanban-card--body">
        <slot name="header">${this.renderHeader()}</slot>
        <slot name="body">
          ${!this.condensed ? this.renderList() : nothing}
        </slot>
      </div>
      <div
        class="fox-kanban-card--footer ${classMap({
          'fox-kanban-card--footer-hidden': this.hideFooter,
        })}"
      >
        <slot name="footer"> ${this.renderFooter()} </slot>
      </div>
    </div>`;
  }

  renderHeader() {
    return html`
      <div class="fox-kanban-card--header">
        <h4 class="fox-kanban-card--header-label">${this.title}</h4>
        ${!this.busy ? this.renderLink(this.link) : this.renderLoader()}
      </div>
    `;
  }

  renderFooter() {
    return html` ${this.condensed ? this.renderAvatar() : this.renderBadge()} `;
  }

  renderBadge() {
    return html`
      <fox-badge label="${this.userProfile.name}">
        ${this.renderAvatar()}
      </fox-badge>
    `;
  }

  renderAvatar() {
    return html`
      <fox-avatar
        slot="icon"
        size="xs"
        initials="${this.userProfile.initials}"
        name="${this.userProfile.name}"
        image="${this.userProfile.image}"
      ></fox-avatar>
    `;
  }

  renderLinkValue(link: string, value?: string) {
    return html` <a
      class="fox-kanban-card--list-value fox-kanban-card--list-value-link"
      target="_blank"
      href="${link}"
      title="${value}"
    >
      ${value}
    </a>`;
  }

  renderStaticValue(value?: string) {
    return html`
      <div class="fox-kanban-card--list-value" title="${value}">
        ${value?.toString()?.slice(0, 120)}
      </div>
    `;
  }

  renderRow(row: FoxKanbanCardRow) {
    //TODO: REFACTOR  TO  FOX typed field and extend the interface of row to typed field
    return html`
      <div class="fox-kanban-card--list">
        <p class="fox-kanban-card--list-label">${row.label}:</p>
        ${row.link
          ? this.renderLinkValue(row.link, row.value)
          : this.renderStaticValue(row.value)}
      </div>
    `;
  }

  renderList() {
    const filteredRows = this.rows.filter((row) => isNotEmpty(row.value));
    return html`
      <div class="fox-kanban-card--list-container">
        ${filteredRows.map((row) => this.renderRow(row))}
      </div>
    `;
  }

  renderLink(link: string) {
    if (!link) return nothing;
    return html` <a
      target="_blank"
      class="fox-kanban-card--header-link"
      href="${link}"
    >
      <fox-icon-button small icon="chevron_right"></fox-icon-button>
    </a>`;
  }

  renderLoader() {
    return html`
      <fox-progress
        class="fox-kanban-card--spinner"
        type="circular"
        indeterminate
      ></fox-progress>
    `;
  }

  renderSkeleton() {
    return html` <div class="fox-kanban-card">
      <div class="fox-kanban-card--body">
        <div class="fox-kanban-card--skeleton"></div>
        <div class="fox-kanban-card--skeleton"></div>
      </div>
      <div class="fox-kanban-card--footer">
        <div
          class="skeleton"
          style="width: 24px; height: 24px; border-radius: 50%"
        ></div>
      </div>
    </div>`;
  }

  protected validImg(image: string | undefined) {
    if (!image) return false;
    const img = new Image();
    img.src = image;
    return img.complete;
  }
}

const name = 'fox-kanban-card';

if (!customElements.get(name)) {
  customElements.define(name, FoxKanbanCard);
} else {
  console.warn(`${name} is already defined`);
}
