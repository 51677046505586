import { IconButtonBase } from '@material/mwc-icon-button/mwc-icon-button-base';
import { styles as mwcStyles } from '@material/mwc-icon-button/mwc-icon-button.css';

import { styles } from './FoxIconButton.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxIconButton;
  }
}

/**
 *
 * Use any icon listed in https://fonts.google.com/icons?selected=Material+Icons and place the name inside the icon attribute
 *
 * @element fox-icon-button
 *
 * @slot default - slot for custom image / svg using image attr is recommended
 *
 * @attr icon  - Icon to display, and aria-label value when label is not defined.
 * @attr label - Accessible label for the button, sets aria-label
 * @attr disabled - Disabled buttons cannot be interacted with and have no visual interaction effect.
 * @attr small  - Button will be displayed at the smallest available size
 *
 */
export class FoxIconButton extends IconButtonBase {
  /**
   * @ignore
   */
  static styles = [mwcStyles, styles];
}

const name = 'fox-icon-button';

if (!customElements.get(name)) {
  customElements.define(name, FoxIconButton);
} else {
  console.warn(`${name} is already defined`);
}
