import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';

import { ElementAction } from '@assecosolutions/fox-common-models';
import { FoxFormsModule } from '@fox/shared-ui-forms';

@Component({
  selector: 'fox-action-executor-menu',
  template: `
    <fox-list-item
      *ngFor="let elementAction of elementActions"
      (click)="onMenuItemClick(elementAction)"
    >
      {{ elementAction.name }}
    </fox-list-item>
  `,
})
export class ActionExecutorMenuComponent {
  @Input()
  elementActions?: ElementAction[];

  @Output()
  triggerClick = new EventEmitter<ElementAction>();

  onMenuItemClick(elementAction: ElementAction) {
    this.triggerClick.emit(elementAction);
  }
}

@NgModule({
  imports: [CommonModule, FoxFormsModule],
  declarations: [ActionExecutorMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ActionExecutorMenuComponent],
})
export class ActionExecutorMenuComponentModule {}
