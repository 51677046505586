import { flatten } from './object-visitor';
import { UnknownRecord } from './strict-mode';
import { isNotEmpty } from './util';

export const dynamicContentExecutor = (
  str: string,
  obj: UnknownRecord
): string => {
  const exp = /\$\{(.+?)\}/g;
  const flattened = flatten(obj, {
    includeIntermediateKeys: false,
    includeObjectLeafs: false,
    includeArrayLeafs: false,
  });

  return isNotEmpty(str)
    ? str.replace(
        exp,
        (_, token) => (valueByPath(flattened, token) as string) ?? ''
      )
    : '';
};

export const valueByPath = (item: UnknownRecord, path: string): string =>
  path && (item[path] as string);
