<div
  class="item-dropzone reset-spacing {{
    element?.type === 'COLUMN' ? 'col' : 'row'
  }}"
  [ngClass]="borderClass"
>
  <ng-container *ngIf="hasChildren; else elementTemplateRef">
    <ng-container *ngFor="let child of element?.children">
      <fox-page-element
        [element]="child"
        [parentElement]="element"
        [pageId]="pageId"
        [isEditable]="isEditable"
        [isDragging]="isDragging"
        (dragStartedInside)="onDragStartedInside($event)"
        (dragEnded)="onDragEnded()"
        (draggingOverElement)="onDraggingOverElement($event)"
        (registerPageElementForInsertion)="
          onRegisterPageElementForInsertion($event)
        "
        (showBorderOnParent)="onShowBorder($event)"
        (deleteElement)="onDeleteElement($event)"
        class="{{ element?.type === 'COLUMN' ? 'row' : 'col' }} reset-spacing"
      >
      </fox-page-element>
    </ng-container>
  </ng-container>
  <ng-template #elementTemplateRef>
    <div
      cdkDrag
      [cdkDragData]="element"
      [cdkDragDisabled]="dragDisabled"
      (cdkDragStarted)="onDragStartedInside(parentElement)"
      (cdkDragEnded)="onDragEnded()"
      #elementLoader
      class="item-element reset-spacing"
    >
      <fox-element-loader
        [element]="elementConfiguration(element)"
        [editable]="isEditable"
        (mousemove)="onMouseMove($event)"
        (mouseleave)="onMouseLeave()"
        (deleteElement)="onDeleteElement($event)"
      ></fox-element-loader>
      <ng-container *cdkDragPreview>
        <fox-element-loader
          class="d-flex drag-preview"
          style="min-width: 400px"
          [editable]="false"
          [element]="elementConfiguration(element)"
        ></fox-element-loader>
      </ng-container>
    </div>
  </ng-template>
</div>
