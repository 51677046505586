import {
  ControlTypeField,
  DisplayType,
  DisplayTypeFormControlModel,
  DisplayTypeMap,
  FieldOption,
} from './field-configurator.utils';

const displayTypeMapDefaultControlsProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeFormControlModel[] = (
  selectableFieldsOptions?: FieldOption[]
) => {
  return [
    {
      name: 'header',
      label: 'Header',
      tooltip: 'Header Name',
    },
    {
      name: 'path',
      label: 'Path',
      tooltip: 'Database field name',
      control: ControlTypeField.CONTROL_TYPE_FIELD_COMBOBOX,
      options: selectableFieldsOptions,
    },
    {
      name: 'tooltip',
      label: 'Tooltip',
      tooltip: 'Tooltip is placed at the Table Header',
    },
    {
      name: 'hidden',
      control: ControlTypeField.CONTROL_TYPE_FIELD_HIDDEN,
    },
    {
      name: 'conditionsConfiguration',
      control: ControlTypeField.CONTROL_TYPE_FIELD_HIDDEN,
    },
    {
      name: 'type',
      label: '',
      tooltip: 'type',
      control: ControlTypeField.CONTROL_TYPE_FIELD_TYPE,
    },
  ];
};

const numberDisplayTypeDefaultControls: () => DisplayTypeFormControlModel[] =
  () => [
    {
      name: 'prefix',
      label: 'Prefix',
      control: ControlTypeField.CONTROL_TYPE_FIELD_COMBOBOX,
      options: [
        { name: '€', value: '€' },
        { name: '$', value: '$' },
        { name: '£', value: '£' },
      ],
    },
    {
      name: 'postfix',
      label: 'Postfix',
      control: ControlTypeField.CONTROL_TYPE_FIELD_COMBOBOX,
      options: [
        { name: '€', value: '€' },
        { name: '$', value: '$' },
        { name: '£', value: '£' },
        { name: 'kg', value: 'kg' },
        { name: 'm', value: 'm' },
        { name: 'km', value: 'km' },
        { name: 'pieces', value: 'pieces' },
      ],
    },
    {
      name: 'decimals',
      label: 'Decimal Points',
      control: ControlTypeField.CONTROL_TYPE_FIELD_NUMBER,
    },
  ];

export const iconDisplayTypeDefaultControls: () => DisplayType = () => {
  return {
    name: 'icon',
    icon: 'text_format',
    description: 'Display type: ICON',
    controls: [
      {
        name: 'tooltip',
        label: 'Tooltip',
        tooltip: 'Tooltip is placed at the Table Header',
      },
      {
        name: 'hidden',
        control: ControlTypeField.CONTROL_TYPE_FIELD_HIDDEN,
      },
      {
        name: 'conditionsConfiguration',
        control: ControlTypeField.CONTROL_TYPE_FIELD_HIDDEN,
      },
      {
        name: 'type',
        label: '',
        value: 'icon',
        tooltip: 'type',
        control: ControlTypeField.CONTROL_TYPE_FIELD_HIDDEN,
      },
      {
        name: 'value',
        label: 'Value',
        control: ControlTypeField.CONTROL_TYPE_FIELD_ICON,
      },
    ],
  };
};

export const numericDisplayTypeMapProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeMap = (selectableFieldsOptions?: FieldOption[]) => {
  const defaultControls: DisplayTypeFormControlModel[] =
    displayTypeMapDefaultControlsProvider(selectableFieldsOptions).filter(
      (control) => control.name !== 'header'
    );
  const defaultControlsNoPath: DisplayTypeFormControlModel[] =
    defaultControls.filter((control) => control.name !== 'path');

  return {
    ...staticDisplayTypeMapProvider(),
    sum: {
      name: 'sum',
      icon: 'text_format',
      description: 'Display type: SUM',
      controls: [...defaultControls, ...numberDisplayTypeDefaultControls()],
    },
    min: {
      name: 'min',
      icon: 'text_format',
      description: 'Display type: MIN',
      controls: [...defaultControls, ...numberDisplayTypeDefaultControls()],
    },
    max: {
      name: 'max',
      icon: 'text_format',
      description: 'Display type: MAX',
      controls: [...defaultControls, ...numberDisplayTypeDefaultControls()],
    },
    count: {
      name: 'count',
      icon: 'text_format',
      description: 'Display type: COUNT',
      controls: [
        ...defaultControlsNoPath,
        ...numberDisplayTypeDefaultControls(),
      ],
    },
    median: {
      name: 'median',
      icon: 'text_format',
      description: 'Display type: MEDIAN',
      controls: [...defaultControls, ...numberDisplayTypeDefaultControls()],
    },
    avg: {
      name: 'avg',
      icon: 'text_format',
      description: 'Display type: AVG',
      controls: [...defaultControls, ...numberDisplayTypeDefaultControls()],
    },
  };
};

export const staticDisplayTypeMapProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeMap = () => {
  return {
    static: {
      name: 'static',
      icon: 'text_format',
      description: 'Display type: Static Text',
      controls: [
        {
          name: 'value',
          label: 'Value',
        },
        ...displayTypeMapDefaultControlsProvider().filter(
          (control) => control.name !== 'header' && control.name !== 'path'
        ),
      ],
    },
  };
};

export const textDisplayTypeMapProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeMap = (selectableFieldsOptions?: FieldOption[]) => {
  const defaultControls: DisplayTypeFormControlModel[] =
    displayTypeMapDefaultControlsProvider(selectableFieldsOptions);

  return {
    text: {
      name: 'text',
      icon: 'text_format',
      description: 'Display type: Dynamic Text',
      controls: [...defaultControls],
    },
  };
};

export const columnDisplayTypeMapProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeMap = (selectableFieldsOptions?: FieldOption[]) => {
  const defaultControls: DisplayTypeFormControlModel[] =
    displayTypeMapDefaultControlsProvider(selectableFieldsOptions).filter(
      (control) => control.name !== 'type'
    );
  return {
    column: {
      name: 'column',
      icon: 'text_format',
      description: 'Display type: Column',
      controls: [
        ...defaultControls,
        {
          name: 'link',
          label: 'Link',
          control: ControlTypeField.CONTROL_TYPE_FIELD_DYNAMIC_INPUT,
        },
        {
          name: 'type',
          label: '',
          tooltip: 'type',
          control: ControlTypeField.CONTROL_TYPE_FIELD_HIDDEN,
        },
      ],
    },
  };
};

export const badgeDisplayTypeMapProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeMap = (selectableFieldsOptions?: FieldOption[]) => {
  const defaultControls: DisplayTypeFormControlModel[] =
    displayTypeMapDefaultControlsProvider(selectableFieldsOptions);
  return {
    badge: {
      name: 'badge',
      icon: 'text_format',
      description: 'Display type: Badge',
      controls: [
        ...defaultControls,
        {
          name: 'icon',
          label: 'Icon',
          control: ControlTypeField.CONTROL_TYPE_FIELD_DYNAMIC_INPUT,
        },
      ],
    },
  };
};

export const displayInputsTypeMapProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeMap = (selectableFieldsOptions?: FieldOption[]) => {
  const defaultControls: DisplayTypeFormControlModel[] =
    displayTypeMapDefaultControlsProvider(selectableFieldsOptions);

  return {
    ...textDisplayTypeMapProvider(selectableFieldsOptions),
    email: {
      name: 'email',
      icon: 'mail',
      description: 'Display type: Mail',
      controls: [...defaultControls],
    },
    number: {
      name: 'number',
      icon: 'euro_symbol',
      description: 'Display type: Number',
      controls: [...defaultControls, ...numberDisplayTypeDefaultControls()],
    },
  };
};

export const displayTypeMapProvider: (
  selectableFieldOptions?: FieldOption[]
) => DisplayTypeMap = (selectableFieldsOptions?: FieldOption[]) => {
  const defaultControls: DisplayTypeFormControlModel[] =
    displayTypeMapDefaultControlsProvider(selectableFieldsOptions);

  return {
    none: {
      name: 'none',
      icon: 'none',
      description: 'none',
      controls: [...defaultControls],
    },
    ...textDisplayTypeMapProvider(selectableFieldsOptions),
    email: {
      name: 'email',
      icon: 'mail',
      description: 'Display type: Mail',
      controls: [...defaultControls],
    },
    link: {
      name: 'link',
      icon: 'link',
      description: 'Display type: Anchor',
      controls: [
        ...defaultControls,
        {
          name: 'target',
          label: 'Target',
          control: ControlTypeField.CONTROL_TYPE_FIELD_SELECT,
          options: [
            { name: 'New window', value: '_blank' },
            { name: 'Same window', value: '_self' },
          ],
        },
        {
          name: 'value',
          label: 'Value',
          control: ControlTypeField.CONTROL_TYPE_FIELD_DYNAMIC_INPUT,
        },
      ],
    },
    image: {
      name: 'image',
      icon: 'image',
      description: 'Display type: Image',
      controls: [...defaultControls],
    },
    number: {
      name: 'number',
      icon: 'euro_symbol',
      description: 'Display type: Number',
      controls: [...defaultControls, ...numberDisplayTypeDefaultControls()],
    },
    datetime: {
      name: 'datetime',
      icon: 'today',
      description: 'Display Type: Date Time',
      controls: [
        ...defaultControls,
        {
          name: 'dateTimeFormat',
          label: 'Date Time Format',
          control: ControlTypeField.CONTROL_TYPE_FIELD_COMBOBOX,
          options: [
            {
              name: '01. May 1970 (dd. MMMM yyyy)',
              value: 'dd. MMMM yyyy',
            },
            {
              name: '01.05.1970 (dd.MM.yyyy)',
              value: 'dd.MM.yyyy',
            },
            {
              name: '01. May 1970 09:15 (dd. MMMM yyyy HH:mm)',
              value: 'dd. MMMM yyyy HH:mm',
            },
            {
              name: '01.05.1970 09:15 (dd.MM.yyyy HH:mm)',
              value: 'dd.MM.yyyy HH:mm',
            },
            {
              name: 'Q2 1970 (QQQ yyyy)',
              value: 'QQQ yyyy',
            },
            {
              name: '09:15 (HH:mm)',
              value: 'HH:mm',
            },
            {
              name: '09:15:32 (HH:mm:ss)',
              value: 'HH:mm:ss',
            },
          ],
        },
      ],
    },
    ...badgeDisplayTypeMapProvider(selectableFieldsOptions),
  };
};
