import { ElementSettings } from './element-configuration.model';
import { FieldConfiguration } from './field-configuration.model';

export type KanbanTypeEnum = 'static' | 'dynamic';

export const KANBAN_TYPES = ['static', 'dynamic'];

export interface KanbanConfiguration {
  settings?: ElementSettings;
  type?: KanbanTypeEnum;
  columns?: FieldConfiguration[];
  cards?: KanbanCardsConfiguration;
}

export interface KanbanCardsConfiguration {
  title: FieldConfiguration[];
  rows: FieldConfiguration[];
  footer: FieldConfiguration[];
}
