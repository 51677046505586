import '@vaadin/vaadin-grid/theme/material/vaadin-grid-sorter.js';
import { GridSorterElement } from '@vaadin/vaadin-grid/src/vaadin-grid-sorter.js';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-sorter': FoxTableSorter;
  }
}

export class FoxTableSorter extends GridSorterElement {}

window.customElements.define('fox-table-sorter', FoxTableSorter);
