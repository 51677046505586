import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TenantIdInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(
      httpRequest.clone({
        setHeaders: {
          //TODO: i know, i know this is bad, but this is only for demo
          'x-tenant-id': localStorage.getItem('tenant') || 'master',
        },
      })
    );
  }
}
