/**
 * FOX API
 * FOX API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateFoxConfigurationStateRequest { 
    state: UpdateFoxConfigurationStateRequest.StateEnum;
}
export namespace UpdateFoxConfigurationStateRequest {
    export type StateEnum = 'IN_DEVELOPMENT' | 'IN_REVIEW' | 'RELEASED' | 'RETRACTED';
    export const StateEnum = {
        InDevelopment: 'IN_DEVELOPMENT' as StateEnum,
        InReview: 'IN_REVIEW' as StateEnum,
        Released: 'RELEASED' as StateEnum,
        Retracted: 'RETRACTED' as StateEnum
    };
}


