import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { removeRedundantSlashes } from '@assecosolutions/ng-common';

import { Route, Routes, UrlParam } from './routes';

export const childRouteActive = (
  router: Router,
  route: ActivatedRoute
): Observable<boolean> => {
  return router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((_) => hasChildren(route)),
    startWith(hasChildren(route))
  );
};

export const hasChildren = (route: ActivatedRoute): boolean => {
  return !!route?.children && !!route.children.length;
};

export const iterateRoutes = (routes: Routes): Route[] => {
  return Object.values(routes).filter((route) => {
    return route.iterable === undefined || route.iterable === null
      ? true
      : route.iterable;
  });
};

export const route = (
  route: Route,
  params?: Partial<Record<UrlParam, string>>
): string => {
  return removeRedundantSlashes(routeSegments(route, params).join('/'));
};

export const routeSegments = (
  route: Route,
  params?: Partial<Record<UrlParam, string>>
): string[] => {
  let parentSegments: string[] = [];
  let currentRoute = route;
  while (currentRoute.parent) {
    parentSegments = [
      ...currentRoute.parent.path.split('/'),
      ...parentSegments,
    ];
    currentRoute = currentRoute.parent;
  }

  const segments = ['/', ...parentSegments, ...route.path.split('/')];
  return replaceRouteParameters(segments, params);
};

export const replaceRouteParameters = (
  segments: string[],
  params?: Partial<Record<UrlParam, string>>
): string[] => {
  return segments.map((segment) => {
    if (segment.startsWith(':')) {
      const paramName = segment.substr(1);
      if (!isEnumValueOf(UrlParam)(paramName)) {
        throw new Error(`Parameter ${paramName} is no known URL parameter.`);
      }

      const paramValue = params && params[paramName];
      if (!paramValue) {
        throw new Error(`Parameter ${paramName} was not provided for route.`);
      }
      return paramValue;
    }
    return segment;
  });
};

const isEnumValueOf =
  <T>(e: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);
