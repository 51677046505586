import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgModule,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NgControl,
  ValidationErrors,
} from '@angular/forms';

import { isNil, isNotNil, isString } from '@assecosolutions/fox-common-utils';
import { MissingDependencyError } from '@assecosolutions/ng-common';

import {
  ComboboxControlComponent,
  MultiselectComboboxControlComponent,
} from '../form-controls';

// Angular's ValidationErrors are of type `any`.
// No way around it..
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationError = any;
export type ErrorMessageFormatter = (error: ValidationError) => string;

export interface ErrorMessageFormatters {
  [key: string]: ErrorMessageFormatter;
}

export const DEFAULT_ERROR_MESSAGE_FORMATTERS = {
  maxlength: (error: ValidationError) => {
    const exceededBy = error.actualLength - error.requiredLength;
    return `Max length exceeded by ${exceededBy} characters`;
  },
  required: (_: ValidationError) => 'Field is required',
  empty: (_: ValidationError) => 'Field is required',
};

const collectErrorMessages = (
  errors: ValidationErrors | null,
  formatters: ErrorMessageFormatters
): string => {
  if (isNil(errors) || isNil(formatters)) {
    return '';
  }
  return Object.keys(errors)
    .map((errorKey) => {
      const error: ValidationError = errors[errorKey];
      if (isString(error)) {
        return error ? error : errorKey;
      } else {
        const formatter = formatters[errorKey];
        return formatter ? formatter(error) : errorKey;
      }
    })
    .join(', ');
};

function defaultValidityTransform(
  control: AbstractControl,
  // No way around it..
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any,
  formatters: ErrorMessageFormatters
) {
  return () => {
    const valid = control.status == 'VALID';
    const errors = control.errors;

    const message = collectErrorMessages(errors, formatters);
    element.setCustomValidity(message);
    return { valid, customError: true };
  };
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[formControlName]' })
export class ValidationMessageDirective implements AfterViewInit {
  @Input()
  errorMessageFormatters: ErrorMessageFormatters | null = null;

  constructor(
    private ngControl: NgControl,
    private hostElementRef: ElementRef,
    @Optional() private comboboxControl: ComboboxControlComponent,
    @Optional()
    private multiselectComboboxControl: MultiselectComboboxControlComponent
  ) {}

  ngAfterViewInit(): void {
    const abstractControl = this.ngControl?.control;
    const elementRef = this.getWebComponentElementRef();

    if (isNil(abstractControl)) {
      throw new MissingDependencyError(
        ValidationMessageDirective.name,
        AbstractControl.name
      );
    }

    if (isNil(elementRef)) {
      throw new MissingDependencyError(
        ValidationMessageDirective.name,
        ElementRef.name
      );
    }
    elementRef.validityTransform = defaultValidityTransform(
      abstractControl,
      elementRef,
      this.errorMessageFormatters ?? DEFAULT_ERROR_MESSAGE_FORMATTERS
    );
  }

  // No way around it..
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getWebComponentElementRef(): any {
    // TODO: rework, optional chaining doesnt work with combobox
    const comboboxInputElement =
      this.comboboxControl?.combobox?.nativeElement.inputElement;
    if (isNotNil(comboboxInputElement)) {
      return comboboxInputElement;
    }

    const multiselectComboboxInputElement =
      this.multiselectComboboxControl?.combobox?.nativeElement?.inputElement;

    if (isNotNil(multiselectComboboxInputElement)) {
      return multiselectComboboxInputElement;
    }
    return this.hostElementRef.nativeElement;
  }
}

@NgModule({
  declarations: [ValidationMessageDirective],
  exports: [ValidationMessageDirective],
  imports: [CommonModule, FormsModule],
})
export class ValidationMessageDirectiveModule {}
