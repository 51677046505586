<fox-notifications></fox-notifications>
<fox-menu-anchor></fox-menu-anchor>
<fox-app-bar
  brandUrl="/"
  moduleName="Admin"
  brandName="FOX"
  profileName="Burak Topal"
  [profileClient]="tenant"
  profileImage="https://avatars.githubusercontent.com/u/17909825"
>
  <fox-list-item slot="profileMenu" (click)="updateXTenantId('DEMO')">
    DEMO
  </fox-list-item>
  <fox-list-item slot="profileMenu" (click)="updateXTenantId('master')">
    MASTER
  </fox-list-item>
</fox-app-bar>
<fox-dialog-anchor></fox-dialog-anchor>
<fox-dialog-anchor id="secondary"></fox-dialog-anchor>
<router-outlet></router-outlet>
