export interface ElementAction<T = Record<string, unknown>> {
  id?: string;
  name: string;
  trigger: ElementActionTriggerEnum;
  target: string;
  result: ElementActionResultEnum;
  parameters?: T;
}

export enum ElementActionTargetEnum {
  TABLE_ROW = 'TABLE_ROW',
  HEADER = 'HEADER',
  CARD = 'CARD',
  LANE = 'LANE',
}

export enum ElementActionTriggerEnum {
  DOUBLE_CLICK = 'DOUBLE_CLICK',
  LEFT_CLICK = 'LEFT_CLICK',
  RIGHT_CLICK = 'RIGHT_CLICK',
}

export enum ElementActionResultEnum {
  OPEN_PREVIEW = 'OPEN_PREVIEW',
  OPEN_URL = 'OPEN_URL',
  RUN_ACTION = 'RUN_ACTION',
}

export interface ElementActionTriggerEvent<T = unknown> {
  trigger?: ElementActionTriggerEnum;
  target?: ElementActionTargetEnum;
  mouseEvent?: MouseEvent;
  selectedItem?: T;
}
