import { TemplateRef, ViewContainerRef } from '@angular/core';

import { DialogType } from '@assecosolutions/fox-dialog';

export interface DialogOptions {
  id: string;
  heading?: string;
  hideActions?: boolean;
  type: DialogType;
  noBorder: boolean;
  noPadding: boolean;
  scrimClickAction: string;
  escapeKeyAction: string;
  defaultAction: string;
}

export interface Dialog extends DialogOptions {
  bodyTemplateRef: TemplateRef<unknown> | null;
  slotTemplateRef: TemplateRef<unknown> | null;
  viewContainer: ViewContainerRef;

  open(): void;
  close(): void;
}

export class ComponentNotRegistered extends Error {
  constructor(id: string) {
    super(`Component with ${id} is not registered.`);
  }
}

export interface ComponentDialogOptions<T> extends DialogOptions {
  inputs?: { [K in keyof T]?: T[K] };
}

export const DEFAULT_DIALOG_OPTIONS: DialogOptions = {
  id: 'default',
  type: 'default',
  hideActions: false,
  noBorder: false,
  noPadding: false,
  scrimClickAction: 'close',
  escapeKeyAction: 'close',
  defaultAction: 'close',
};
