<fox-back-layer>
  <fox-content-layer>
    <div slot="body">
      <div class="container py-4">
        <h3 class="px-3">{{ pageConfiguration.name }}</h3>
        <fox-page-element [element]="configuration"></fox-page-element>
      </div>
    </div>
  </fox-content-layer>
</fox-back-layer>
<fox-front-layer [preview]="previewFacade.previewActive$ | async">
  <fox-preview
    [configuration]="previewFacade.selectedItemConfiguration$ | async"
    [selectedItem]="previewFacade.selectedItem$ | async"
    (closePreview)="onClosePreview()"
  ></fox-preview>
</fox-front-layer>
