import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';

import {
  TableColumnConfiguration,
  TableConfiguration,
} from '@assecosolutions/fox-common-models';
import { isNotNil } from '@assecosolutions/fox-common-utils';
import { FoxCheckListItem } from '@assecosolutions/fox-list';
import { ElementConfiguration } from '@fox/shared/models';

import {
  ActionExecutorMenuComponent,
  ActionExecutorMenuComponentModule,
} from '../action-executor/action-executor-menu.component';

@Component({
  selector: 'fox-element-executor-table-menu',
  template: `
    <fox-action-executor-menu
      [elementActions]="elementActions"
      (triggerClick)="onMenuItemClick($event)"
    ></fox-action-executor-menu>

    <fox-list multi>
      <li divider role="separator" *ngIf="elementActions"></li>
      <fox-check-list-item
        *ngFor="
          let column of tableConfiguration?.detailConfiguration.columns;
          let index = index
        "
        [selected]="!column.hidden"
        (click)="onToggleColumnVisibility($event, column)"
        value="{{ index }}"
      >
        {{ column.header }}
      </fox-check-list-item>
    </fox-list>
  `,
})
export class ElementExecutorTableMenuComponent extends ActionExecutorMenuComponent {
  @Input()
  tableConfiguration?: ElementConfiguration<TableConfiguration>;

  @Output()
  updatedTableConfiguration = new EventEmitter<
    ElementConfiguration<TableConfiguration>
  >();

  onToggleColumnVisibility(
    event: MouseEvent,
    selectedColumn: TableColumnConfiguration
  ) {
    if (this.tableConfiguration && isNotNil(this.tableConfiguration)) {
      const updatedTableConfiguration = {
        ...this.tableConfiguration,
        detailConfiguration: {
          ...this.tableConfiguration?.detailConfiguration,
          columns: this.tableConfiguration?.detailConfiguration?.columns?.map(
            (column: TableColumnConfiguration) => {
              return column === selectedColumn
                ? {
                    ...column,
                    hidden: (event.target as FoxCheckListItem).selected,
                  }
                : column;
            }
          ),
        },
      };

      this.tableConfiguration = updatedTableConfiguration;
      this.updatedTableConfiguration?.emit(updatedTableConfiguration);
    }
  }
}

@NgModule({
  imports: [CommonModule, ActionExecutorMenuComponentModule],
  declarations: [ElementExecutorTableMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ElementExecutorTableMenuComponent],
})
export class ElementExecutorTableMenuComponentModule {}
