import '@vaadin/vaadin-grid/theme/material/vaadin-grid-filter.js';
import { GridFilterElement } from '@vaadin/vaadin-grid/src/vaadin-grid-filter.js';

declare global {
  interface HTMLElementTagNameMap {
    'fox-table-filter': FoxTableFilter;
  }
}

export class FoxTableFilter extends GridFilterElement {}

window.customElements.define('fox-table-filter', FoxTableFilter);
