import { UntypedFormGroup } from '@angular/forms';

import { isArray, keysOf, KeysOfOpts } from '@assecosolutions/fox-common-utils';

import { FormControlWithProperties } from './element-configurator.model';

export interface DisplayTypeFormControlModel {
  name: string;
  label?: string;
  tooltip?: string;
  control?: ControlTypeField;
  options?: DisplayTypeFormControlModelOptions[];
}

export interface DisplayTypeFormControlModelOptions {
  name: string;
  value: string;
}

export type DisplayTypeFormControlsModel = DisplayTypeFormControlModel[];
export interface DisplayType {
  name: string;
  description: string;
  icon: string;
  controls?: DisplayTypeFormControlsModel;
}
export type DisplayTypes = DisplayType[];
export interface DisplayTypeMap {
  [key: string]: DisplayType;
}

// eslint-disable-next-line no-shadow
export enum ControlTypeField {
  CONTROL_TYPE_FIELD_COMBOBOX = 'field-combobox',
  CONTROL_TYPE_FIELD_SELECT = 'field-select',
  CONTROL_TYPE_FIELD_DYNAMIC_INPUT = 'field-dynamic-input',
  CONTROL_TYPE_FIELD_TYPE = 'field-type',
  CONTROL_TYPE_FIELD_CHECKBOX = 'field-checkbox',
  CONTROL_TYPE_FIELD_NUMBER = 'field-number',
  CONTROL_TYPE_FIELD_ICON = 'field-icon',
  CONTROL_TYPE_FIELD_HIDDEN = 'field-hidden',
}

export const emptyControls = {
  header: '',
  hidden: '',
  path: '',
  tooltip: '',
  type: '',
};

export interface FieldOption {
  name: string;
  value: string;
}

export const toDisplayTypes: (map: DisplayTypeMap) => DisplayTypes = (
  map: DisplayTypeMap
) => Object.values(map).filter((displayType) => displayType.name !== 'none');

export const toSelectableFields: (
  selectableFields?: string[]
) => FieldOption[] = (selectableFields: string[]) =>
  selectableFields?.map((key) => {
    return { name: key, value: key };
  });

export const addOrUpdateDisplayTypeFormControls: (
  displayType: DisplayType
) => (formGroup: UntypedFormGroup) => UntypedFormGroup =
  (displayType: DisplayType) => (formGroup: UntypedFormGroup) => {
    if (!displayType?.controls) {
      return formGroup;
    }

    const controls: {
      [key: string]: FormControlWithProperties<DisplayTypeFormControlModel>;
    } = displayType.controls.reduce((acc, curr) => {
      const name = curr.name;
      const control =
        new FormControlWithProperties<DisplayTypeFormControlModel>('', curr);
      if (formGroup.controls[name]) {
        const value = formGroup.controls[name].value;
        control.setValue(value);
      }
      acc = {
        ...acc,
        [name]: control,
      };
      return acc;
    }, {});

    Object.keys(formGroup.controls).forEach((formControl) =>
      formGroup.removeControl(formControl)
    );
    Object.keys(controls).forEach((control) =>
      formGroup.addControl(control, controls[control])
    );
    return formGroup;
  };

export const setFormControl: (
  name: string,
  value: unknown
) => (formGroup: UntypedFormGroup) => UntypedFormGroup =
  (name: string, value: unknown) => (formGroup: UntypedFormGroup) => {
    formGroup?.get(name)?.setValue(value);
    formGroup.markAsPristine();
    return formGroup;
  };

export const transformKeysOf = (value: unknown): string[] => {
  const opts: KeysOfOpts = {
    includeArrayLeafs: false,
    includeObjectLeafs: false,
    includeIntermediateKeys: false,
  };

  if (isArray(value)) {
    const keys = new Set<string>();
    Object.values(value).forEach((value) => {
      keysOf(value, opts).forEach(keys.add, keys);
    });
    return Array.from(keys);
  }

  return keysOf(value, opts);
};
