import { html, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import '@assecosolutions/fox-menu';
import '@assecosolutions/fox-list';
import { FoxIconButton } from '@assecosolutions/fox-icon-button';
import { FoxListItem, foxListItemStyles } from '@assecosolutions/fox-list';
import { FoxMenu } from '@assecosolutions/fox-menu';

import { styles } from './FoxActivityListItem.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxActivityListItem;
  }
}

export class FoxActivityListItem extends FoxListItem {
  /**
   * @ignore
   */
  static styles = [foxListItemStyles, styles];
  @property({ type: Boolean }) hideMenu = false;
  @property({ type: Boolean }) hideMoreButton = false;
  @property({ type: String }) showMoreText = 'Show more';
  @property({ type: String }) showLessText = 'Show less';
  @property({ type: Boolean }) protected showMore = false;

  @query('fox-menu') protected foxMenu!: FoxMenu;
  @query('#fox-menu-button') protected foxMenuButton!: FoxIconButton;

  protected renderTwoline() {
    const classes = {
      showMore: !this.showMore,
    };
    if (this.hideMoreButton) {
      this.showMore = true;
    }
    return html`
      <span class="mdc-deprecated-list-item__primary-text">
        <slot></slot>
      </span>
      <span
        class="mdc-deprecated-list-item__secondary-text ${classMap(classes)}"
      >
        <span>
          <slot name="secondary"></slot>
        </span>

        ${!this.hideMoreButton
          ? html`
              <div class="toggle-more-button" @click="${this.toggleMore}">
                ${this.showMore ? this.showLessText : this.showMoreText}
              </div>
            `
          : nothing}

        <slot name="tertiary"></slot>
      </span>
    `;
  }

  protected renderMeta() {
    return html`
      <span class="mdc-deprecated-list-item__meta">
        <span>
          <slot name="meta"></slot>
        </span>
        <div class="menu-wrapper">
          <slot name="menu">
            ${!this.hideMenu
              ? html`
                  <fox-icon-button
                    icon="more_vert"
                    id="fox-menu-button"
                    @click="${this.openMenu}"
                  ></fox-icon-button>
                  <fox-menu menuCorner="END" corner="TOP_RIGHT">
                    <slot name="menuItems"></slot>
                  </fox-menu>
                `
              : nothing}
          </slot>
        </div>
      </span>
    `;
  }

  private openMenu() {
    this.foxMenu.show();
    this.foxMenu.anchor = this.foxMenuButton;
  }

  private toggleMore() {
    this.showMore = !this.showMore;
  }
}

const name = 'fox-activity-list-item';

if (!customElements.get(name)) {
  customElements.define(name, FoxActivityListItem);
} else {
  console.warn(`${name} is already defined`);
}
