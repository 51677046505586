import { Injectable } from '@angular/core';

export enum ResponsePreviewTabs {
  REQUEST = 0,
  INPUT = 1,
  RAW = 2,
}

@Injectable({ providedIn: 'root' })
export class BuilderRestTabService {
  activeResponsePreviewTab: ResponsePreviewTabs = ResponsePreviewTabs.REQUEST;

  isRequestTabActive(): boolean {
    return this.activeResponsePreviewTab === ResponsePreviewTabs.REQUEST;
  }

  isInputTabActive(): boolean {
    return this.activeResponsePreviewTab === ResponsePreviewTabs.INPUT;
  }

  activateRequestTab() {
    this.activeResponsePreviewTab = ResponsePreviewTabs.REQUEST;
  }

  activateInputTab() {
    this.activeResponsePreviewTab = ResponsePreviewTabs.INPUT;
  }
}
