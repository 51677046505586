import { FOX_NOTIFICATION, FoxNotification } from './FoxNotification';
import {
  FOX_NOTIFICATION_CONTAINER,
  FoxNotificationContainer,
} from './FoxNotificationContainer';

declare global {
  interface HTMLElementTagNameMap {
    [FOX_NOTIFICATION]: FoxNotification;
    [FOX_NOTIFICATION_CONTAINER]: FoxNotificationContainer;
  }
}

export enum FoxNotificationTypes {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}
