import { html, LitElement } from 'lit';

import { styles } from './FoxSidebar.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxSidebar;
  }
}

export class FoxSidebar extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  render() {
    return html` <slot></slot> `;
  }
}

const name = 'fox-sidebar';

if (!customElements.get(name)) {
  customElements.define(name, FoxSidebar);
} else {
  console.warn(`${name} is already defined`);
}
