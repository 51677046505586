import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

import { styles } from './FoxIcon.css';

declare global {
  interface HTMLElementTagNameMap {
    [name]: FoxIcon;
  }
}

/**
 *
 * Use any icon listed in https://fonts.google.com/icons?selected=Material+Icons and place the name inside the component
 *
 * @element fox-icon
 *
 * @slot default - slot for icon name
 *
 * @attr small  - Attribute for small icon
 * @attr large - Attribute for large icon
 * @attr x-large - Attribute for x-large icon
 *
 */
export class FoxIcon extends LitElement {
  /**
   * @ignore
   */
  static styles = [styles];

  @property({ type: String }) icon = '';
  @property({ type: Boolean }) small = false;
  @property({ type: Boolean }) large = false;

  render() {
    return html`<slot>${this.icon}</slot>`;
  }
}

const name = 'fox-icon';

if (!customElements.get(name)) {
  customElements.define(name, FoxIcon);
} else {
  console.warn(`${name} is already defined`);
}
