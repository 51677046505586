import '@vaadin/vaadin-grid/theme/material/vaadin-grid-column.js';
import { GridColumnElement } from '@vaadin/vaadin-grid/src/vaadin-grid-column.js';

import { findHostGrid } from './FoxTableHelper';
declare global {
  interface HTMLElementTagNameMap {
    'fox-table-column': FoxTableColumn;
  }
}

export class FoxTableColumn extends GridColumnElement {
  _findHostGrid() {
    return findHostGrid(this);
  }
}

window.customElements.define('fox-table-column', FoxTableColumn);
