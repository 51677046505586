import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  NgModule,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { ensureNotNil } from '@assecosolutions/fox-common-utils';
import { FoxMenu } from '@assecosolutions/fox-menu';

import { Menu, Styles } from './menu.model';
import { MenuService } from './menu.service';

@Component({
  selector: 'fox-menu-anchor',
  template: `<fox-menu
    absolute
    [attr.y]="y"
    [attr.x]="x"
    [ngStyle]="styles"
    #menu
  >
    <ng-template #viewContainer></ng-template>
  </fox-menu>`,
  styles: [
    `
      :host {
        --mdc-list-vertical-padding: 0;
      }
    `,
  ],
})
export class MenuComponent implements OnInit, Menu {
  y = 0;

  x = 0;

  styles?: Styles;

  @ViewChild('menu', { read: ElementRef })
  menu!: ElementRef<FoxMenu>;

  @ViewChild('viewContainer', { read: ViewContainerRef, static: false })
  private _viewContainer: ViewContainerRef | null = null;

  get viewContainer(): ViewContainerRef {
    return ensureNotNil(this._viewContainer);
  }

  constructor(private _menuService: MenuService) {
    window.onresize = () => this.close();
    window.onhashchange = () => this.close();
    window.onpopstate = () => this.close();
  }

  open() {
    this.menu.nativeElement.open = true;
  }

  close() {
    this.viewContainer?.clear();
    this.menu.nativeElement.close();
  }

  ngOnInit(): void {
    this._menuService.menu = this;
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [MenuComponent],
  declarations: [MenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MenuComponentModule {}
