import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AdministrationSharedFeatureNavigationModule } from '@fox/administration-shared-feature-navigation';

import { DefaultLayoutComponent } from './default-layout.component';

@NgModule({
  declarations: [DefaultLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    AdministrationSharedFeatureNavigationModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DefaultLayoutModule {}
